import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { Modal, SelectInput, TextInput } from 'components';
import VendorPickerContainer from 'containers/vendor-picker';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useUrlState } from 'utils';

const GET_INVOICE = gql`
  query GetInvoice($filter: InvoiceFilter!) {
    invoice: getInvoice(filter: $filter) {
      _id
      currency
      mode
      status
      totalAmount
      vendor {
        _id
      }
      vendorReference
      application {
        _id
        personalInformation {
          lastName
          firstName
          middleName
        }
        code
        cycle {
          name
          _id
        }
        level
        type
      }
      applicant {
        email
        _id
      }
    }
  }
`;

const APPROVE_INVOICE = gql`
  mutation ApproveInvoicePayment($input: ApproveInvoicePaymentInput!) {
    approveInvoicePayment(input: $input) {
      _id
    }
  }
`;

export default function ApproveInvoiceContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const [_, setModal] = useUrlState('modal');
  const [mode, setPaymentMode] = useState('');
  const [status, setStatus] = useState('');
  const [vendor, setVendor] = useState('');
  const [vendorReference, setVendorReference] = useState('');

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_INVOICE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: [
        'application',
        'application.personalInformation',
        'application.cycle',
        'applicant',
      ],
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only',
  });

  const [approveInvoice, { loading: updating }] = useMutation(APPROVE_INVOICE);

  const shouldDisableForm = data?.invoice?.status === 'PAID';

  useEffect(() => {
    setStatus(data?.invoice?.status);
    setPaymentMode(data?.invoice?.mode);
    setVendor(data?.invoice?.vendor?._id);
    setVendorReference(data?.invoice?.vendorReference);
  }, [data]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await approveInvoice({
      variables: {
        input: {
          invoiceId: data?.invoice?._id,
          mode,
          status,
          vendor,
          vendorReference,
        },
      },
    }).then(({ data }) => {
      if (data?.approveInvoicePayment?._id) {
        toast(
          JSON.stringify({
            type: 'success',
            title: 'Invoice approved successfully',
          }),
        );
        refetch?.();
        setOpen(false);
      } else {
        toast(
          JSON.stringify({ type: 'error', title: 'Could not update invoice' }),
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="3xl"
      loading={loading}
      title="Approve Invoice"
      description="Details of the application are shown below"
      renderActions={() => (
        <>
          {!shouldDisableForm && (
            <button
              type="button"
              className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-200 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleSubmit}
              disabled={!vendorReference || !mode || !status || !vendor}
            >
              {updating ? 'Saving...' : 'Save'}
            </button>
          )}
        </>
      )}
    >
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="pt-6">
          <span className="text-xs font-light">Application Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.invoice?.application?.code || 'N/A'}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  data?.invoice?.application?.personalInformation?.lastName,
                  data?.invoice?.application?.personalInformation?.firstName,
                  data?.invoice?.application?.personalInformation?.middleName,
                ].join(' ') || 'N/A'}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Type
              </span>
              <div className="mt-1 block w-full capitalize sm:text-sm">
                {data?.invoice?.application?.type?.toLowerCase() || 'N/A'}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Level
              </span>
              <div className="mt-1 block w-full capitalize sm:text-sm">
                {data?.invoice?.application?.level?.toLowerCase() || 'N/A'}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Applicant Email
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.invoice?.applicant?.email || 'N/A'}
              </div>
            </div>
          </div>
        </div>

        <div>
          <span className="text-xs font-light">Approval Details</span>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <VendorPickerContainer
                id="vendor"
                setFieldValue={(_field: string, value: string) =>
                  setVendor(value)
                }
                values={{ vendor }}
                rawId={true}
                required
                disabled={!!shouldDisableForm}
              />
            </div>

            <div>
              <TextInput
                id="vendorReference"
                label="Transaction Reference"
                type="text"
                placeholder="e.g. 44555125252"
                required={true}
                disabled={!!shouldDisableForm}
                handleChange={(event: any) =>
                  setVendorReference(event.target.value)
                }
                handleBlur={() => null}
                values={{ vendorReference }}
              />
            </div>

            <div>
              <SelectInput
                id="paymentMode"
                label="Payment Mode"
                placeholder="Choose start year"
                options={['CASH', 'USSD', 'CARD', 'APP']}
                handleChange={(event: any) => {
                  setPaymentMode(event.target.value);
                }}
                disabled={!!shouldDisableForm}
                handleBlur={() => null}
                values={{ paymentMode: mode }}
              />
            </div>

            <div>
              <SelectInput
                id="status"
                label="Status"
                placeholder="Choose status"
                options={['PENDING', 'PAID', 'CANCELLED']}
                handleChange={(event: any) => {
                  setStatus(event.target.value);
                }}
                disabled={!!shouldDisableForm}
                handleBlur={() => null}
                values={{ status }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
