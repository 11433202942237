type ButtonGroupType = {
  label: string;
  value?: string;
};

const buttonConfigs = {
  indigo: {
    normal:
      'focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-gray-700 hover:bg-gray-50 border-gray-300 bg-white',
    active: 'bg-indigo-500 text-white border-gray-300 bg-indigo-500',
  },
  primary: {
    normal:
      'focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 text-gray-700 hover:bg-gray-50 border-gray-300 bg-white',
    active: 'text-white border-gray-300 bg-primary-500',
  },
};

const sizes = {
  lg: 'px-4 py-2',
  normal: 'px-2.5 py-1',
};

interface Props {
  buttons: ButtonGroupType[];
  theme?: keyof typeof buttonConfigs;
  size?: keyof typeof sizes;
  selectedValue?: string;
  onChangeValue?: (value: string | undefined) => void;
}

export const ButtonGroup = ({
  buttons,
  theme,
  size,
  selectedValue,
  onChangeValue,
}: Props) => {
  const configs = buttonConfigs[theme || 'primary'];

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {buttons.map((button, idx) => {
        const isActive = button?.value === selectedValue;
        if (idx === 0) {
          return (
            <button
              key={idx}
              type="button"
              onClick={() => onChangeValue?.(button?.value)}
              className={`relative inline-flex items-center rounded-l-md border border-gray-300  ${sizes[size || 'normal']}  text-sm font-medium  focus:z-10 ${isActive ? configs.active : configs.normal}`}
            >
              {button.label}
            </button>
          );
        }

        if (idx === buttons.length - 1) {
          return (
            <button
              key={idx}
              type="button"
              onClick={() => onChangeValue?.(button?.value)}
              className={`relative -ml-px inline-flex items-center rounded-r-md  border border-gray-300  ${sizes[size || 'normal']} text-sm font-medium  focus:z-10 ${isActive ? configs.active : configs.normal}`}
            >
              {button.label}
            </button>
          );
        }

        return (
          <button
            key={idx}
            type="button"
            onClick={() => onChangeValue?.(button?.value)}
            className={`relative -ml-px inline-flex items-center border border-gray-300  ${sizes[size || 'normal']} text-sm font-medium focus:z-10 ${isActive ? configs.active : configs.normal} `}
          >
            {button.label}
          </button>
        );
      })}
    </span>
  );
};
