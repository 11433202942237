import { UserPermission } from 'apollo/cache/auth';
import lodash from 'lodash';
import { rankRoutes } from 'react-location-rank-routes';
import { RouteProps } from './routes';

export const withRoutePermissions =
  (permissions: UserPermission[]) => (routes: RouteProps[]) => {
    return lodash.filter(routes, route =>
      route?.withPermissions && route?.withPermissions.length > 0
        ? lodash.intersection(route?.withPermissions, permissions).length > 0
        : true,
    );
  };
/**
 *
 * @param permissions permissions associated with the current user. pass this if you are using permissions
 * @returns a list of filtered routes
 */
const filterRoutes = (permissions?: UserPermission[]) => {
  return lodash.flow([
    rankRoutes,
    ...(permissions ? [withRoutePermissions(permissions ?? [])] : []),
  ]);
};

export default filterRoutes;
