import * as Yup from 'yup';

export const MailingSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is a required field'),
  cycle: Yup.string(),
  recipientType: Yup.string().required('Recipient Type is a required field'),
  message: Yup.string().required('Message is a required field'),
  recipientsApplicationCodes: Yup.array().of(Yup.string()),
});

export const MailingInitialValues = {
  subject: '',
  cycle: '',
  message: '',
  recipientType: '',
  recipientsApplicationCodes: [],
};
