import { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { AdmissionLetterConfigForm, Modal } from 'components';
import { wrapClick } from 'utils';

const GET_ADMISSION_LETTER = gql`
  query GetAdmissionLetter(
    $filter: AdmissionLetterFilter!
    $populate: [String]
  ) {
    admissionletter: getAdmissionLetter(filter: $filter, populate: $populate) {
      _id
      code
      name
      types
      name
      title
      signatureName
      signaturePortfolio
      signatureUrl
      pointers
      address
      postalAddress
    }
  }
`;

export const UPDATE_ADMISSIONLETTER = gql`
  mutation UpdateAdmissionLetter(
    $updateAdmissionLetterId: ID!
    $input: AdmissionLetterInput!
  ) {
    updateAdmissionLetter(id: $updateAdmissionLetterId, input: $input) {
      _id
      code
    }
  }
`;

export default function UpdateAdmissionLetterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const {
    data,
    loading,
    refetch: refetchData,
  } = useQuery(GET_ADMISSION_LETTER, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  });

  const { params } = useMatch<LocationGenerics>();

  const [updateAdmissionLetter, { loading: updating }] = useMutation(
    UPDATE_ADMISSIONLETTER,
  );

  const form = useFormik({
    initialValues: {
      generalInfo: {
        title: '',
        name: '',
        signatureName: '',
        signaturePortfolio: '',
        signatureUrl: '',
        postalAddress: '',
        address: '',
      },
      pointersInfo: {
        pointers: [] as any,
      },
    },
    onSubmit: async ({ ...values }) => {
      await updateAdmissionLetter({
        variables: {
          updateAdmissionLetterId: searchParams.id,
          input: {
            ...values?.generalInfo,
            ...values?.pointersInfo,
            admissionCycleId: params.admissionCycle,
          },
        },
      }).then(({ data }) => {
        if (data.updateAdmissionLetter._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Admission Letter Edited Successfully',
            }),
          );
          refetch?.();
          refetchData?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Admission Letter',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      generalInfo: {
        name: data?.admissionletter?.name,
        signatureName: data?.admissionletter?.signatureName,
        signaturePortfolio: data?.admissionletter?.signaturePortfolio,
        signatureUrl: data?.admissionletter?.signatureUrl,
        title: data?.admissionletter?.title,
        address: data?.admissionletter?.address,
        postalAddress: data?.admissionletter?.postalAddress,
      },
      pointersInfo: {
        pointers: data?.admissionletter?.pointers || [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.admissionletter, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={() => setOpen(false)}
      title="Edit Admission Letter"
      description="Provide the details for the admission letter"
      size="5xl"
      loading={loading}
      hideActions={true}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing Admission Letter...' : 'Edit Admission Letter'}
          </button>
        </>
      )}
    >
      {data?.admissionletter?._id && (
        <AdmissionLetterConfigForm form={form} isCompleted={true} />
      )}
    </Modal>
  );
}
