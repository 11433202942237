import { useFormik } from 'formik';
import { Link, useNavigate, useSearch } from 'react-location';
import * as Yup from 'yup';
import { useLocalStorage } from 'react-use';
import { gql, useMutation } from '@apollo/client';
import { setAuth } from 'apollo/cache/auth';
import { LocationGenerics } from 'router/location';
import { FC } from 'react';

const SEND_RESET_CODE = gql`
  mutation SendUserCode($input: SendCodeInput!) {
    sendUserCode(input: $input) {
      user {
        _id
      }
    }
  }
`;

const VERIFY_RESET_CODE = gql`
  mutation VerifyUserCode($input: VerifyCodeInput!) {
    verifyUserCode(input: $input) {
      user {
        _id
        code
        email
        name
        phone
        profileImageUrl
        role
        gender
      }
      token
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetUserPassword($input: ResetPasswordInput!) {
    resetUserPassword(input: $input) {
      token
      user {
        _id
        code
        createdAt
        profileImageUrl
        role
        school {
          _id
        }
        name
        phone
        meta {
          isFirstLogin
          isSuspended
          lastLoginAt
        }
        staffId
        email
      }
    }
  }
`;

const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();
  const search = useSearch<LocationGenerics>();
  const [forgotStore, setForgotStore] = useLocalStorage<{
    username?: string;
    tab: string;
    token?: string;
  }>('forgot-store', { username: '', tab: 'send-code', token: '' });

  const [sendResetCode, { loading: loadingSend }] =
    useMutation(SEND_RESET_CODE);
  const [verifyResetCode, { loading: loadingVerify }] =
    useMutation(VERIFY_RESET_CODE);
  const [resetUserPassword, { loading: loadingReset }] =
    useMutation(RESET_PASSWORD);

  const sendCodeForm = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
    }),
    onSubmit: async values => {
      await sendResetCode({
        variables: {
          input: {
            username: values.username || forgotStore?.username,
            medium: 'EMAIL',
          },
        },
      })
        .then(({ data }) => {
          setForgotStore({
            tab: 'verify-code',
            username: values.username || forgotStore?.username,
          });
        })
        .catch(err => {});
    },
  });

  const verifyCodeForm = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(6, 'Code should be 6 digits')
        .required('Enter reset code'),
    }),
    onSubmit: async values => {
      const payload = {
        username: forgotStore?.username,
        code: values.code,
        medium: 'EMAIL',
      };
      console.log({ payload });
      await verifyResetCode({
        variables: {
          input: payload,
        },
      })
        .then(({ data }) => {
          setForgotStore({
            tab: 'reset-password',
            token: data.verifyUserCode.token,
            username: data.verifyUserCode.user.name,
          });
        })
        .catch(err => {});
    },
  });

  const resetPasswordForm = useFormik({
    initialValues: {
      password: '',
      rPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be more than 8 characters')
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])/,
          'Must Contain at least One Lowercase Character',
        )
        .matches(
          /^(?=.*[A-Z])/,
          'Must Contain at least One Uppercase Character',
        )
        .matches(/^(?=.*[0-9])/, 'Must Contain at least One Number')
        .matches(
          /^(?=.*[!@#$%^&*\\|/{}()<>:;[\]_\\-\\=?])/,
          'Must Contain at least One special case Character',
        ),
      rPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
        .required('Please confirm your password'),
    }),
    onSubmit: async values => {
      await resetUserPassword({
        variables: {
          input: {
            newPassword: values.password,
          },
        },
        context: {
          headers: {
            authorization: forgotStore?.token,
          },
        },
      })
        .then(({ data }) => {
          setAuth({
            user: data?.resetUserPassword?.user,
            token: data.resetUserPassword.token,
          });
          // setForgotStore({ tab: "send-code", username: "" })
          navigate({
            replace: true,
            to: search?.redirect ?? '/',
          });
        })
        .catch(err => {});
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="text-center">
        <h2 className="text-xl font-extrabold text-gray-900">
          {['send-code', 'verify-code'].includes((forgotStore as any).tab)
            ? 'Forgot your password?'
            : 'Update your password'}
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          {forgotStore?.tab === 'send-code'
            ? 'Enter your email address to receive an email with a verification code.'
            : forgotStore?.tab === 'verify-code'
              ? `Enter the 6 digit code sent to your email address (${forgotStore?.username})`
              : `Hello ${forgotStore?.username}, kindly enter a new password to use with your account`}
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          {forgotStore?.tab === 'send-code' && (
            <form onSubmit={sendCodeForm.handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    name="username"
                    type="email"
                    autoComplete="email"
                    value={sendCodeForm.values.username}
                    onChange={sendCodeForm.handleChange}
                    placeholder="eg. user@polymorphlabs.com"
                    className="block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {sendCodeForm.touched.username &&
                    sendCodeForm.errors.username}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <div className="text-sm text-gray-600">
                  Remember your password?{' '}
                  <Link
                    to="/login"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    Login
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingSend}
                  className="flex w-full justify-center rounded border border-transparent bg-primary-600 bg-gradient-to-r px-4 py-2 text-sm font-medium text-white shadow-sm  hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {loadingSend ? 'Sending Reset Code...' : 'Send Reset Code'}
                </button>
              </div>
            </form>
          )}
          {forgotStore?.tab === 'verify-code' && (
            <form onSubmit={verifyCodeForm.handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    value={verifyCodeForm.values.code}
                    onChange={verifyCodeForm.handleChange}
                    maxLength={6}
                    minLength={6}
                    className="block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {verifyCodeForm.touched.code && verifyCodeForm.errors.code}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <div className="text-sm text-gray-600">
                  Didn't receive code?{' '}
                  <button
                    type="button"
                    disabled={loadingSend}
                    onClick={() => sendCodeForm.handleSubmit()}
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    Resend
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingVerify}
                  className="flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {loadingVerify
                    ? 'Verifying Reset Code...'
                    : 'Verify Reset Code'}
                </button>
              </div>
            </form>
          )}
          {forgotStore?.tab === 'reset-password' && (
            <form
              onSubmit={resetPasswordForm.handleSubmit}
              className="space-y-6"
            >
              <div className="space-y-1">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={resetPasswordForm.values.password}
                    onChange={resetPasswordForm.handleChange}
                    onBlur={resetPasswordForm.handleBlur}
                    className="block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {resetPasswordForm.touched.password &&
                    resetPasswordForm.errors.password}
                </p>
              </div>

              <div className="space-y-1">
                <label
                  htmlFor="rPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repeat Password
                </label>
                <div className="mt-1">
                  <input
                    id="rPassword"
                    name="rPassword"
                    type="password"
                    value={resetPasswordForm.values.rPassword}
                    onChange={resetPasswordForm.handleChange}
                    onBlur={resetPasswordForm.handleBlur}
                    className="block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {resetPasswordForm.touched.rPassword &&
                    resetPasswordForm.errors.rPassword}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingReset}
                  className="flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {loadingReset ? 'Reseting password...' : 'Reset password'}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
