import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, SchoolForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { SchoolSchema } from 'components/forms/school/schema';

const GET_SCHOOL = gql`
  query GetSchoolLean($filter: SchoolFilter!) {
    school: getSchool(filter: $filter) {
      _id
      code
      dean {
        _id
        code
        name
        email
        phone
        role
      }
      digitalAddress
      email
      name
      phone
      officeAddress
      yearEstablished
    }
  }
`;

const DELETE_SCHOOL = gql`
  mutation DeleteSchool($id: ID!) {
    school: deleteSchool(id: $id) {
      _id
    }
  }
`;

export default function DeleteSchoolContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SCHOOL, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [deleteSchool, { loading: deleting }] = useMutation(DELETE_SCHOOL);

  const form = useFormik({
    initialValues: {},
    onSubmit: async values => {
      await deleteSchool({
        variables: {
          id: searchParams.id,
        },
      }).then(({ data }) => {
        if (data?.school?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'School deleted successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not delete school' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Delete School"
      loading={loading || deleting}
      size="xl"
      description="Remove this school"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={deleting}
            className="inline-flex w-full justify-center rounded border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {deleting ? 'Deleting School...' : 'Delete School'}
          </button>
        </>
      )}
    >
      <div>
        <p className="text-center text-sm text-gray-500">
          Are you sure you want to delete this school{' '}
          <span className="whitespace-nowrap font-medium text-gray-700">
            ({data?.school?.name})
          </span>
          ? All of your configuration will be permanently removed from our
          servers forever. This action cannot be undone.
        </p>
      </div>
    </Modal>
  );
}
