import { AvatarUpload, TextInput } from 'components/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick } from 'utils';

interface GeneralInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  handleCancel: () => void;
  initialValues: any;
  values: any;
}

const GET_ADMISSION_LETTERS = gql`
  query GetAdmissionLettersLite {
    getAdmissionLetters {
      _id
      types
    }
  }
`;

const ProgrammeTypes = [
  'DIPLOMA',
  'BACHELORS',
  'POSTGRADUATE',
  'MASTERS',
  'DOCTORS',
  'CHARTERED',
];

const GeneralInfoForm: FC<GeneralInfoFormProps> = ({
  handleNext,
  handlePrevious,
  handleCancel,
  values,
  initialValues,
}) => {
  const { params } = useMatch<LocationGenerics>();

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_ADMISSION_LETTERS,
    {
      variables: {
        filter: {
          admissionCycle: {
            eq: params.admissionCycle,
          },
        },
      },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'no-cache',
    },
  );

  const form = useFormik({
    initialValues: {
      name: '',
      title: '',
      pointers: '',
      signatureName: '',
      signaturePortfolio: '',
      signatureUrl: '',
      address: '',
      postalAddress: '',
    },
    // validationSchema: AdmissionProgrammeSchema,
    onSubmit: async ({ ...values }) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    if (initialValues) {
      form.setValues({
        ...initialValues,
      });
    }
  }, [initialValues]);

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden "
    >
      <div className="flex-1 space-y-6 divide-gray-200 overflow-y-auto p-6">
        <div className="rounded-md border-0 border-dashed p-3">
          <span className="text-xs font-light">General Information</span>
          <div className="mt-2 grid grid-cols-2 gap-6">
            <div className="col-span-1">
              <TextInput
                id={`name`}
                label="Name"
                type="text"
                placeholder="eg. 2024/2025 Admissions"
                {...form}
              />
            </div>

            <div></div>

            <div className="col-span-2">
              <TextInput
                id={`title`}
                label="Letter Title"
                type="text"
                placeholder="eg. Admissions For Graduates - 2024/2025"
                {...form}
              />
            </div>

            <div className="col-span-1">
              <TextInput
                id={`address`}
                label="Address"
                type="text"
                placeholder="eg. Legon - Accra"
                {...form}
              />
            </div>

            <div className="col-span-1">
              <TextInput
                id={`postalAddress`}
                label="Postal Address"
                type="text"
                placeholder="eg. P.O.Box CT330, Cantonments"
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="rounded-md border-2 border-dashed p-3">
          <span className="text-xs font-light">Signature Information</span>
          <div className="mt-2 grid grid-cols-2 gap-6">
            <div>
              <AvatarUpload
                id="signatureUrl"
                label="Signature Image"
                {...form}
              />
            </div>

            <div></div>

            <div className="">
              <TextInput
                id={`signatureName`}
                label="Name"
                type="text"
                placeholder="eg. Samuel Asare"
                {...form}
              />
            </div>
            <div className="">
              <TextInput
                id={`signaturePortfolio`}
                label="Portfoilio"
                type="text"
                placeholder="eg. Dean of Graduate Studies"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          disabled={false}
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-300 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Continue
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default GeneralInfoForm;
