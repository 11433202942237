import LetterPointersInfoForm from './pointers-form';
import GeneralInfoForm from './general-info-form';

const admisssionLetterFormSteps: any[] = [
  {
    name: 'General Pointers',
    description: 'This is for genral inforamtion',
    accessor: 'generalInfo',
    FormComponent: GeneralInfoForm,
    SummaryComponent: () => <div></div>,
  },
  {
    name: 'Letter Pointers',
    description: 'Pointer by pointer information on the admission letters',
    accessor: 'pointersInfo',
    FormComponent: LetterPointersInfoForm,
    SummaryComponent: () => <div></div>,
  },
];

export default admisssionLetterFormSteps;
