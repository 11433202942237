import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { TextInput } from 'components/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { currentConfigVar } from 'apollo/cache/config';

interface FXRatesFormProps {
  form: FormikProps<{
    USDXGHS: number;
  }>;
  data?: {
    lastUpdatedBy?: string;
    lastUpdatedAt?: string;
  };
}

const ConfigureFXRatesForm: FC<FXRatesFormProps> = ({ form, data }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">FX Rates Information</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.lastUpdatedBy || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {dayjs(data?.lastUpdatedAt).format(dateFormat + ' hh:mm A')}
            </div>
          </div>

          <div className="col-span-2">
            <TextInput
              id="USDXGHS"
              label="USDXGHS"
              type="number"
              placeholder="e.g. 12.60"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureFXRatesForm;
