import { gql } from '@apollo/client';

type InvoiceStatus = 'PENDING' | 'PAID' | 'CANCELLED';

export type InvoiceType = {
  _id: any;
  code: any;
  paystackAuthorizationUrl: any;
  currency: any;
  mode: any;
  status: InvoiceStatus;
  totalAmount: number;
  name: any;
  createdAt: string;
  updatedAt: string;
};

export interface GetInvoicesOutputProps {
  getInvoices: InvoiceType[];
}

export interface GetInvoicesInputProps {
  filter: {
    application: {
      eq: string;
    };
    status: {
      eq: InvoiceStatus;
    };
  };
}

export const GET_VENDORS = gql`
  query GetVendors {
    getVendors {
      _id
      name
      description
    }
  }
`;

export interface GetVendorsOutputProps {
  getVendors: {
    _id: string;
    name: string;
    description: string;
  }[];
}

export const GET_ADMISSION = gql`
  query GetAdmission($filter: AdmissionFilter!, $populate: [String]) {
    admission: getAdmission(filter: $filter, populate: $populate) {
      _id
      applicant {
        code
        email
        _id
      }
      code
      programme {
        name
        _id
        code
        duration
        department {
          code
          name
        }
        level
        school {
          name
        }
        admissionLetter {
          _id
          title
          signatureName
          signaturePortfolio
          signatureUrl
          postalAddress
          address
          pointers
        }
      }
      updatedAt
      createdAt
      application {
        code
        level
        type
        gimpaIndexNumber
        personalInformation {
          firstName
          lastName
          middleName
          profileImageUrl
          maritalStatus
          gender
        }
        _id
        programmeInformation {
          preferredCampus {
            name
          }
          preferredOfferingType
        }
      }
      shortlist {
        startingYear
        offeredProgramme {
          _id
          name
        }
        offeredProgrammeRemark
      }
      department {
        name
        phone
        _id
      }
      school {
        _id
        name
      }
      createdBy {
        _id
        name
      }
      cycle {
        schoolFeesDates {
          commitmentFeeDate
          provisionalRegistrationDate
          outstandingFeesDate
        }
        academicCalendar {
          year
          openingDate
          orientationDate
        }
        signatures {
          undergraduateName
          graduateName
          graduatePortfolio
          graduateUrl
          undergraduatePortfolio
          undergraduateUrl
        }
      }
      applicationName
      bankBranch
      bankName
      bankTransactionCode
      bankTransactionImage
      offeringType
      enrolled
      dateOfPayment
      applicationCode
      indexNumber
      campus {
        _id
        name
      }
    }
  }
`;

export interface AdmittedProps {
  _id: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  applicant: {
    code: string;
    email: string;
    _id: string;
  };
  application: {
    code: string;
    gimpaIndexNumber: string;
    type: string;
    personalInformation: {
      firstName: string;
      lastName: string;
      middleName: string;
      profileImageUrl: string;
      gender: string;
      maritalStatus: string;
    };
    programmeInformation: {
      preferredCampus: {
        name: string;
      };
      preferredOfferingType: string;
    };
    _id: string;
    level: string;
  };
  programme: {
    _id: string;
    name: string;
    level: string;
    duration: number;
    school: {
      name: string;
    };
    requiresInterview: boolean;
    requiresExamination: boolean;
    admissionLetter: {
      _id: string;
      title: string;
      signatureName: string;
      signaturePortfolio: string;
      signatureUrl: string;
      postalAddress: string;
      address: string;
      pointers: string[];
    };
  };
  shortlist: {
    startingYear: number;
    offeredProgramme: {
      _id: string;
      name: string;
    };
    offeredProgrammeRemark: string;
  };
  department: {
    name: string;
    phone: string;
    _id: string;
  };
  school: {
    _id: string;
    name: string;
  };
  createdBy: {
    _id: string;
    name: string;
  };
  cycle: {
    schoolFeesDates: {
      commitmentFeeDate: string;
      provisionalRegistrationDate: string;
      outstandingFeesDate: string;
    };
    academicCalendar: {
      year: string;
      openingDate: string;
      orientationDate: string;
    };
    signatures: {
      undergraduateName: string;
      graduateName: string;
      graduatePortfolio: string;
      graduateUrl: string;
      undergraduatePortfolio: string;
      undergraduateUrl: string;
    };
  };
  applicationName: string;
  bankBranch: string;
  bankName: string;
  bankTransactionCode: string;
  bankTransactionImage: string;
  offeringType: string;
  enrolled: boolean;
  dateOfPayment: string;
  applicationCode: string;
  indexNumber: string;
  campus: {
    _id: any;
    name: any;
  };
}

export interface VendorProps {
  _id: string;
  name: string;
  description: string;
}

export interface AdmissionLetterViewProps {
  admitted: AdmittedProps;
  vendor?: VendorProps[];
  invoices?: InvoiceType[];
}

export const GET_INVOICES = gql`
  query GetInvoices($filter: InvoiceFilter) {
    getInvoices(filter: $filter) {
      _id
      code
      status
      name
      totalAmount
      currency
    }
  }
`;
interface Credentials {
  studentId: string;
  pin: string;
}

export type AdmissionFormInputs = {
  admissionData?: AdmittedProps;
  paymentDates?: Record<string, string>;
  applicationData?: any;
  credentials: Credentials;
  commitmentFee: string;
  startingYear: number;
};
