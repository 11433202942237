import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Header, Shimmers, TableComponent, SelectInput } from 'components';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, classNames, withPermissions } from 'utils';
import config from 'config';
import ViewCampusContainer from './view';
import { currentConfigVar } from 'apollo/cache/config';
import { ExportDataContainer } from 'containers';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import dayjs from 'dayjs';
import AdmissionCyclePickerContainer from 'containers/admission-cycle-picker';
import ApproveInvoiceContainer from './approve';
import { BanknotesIcon, PlusIcon } from '@heroicons/react/24/outline';
import CreateInvoiceContainer from './create';
import ConfigureFXRateContainer from './configure-fx';

const GET_INVOICES = gql`
  query GetInvoices(
    $filter: InvoiceFilter
    $pagination: Pagination
    $search: SearchOperator
    $populate: [String]
    $sort: InvoiceSort
  ) {
    items: getInvoices(
      filter: $filter
      pagination: $pagination
      search: $search
      populate: $populate
      sort: $sort
    ) {
      _id
      code
      currency
      mode
      totalAmount
      status
      createdAt
      type
      application {
        code
        personalInformation {
          lastName
          firstName
        }
      }
    }
    itemsCount: getInvoicesCount(filter: $filter, search: $search)
  }
`;

const InvoicesPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [cycle, setCycle] = useUrlState('cycle');
  const [status, setStatus] = useUrlState('status');
  const [type, setType] = useUrlState('type');
  const [mode, setMode] = useUrlState('mode');

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search,
              fields: ['code', 'name', 'code', 'applicationCode'],
            },
          }
        : {}),
      filter: {
        ...(status && { status: { eq: status } }),
        ...(type && { type: { eq: type } }),
        ...(mode && { mode: { eq: mode } }),
      },
      sort: {},
      populate: ['application.personalInformation'],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_INVOICES, {
    variables,
    pollInterval,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
  });

  const { dateFormat } = useReactiveVar(currentConfigVar);

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex h-screen flex-1 flex-col overflow-hidden bg-gray-50">
      <Header
        renderActions={() => (
          <>
            <AdmissionCyclePickerContainer
              id="cycle"
              label="Admission Cycle"
              labelHidden={true}
              setFieldValue={(_field: string, value: string) => setCycle(value)}
              values={{ cycle: cycle }}
              rawId={true}
            />

            {withPermissions(['*:*', 'invoices:*', 'invoices:create'])(
              <button
                type="button"
                onClick={wrapClick(() => setModal('configure'))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-green-700 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                <BanknotesIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Configure FX
              </button>,
            )}

            {withPermissions(['*:*', 'invoices:*', 'invoices:create'])(
              <button
                type="button"
                onClick={wrapClick(() => setModal('create'))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New Invoice
              </button>,
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
          <TableComponent
            title={'invoices'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, 'items', []),
              total: lodash.get(data, 'itemsCount', 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, 'itemsCount', 0) /
                  (searchParams?.pageSize || config.constants.pageSize),
              ),
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Invoice"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="flex flex-row space-x-4">
                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setMode(e.target.value)}
                  id="mode"
                  label="Mode"
                  placeholder="Filter Mode"
                  labelHidden
                  options={[
                    { label: 'App', value: 'APP' },
                    { label: 'Card', value: 'CARD' },
                    { label: 'Cash', value: 'CASH' },
                    { label: 'Ecobank', value: 'ECOBANK' },
                    { label: 'Paystack', value: 'PAYSTACK' },
                    { label: 'USSD', value: 'USSD' },
                  ]}
                  values={{ mode }}
                  required={true}
                />
                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setType(e.target.value)}
                  id="type"
                  label="Type"
                  placeholder="Filter Type"
                  labelHidden
                  options={[
                    { label: 'Application', value: 'APPLICATION' },
                    { label: 'Examination', value: 'EXAMINATION' },
                    { label: 'Surcharge', value: 'SURCHARGE' },
                  ]}
                  values={{ type }}
                  required={true}
                />
                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setStatus(e.target.value)}
                  id="status"
                  label="Status"
                  placeholder="Filter Status"
                  labelHidden
                  options={[
                    { label: 'Paid', value: 'PAID' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Cancelled', value: 'CANCELLED' },
                  ]}
                  values={{ status }}
                  required={true}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Application
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Mode
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Total Amount
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Date Generated
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={item => (
              <tr
                key={item._id}
                className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                onClick={wrapClick(dispatchAction(item._id, 'view'))}
              >
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {[
                      item?.application?.personalInformation?.lastName,
                      item?.application?.personalInformation?.firstName,
                    ].join(' ') || 'N/A'}
                  </div>
                  <div className="font-medium text-gray-400 dark:text-gray-400">
                    {item?.application?.code || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.mode || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <span
                    className={classNames(
                      item?.type === 'APPLICATION'
                        ? `bg-green-100 text-green-700 dark:bg-[#F87A17] dark:text-gray-50`
                        : '',
                      item?.type === 'EXAMINATION'
                        ? `bg-blue-100 text-blue-800 dark:bg-[#836f65] dark:text-gray-50`
                        : '',
                      item?.type === 'SURCHARGE'
                        ? `bg-yellow-100 text-yellow-800 dark:bg-[#836f65] dark:text-gray-50`
                        : '',
                      !item?.type
                        ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                        : '',
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.type || 'Unknown'}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {`${item?.currency || 'GHS'} ${(item?.totalAmount || 0) / 100}` ||
                      'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <span
                    className={classNames(
                      item?.status === 'PAID'
                        ? `bg-green-100 text-green-700 dark:bg-[#F87A17] dark:text-gray-50`
                        : '',
                      item?.status === 'CANCELLED'
                        ? `bg-red-100 text-red-800 dark:bg-[#836f65] dark:text-gray-50`
                        : '',
                      !item?.status
                        ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                        : '',
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || 'Unknown'}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {dayjs(item?.createdAt).format(dateFormat + ' hh:mm A') ||
                      'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />
                    {withPermissions(['*:*', 'invoices:*', 'invoices:approve'])(
                      <ActionButton
                        action="approve"
                        onClick={dispatchAction(item?._id, 'approve')}
                      />,
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(['*:*', 'invoices:*', 'invoices:create'])(
        <CreateInvoiceContainer
          open={modal === 'create'}
          setOpen={(val: boolean) => setModal(val ? 'create' : undefined)}
          refetch={refetch}
        />,
      )}

      {withPermissions(['*:*', 'invoices:*', 'invoices:configure'])(
        <ConfigureFXRateContainer
          open={modal === 'configure'}
          setOpen={(val: boolean) => setModal(val ? 'configure' : undefined)}
          refetch={refetch}
        />,
      )}

      {!!searchParams.id?.length && (
        <>
          <ViewCampusContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />
          {withPermissions(['*:*', 'invoices:*', 'invoices:approve'])(
            <ApproveInvoiceContainer
              open={modal === 'approve'}
              setOpen={(val: boolean) => setModal(val ? 'approve' : undefined)}
            />,
          )}
        </>
      )}
    </main>
  );
};

export default InvoicesPage;
