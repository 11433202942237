import { useReactiveVar } from '@apollo/client';
import { currentUserVar } from 'apollo/cache/auth';
import { SelectInput, TextArea, TextInput } from 'components/core';
import { SchoolPicker, DepartmentPicker } from 'containers';
import { FormikProps } from 'formik';
import { FC } from 'react';

export interface ProgrammeFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    description: string;
    level: string;
    department: string;
    school: string;
  }>;
}

const ProgrammeForm: FC<ProgrammeFormProps> = ({ form }) => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Programme Information</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <SchoolPicker
              id="school"
              label="School"
              placeholder="e.g. Accra East School"
              required={true}
              {...form}
              rawId={true}
              disabled={!!currentUser?.school?._id}
            />
          </div>

          <div>
            <DepartmentPicker
              id="department"
              label="Department"
              placeholder="e.g. Computer Science"
              required={true}
              {...form}
              rawId={true}
            />
          </div>

          <div className="space-y-1">
            <SelectInput
              id="level"
              label="Level"
              placeholder="e.g. Graduate"
              options={[
                { label: '- select -', value: '' },
                { label: 'Graduate', value: 'GRADUATE' },
                { label: 'Undergraduate', value: 'UNDERGRADUATE' },
              ]}
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="code"
              label="Programme Code"
              type="text"
              placeholder="e.g. 01-04"
              disabled={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="name"
              label="Programme Name"
              type="text"
              placeholder="e.g. Legon Programme"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Description</span>
        <div className="mt-2 grid gap-6">
          <div>
            <TextArea
              id="description"
              label="Description"
              placeholder="e.g. This is a nice programme"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammeForm;
