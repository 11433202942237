import * as Yup from 'yup';

export const AdmissionTransferSchema = Yup.object().shape({
  destinationAdmissionProgrammeId: Yup.string().required().min(1),
  destinationCycleId: Yup.string().required().min(1),
  sourceAdmissionProgrammeId: Yup.string().required().min(1),
  sourceCycleId: Yup.string().required().min(1),
});

export const AdmissionTransferInitialValues = {
  destinationAdmissionProgrammeId: '',
  destinationCycleId: '',
  sourceAdmissionProgrammeId: '',
  sourceCycleId: '',
};
