import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { MultiCheckInput, SelectInput, TextInput } from 'components/core';
import { ProgrammePicker } from 'containers';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { useMatches } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames, useUrlState, wrapClick, wrapOnchange } from 'utils';

interface ProgrammeInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const GET_CAMPUSES_LEAN = gql`
  query GetCampusesLean {
    campuses: getCampuses {
      _id
      name
    }
  }
`;

const GET_PROGRAMME_LEAN = gql`
  query GetProgramme($filter: ProgrammeFilter!) {
    programme: getProgramme(filter: $filter) {
      _id
      level
    }
  }
`;

const GET_ADMISSION_PROGRAMMES_LEAN = gql`
  query GetAdmissionProgrammesSMALL($filter: AdmissionProgrammeFilter) {
    admissionProgrammes: getAdmissionProgrammes(filter: $filter) {
      _id
      programme {
        _id
      }
    }
  }
`;

const ProgrammeInfoForm: FC<ProgrammeInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  values,
}) => {
  const [modal] = useUrlState('modal');
  const [school] = useUrlState('school');

  const form = useFormik({
    initialValues,
    onSubmit: values => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id,
      ),
    ]);
  };

  const matches = useMatches<LocationGenerics>();
  const cycle = matches?.[0]?.params?.admissionCycle;

  const { data: campusesData } = useQuery(GET_CAMPUSES_LEAN);

  const [getProgramme, { data: programmeData }] =
    useLazyQuery(GET_PROGRAMME_LEAN);
  const [getAdmissionProgrammes, { data: admissionProgrammeData }] =
    useLazyQuery(GET_ADMISSION_PROGRAMMES_LEAN, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    getAdmissionProgrammes({
      variables: {
        filter: {
          cycle: {
            eq: cycle,
          },
        },
      },
    });
  }, []);

  const usedProgrammes = useMemo(
    () =>
      admissionProgrammeData?.admissionProgrammes?.map(
        (admissionProgramme: any) => admissionProgramme?.programme?._id,
      ) || [],
    [admissionProgrammeData],
  );
  // const deletedProgrammes = useMemo(() => admissionProgrammeData?.admissionProgrammes?.map((admissionProgramme: any) => admissionProgramme?.programme?._id) || [], [admissionProgrammeData])

  // console.log({usedProgrammes})

  useEffect(() => {
    if (form?.values?.programme?._id) {
      getProgramme({
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            _id: {
              eq: form?.values?.programme?._id,
            },
          },
        },
      });
    }
  }, [form?.values?.programme?._id]);

  useEffect(() => {
    console.log({ initialValues });
    form.setValues({
      programme: initialValues?.programme || null,
      tracks: initialValues?.tracks || [],
      offerings:
        initialValues?.offerings?.map((offering: any) => ({
          ...offering,
          campus: offering?.campus,
        })) || [],
      requirementTypes: initialValues?.requirementTypes || [],
      duration: initialValues?.duration,
      hasAdmissionQuota: initialValues?.hasAdmissionQuota,
      admissionQuota: initialValues?.admissionQuota,
    });
  }, [initialValues]);

  const usedCampuses = useMemo(
    () => form?.values.offerings.map((offering: any) => offering.campus),
    [form?.values],
  ) as any[];

  // console.log("programme-info", { values: form?.values })

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden "
    >
      <div className="flex-1 space-y-6 divide-y divide-gray-200 overflow-y-auto p-6">
        <div>
          <span className="text-xs font-light">Instance Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="">
              <TextInput
                id="programme.code"
                label="Code"
                type="text"
                placeholder="eg. AP01"
                disabled={true}
                {...form}
              />
            </div>
            {/* <div></div>
            <div></div> */}

            {/* <div className="col-span-1">
              <SchoolPicker
                id="school"
                label="School"
                placeholder="e.g. BSc Computer Science"
                {...form}
                rawId={true}
              />
            </div>

            <div className="col-span-1">
              <DepartmentPicker
                id="department"
                label="Name"
                placeholder="e.g. BSc Computer Science"
                {...form}
                rawId={true}
              />
            </div> */}

            <div className="col-span-2">
              <ProgrammePicker
                id="programme._id"
                rawId
                label="Name"
                filter={{
                  _id: {
                    notIn: modal === 'create' ? usedProgrammes : [],
                  },
                  ...(school && { school: { eq: school } }),
                }}
                placeholder="e.g. BSc Computer Science"
                {...form}
                disabled={modal === 'update'}
              />
            </div>

            <div className="">
              <SelectInput
                handleBlur={() => null}
                handleChange={() => null}
                id="level"
                label="Level"
                placeholder="e.g. Graduate"
                disabled
                options={[
                  { label: '- select -', value: '' },
                  { label: 'Graduate', value: 'GRADUATE' },
                  { label: 'Undergraduate', value: 'UNDERGRADUATE' },
                ]}
                values={programmeData?.programme}
                required={true}
              />
            </div>

            <div className="">
              <TextInput
                id="duration"
                label="Program Duration (Months)"
                type="number"
                placeholder="e.g. 12 Months"
                min={1}
                required={true}
                {...form}
              />
            </div>

            <div></div>

            <div className="col-span-3">
              <MultiCheckInput
                id="tracks"
                label="Tracks"
                options={[
                  {
                    label: 'Semester',
                    value: 'SEMESTER',
                  },
                  {
                    label: 'Trimester',
                    value: 'TRIMESTER',
                  },
                  {
                    label: 'Quarterly',
                    value: 'QUARTERLY',
                  },
                  {
                    label: 'Modular',
                    value: 'MODULAR',
                  },
                ]}
                {...form}
              />
            </div>

            <div className="col-span-3">
              <MultiCheckInput
                id="requirementTypes"
                label="Requirement Types"
                options={[
                  // {
                  //   label: "A-Level",
                  //   value: "ALEVEL"
                  // },
                  // {
                  //   label: "0-Level",
                  //   value: "OLEVEL"
                  // },
                  {
                    label: '0&A Level',
                    value: 'ONALEVEL',
                  },
                  {
                    label: 'WASSCE',
                    value: 'WASSCE',
                  },
                  {
                    label: 'Non Ghanaian WASSCE',
                    value: 'WASSCE_NON_GHANAIAN',
                  },
                  {
                    label: 'SSSCE',
                    value: 'SSSCE',
                  },
                  {
                    label: 'MATURE',
                    value: 'MATURE',
                  },
                  {
                    label: 'Diploma',
                    value: 'DIPLOMA',
                  },
                  {
                    label: 'Baccalaureate',
                    value: 'BACCALAUREATE',
                  },
                  {
                    label: 'HND',
                    value: 'HND',
                  },
                  {
                    label: 'Post-Graduate Diploma',
                    value: 'POST_GRADUATE_DIPLOMA',
                  },
                  {
                    label: 'LLB',
                    value: 'LLB',
                  },
                  {
                    label: 'LLM',
                    value: 'LLM',
                  },
                  {
                    label: 'Degree',
                    value: 'DEGREE',
                  },
                  {
                    label: 'COTVET',
                    value: 'COTVET',
                  },
                ]}
                {...form}
              />
            </div>
          </div>
        </div>

        <div>
          <span className="text-xs font-light">Quota Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="">
              <label
                htmlFor={'hasAdmissionQuota'}
                className="block text-sm font-medium text-gray-700"
              >
                Has Admissions Quota
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `hasAdmissionQuota`)}
                    onChange={(_val: boolean) =>
                      form.setFieldValue(`hasAdmissionQuota`, _val)
                    }
                    className={classNames(
                      _.get(form.values, 'hasAdmissionQuota')
                        ? 'bg-primary-600'
                        : 'bg-gray-200 dark:bg-gray-700',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, 'hasAdmissionQuota')
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-800',
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>

            {_.get(form.values, 'hasAdmissionQuota') && (
              <>
                <div className="">
                  <TextInput
                    id="admissionQuota"
                    label="Admissions Quota"
                    type="number"
                    placeholder="e.g. 100"
                    required={true}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">Offerings Information</span>
          <div className="mt-2 w-full ">
            <table className="min-w-full divide-y divide-gray-300 rounded border border-gray-300">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Campus
                  </th>
                  <th
                    scope="col"
                    className="w-[16%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Day
                  </th>
                  <th
                    scope="col"
                    className="w-[16%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Evening
                  </th>
                  <th
                    scope="col"
                    className="w-[16%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Weekend
                  </th>
                  <th
                    scope="col"
                    className="w-[38px] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {_.times(form.values.offerings?.length + 1, idx => {
                  const id = `offerings.${idx}`;
                  const isLast = form.values.offerings?.length === idx;
                  const isLastButOne =
                    form.values.offerings?.length - 1 === idx;
                  const types = _.get(form.values, `${id}.types`, []);

                  const addType = (type: string) => () => {
                    form.setFieldValue(
                      `${id}.types`,
                      types?.includes(type)
                        ? _.reject(types, (value: string) => value === type)
                        : _.uniq([...types, type]),
                    );
                  };

                  if (
                    isLastButOne &&
                    !types.length &&
                    !_.get(form.touched, `${id}.types`)
                  ) {
                    form.setFieldValue(`${id}.types`, [
                      'DAY',
                      'EVENING',
                      'WEEKEND',
                    ]);
                  }

                  return (
                    <tr key={idx} className="divide-x divide-gray-200">
                      <td className="p-0">
                        <select
                          key={idx}
                          id={`${id}.campus`}
                          name={`${id}.campus`}
                          value={form.values?.offerings?.[idx]?.campus}
                          onChange={wrapOnchange(value => {
                            form.setFieldValue(
                              `${id}.campus`,
                              value?.length ? value : null,
                            );
                          })}
                          onBlur={form.handleBlur}
                          className={classNames(
                            _.get(form.errors, `${id}.campus`) &&
                              _.get(form.touched, `${id}.campus`)
                              ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                              : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                            'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                          )}
                        >
                          <option value="">- select campus -</option>
                          {campusesData?.campuses?.map(
                            (option: any, idx: number) => (
                              <option
                                disabled={usedCampuses.includes(option?._id)}
                                key={idx}
                                value={option?._id}
                              >
                                {option.name}
                              </option>
                            ),
                          )}
                        </select>
                      </td>
                      <td className="p-0">
                        <select
                          key={idx}
                          id={`${id}.types`}
                          name={`${id}.types`}
                          value={types?.includes('DAY') ? 'Yes' : 'No'}
                          onChange={wrapOnchange(addType('DAY'))}
                          onBlur={form.handleBlur}
                          className={classNames(
                            'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                            'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                          )}
                        >
                          {[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ].map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="p-0">
                        <select
                          key={idx}
                          id={`${id}.types`}
                          name={`${id}.types`}
                          value={types?.includes('EVENING') ? 'Yes' : 'No'}
                          onChange={wrapOnchange(addType('EVENING'))}
                          onBlur={form.handleBlur}
                          className={classNames(
                            'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                            'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                          )}
                        >
                          {[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ].map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="p-0">
                        <select
                          key={idx}
                          id={`${id}.types`}
                          name={`${id}.types`}
                          value={types?.includes('WEEKEND') ? 'Yes' : 'No'}
                          onChange={wrapOnchange(addType('WEEKEND'))}
                          onBlur={form.handleBlur}
                          className={classNames(
                            'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                            'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                          )}
                        >
                          {[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ].map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="flex h-[38px] w-[38px] items-center justify-center whitespace-nowrap p-0 text-right text-sm text-gray-500 dark:text-gray-400">
                        {!isLast && (
                          <button
                            type="button"
                            onClick={wrapClick(() =>
                              removeItem('offerings', idx),
                            )}
                            className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          >
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-2 grid grid-cols-2 gap-6">
            {false && (
              <>
                <div className="">
                  <ProgrammePicker
                    id="programme"
                    label="Programme"
                    placeholder="e.g. BSc Computer Science"
                    {...form}
                  />
                </div>

                <div className="col-span-1">
                  <MultiCheckInput
                    id="tracks"
                    label="Tracks"
                    itemsPerRow={3}
                    options={[
                      {
                        label: 'Day',
                        value: 'DAY',
                      },
                      {
                        label: 'Evening',
                        value: 'EVENING',
                      },
                      {
                        label: 'Weekend',
                        value: 'WEEKEND',
                      },
                    ]}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ProgrammeInfoForm;
