import { CheckIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { classNames, useUrlState } from 'utils';

interface StepWizardAltProps {
  steps: any[];
  step: number;
  setStep: (step: number) => void;
  lastStep: number;
}

const StepWizardAlt: FC<StepWizardAltProps> = ({
  steps,
  step: currentStep,
  setStep,
  lastStep,
}) => {
  const [_, setPage] = useUrlState('page');

  const handleClick = (id: any) => {
    setStep(id);
    setPage(id);
  };

  return (
    <nav aria-label="Progress" className="sticky top-0">
      <ol role="list" className="overflow-hidden py-6">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pb-10' : '',
              'relative flex justify-between',
            )}
          >
            {step.href === currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={classNames(
                      step.href === lastStep ? 'bg-gray-300' : 'bg-primary-600',
                      'absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5',
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => {
                    handleClick(step.href);
                  }}
                  disabled={step.href > lastStep}
                  className={classNames(
                    step.href > lastStep
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer',
                    'group relative flex items-start',
                  )}
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-primary-600" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-left text-xs font-semibold uppercase tracking-wide text-primary-600">
                      {step.name}
                    </span>
                    <span className="text-left text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            ) : step.href < lastStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-600"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => handleClick(step.href)}
                  disabled={step.href > lastStep}
                  className={classNames(
                    step.href > lastStep
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer',
                    'group relative flex items-start',
                  )}
                >
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 group-hover:bg-primary-800">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-left text-xs font-semibold uppercase tracking-wide">
                      {step.name}
                    </span>
                    <span className="text-left text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => handleClick(step.href)}
                  disabled={step.href > lastStep}
                  className={classNames(
                    step.href > lastStep
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer',
                    'group relative flex items-start',
                  )}
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span
                        className={classNames(
                          step.href === lastStep
                            ? 'bg-gray-300 group-hover:bg-gray-400'
                            : 'group-hover:bg-gray-300',
                          'h-2.5 w-2.5 rounded-full',
                        )}
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-left text-xs font-semibold uppercase tracking-wide text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-left text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            )}

            <div
              className={classNames(
                step.href === currentStep ? 'bg-white' : 'bg-transparent',
                'h-10 w-10 origin-bottom-right rotate-45 transform',
              )}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepWizardAlt;
