import { classNames, wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { VoucherClassForm, Modal } from 'components';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';

const CREATE_VOUCHER_CLASS = gql`
  mutation CreateVoucherClass($input: VoucherClassInput!) {
    voucherClass: createVoucherClass(input: $input) {
      _id
    }
  }
`;

export default function CreateVoucherClassContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const [createVoucherClass, { loading }] = useMutation(CREATE_VOUCHER_CLASS);

  const form = useFormik({
    initialValues: {
      name: '',
      price: 0,
      description: '',
      level: '',
    },
    onSubmit: async values => {
      await createVoucherClass({
        variables: {
          input: {
            ...values,
            price: parseInt(`${values.price * 100}`),
            cycle: params.admissionCycle,
          },
        },
      }).then(({ data }) => {
        if (data?.voucherClass?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Class created successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not create class' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Create Class"
      description="Provide the details to create class"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className={classNames(
              loading ? 'cursor-not-allowed' : 'cursor-pointer',
              'inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? 'Creating Class...' : 'Create Class'}
          </button>
        </>
      )}
    >
      <VoucherClassForm form={form} />
    </Modal>
  );
}
