import { makeVar } from '@apollo/client';
import Cookies from 'js-cookie';
import config from 'config';
import { Permissions } from 'apollo/data';
import { toast } from 'react-hot-toast';

export const UserRoles = [
  'SUDO',
  'DEAN',
  'ADMISSIONS_OFFICER',
  'SCHOOL_ADMINISTRATOR',
  'DEPARTMENT_HEAD',
  'FINANCE_OFFICER',
  'CAMPUS_OFFICER',
  'SCHOOL_REGISTRAR',
  'OBSERVER',
  'CAMPUS_MANAGER',
] as const;
export type UserRole = (typeof UserRoles)[number];
export const UserPermissions = Permissions.map(({ value }) => value);
export type UserPermission = (typeof UserPermissions)[number];
export const PermissionsMap: { [key in UserRole]: UserPermission[] } = {
  SUDO: ['*:*'],
  DEAN: [
    'programmes:*',
    'programmes:manage',
    'programmes:create',
    'programmes:update',
    'departments:*',
    'application:manage',
    'application:import',
    'shortlist:manage',
    'schools:manage',
    'vendors:manage',
    'campuses:manage',
    'admissions:manage',
    'enrollment:manage',
    'admission-cycles:manage',
    'admission-programmes:manage',
    'enrollment:export',
  ],
  ADMISSIONS_OFFICER: [
    'admission-cycles:*',
    'admission-programmes:*',
    'shortlist:programme-offer',
    'application:*',
    'shortlist:*',
    'campuses:*',
    'tuition:*',
    'voucher-classes:*',
    'departments:*',
    'admissions:import',
    'admissions:manage',
    'programmes:*',
    'invoices:manage',
    'mailing:*',
    'schools:*',
    'reporting:*',
    'admissions:export',
    'enrollment:export',
    'admissions:transfer',
  ],
  SCHOOL_ADMINISTRATOR: [
    'admission-cycles:manage',
    'admission-programmes:manage',
    'shortlist:programme-offer',
    'application:manage',
    'shortlist:manage',
    'campuses:manage',
    'admission-programmes:delete',
    'admission-programmes:create',
    'admission-programmes:update',
    'admissions:export',
    'admissions:manage',
    'admissions:upload-admissions',
    'enrollment:manage',
    'departments:manage',
    'programmes:manage',
    'campuses:manage',
    'schools:manage',
    'vendors:manage',
    'enrollment:export',
    'admissions:manage',
  ],
  SCHOOL_REGISTRAR: [
    'admission-cycles:manage',
    'admission-programmes:manage',
    'campuses:manage',
    'shortlist:manage',
    'shortlist:approve',
    'shortlist:programme-offer',
    'shortlist:update',
    'admissions:export',
    'enrollment:export',
    'schools:manage',
    'programmes:manage',
    'departments:manage',
    'vendors:manage',
    'application:manage',
    'shortlist:manage',
    'shortlist:approve',
    'shortlist:review',
    'shortlist:programme-offer',
    'admissions:manage',
    'admission-cycles:manage',
    'admission-programmes:create',
    'admission-programmes:update',
    'admission-programmes:manage',
    'admission-programmes:delete',
    'enrollment:manage',
  ],
  DEPARTMENT_HEAD: [
    'admission-programmes:manage',
    'admission-cycles:manage',
    'application:manage',
    'shortlist:manage',
    'shortlist:review',
    'shortlist:update',
    'shortlist:approve',
    'shortlist:programme-offer',
    'programmes:manage',
    'campuses:manage',
    'departments:manage',
    'schools:manage',
    'vendors:manage',
    'admissions:export',
    'enrollment:manage',
    'enrollment:export',
    'admissions:manage',
    'shortlist:update',
  ],
  FINANCE_OFFICER: [
    'admission-cycles:manage',
    'invoices:*',
    'vendors:*',
    'admissions:*',
    'application:manage',
    'campuses:manage',
    'departments:manage',
    'programmes:manage',
    'schools:manage',
    'voucher-classes:manage',
    'vendors:manage',
    'tuition:manage',
    'application:manage',
    'admissions:manage',
    'enrollment:manage',
  ],
  CAMPUS_OFFICER: [
    'admission-cycles:manage',
    'campuses:manage',
    'admission-programmes:manage',
    'departments:manage',
    'programmes:manage',
    'schools:manage',
    'enrollment:manage',
    'enrollment:import',
    'application:manage',
    'admissions:upload-admissions',
    'admissions:manage',
    'shortlist:manage',
    'admissions:export',
    'admissions:import',
    'vendors:manage',
    'shortlist:programme-offer',
    'admission-programmes:delete',
    'admission-programmes:create',
    'admission-programmes:update',
    'enrollment:export',
  ],
  CAMPUS_MANAGER: [
    'admission-cycles:manage',
    'campuses:manage',
    'admission-programmes:manage',
    'departments:manage',
    'programmes:manage',
    'schools:manage',
    'enrollment:manage',
    'application:manage',
    'admissions:upload-admissions',
    'admissions:manage',
    'shortlist:*',
    'admissions:export',
    'admissions:import',
    'vendors:manage',
    'admission-programmes:manage',
    'enrollment:export',
  ],
  OBSERVER: [
    'admission-cycles:manage',
    'campuses:manage',
    'admission-programmes:manage',
    'departments:manage',
    'programmes:manage',
    'schools:manage',
    'enrollment:manage',
    'application:manage',
    'admissions:manage',
    'vendors:manage',
    'admission-programmes:manage',
    'enrollment:export',
  ],
};

interface IUser {
  _id: string;
  code: string;
  name: string;
  gender?: 'MALE' | 'FEMALE';
  phone: string;
  email: string;
  profileImageUrl: string;
  department: {
    _id: string;
    code: string;
    name: string;
  };
  school: {
    _id: string;
    code: string;
    name: string;
  };
  campus: {
    _id: string;
    code: string;
    name: string;
  };
  meta: {
    isFirstLogin: boolean;
    isSuspended: boolean;
  };
  role: UserRole;
  permissions: UserPermission[];
  createdAt: Date;
  updatedAt: Date;
}

export const isLoggedInVar = makeVar<boolean>(
  !!Cookies.get(`${config.name}:auth:token`),
);
export const currentTokenVar = makeVar<string | null>(
  Cookies.get(`${config.name}:auth:token`) || null,
);
export const currentUserVar = makeVar<IUser>(
  JSON.parse(Cookies.get(`${config.name}:auth:user`) ?? '{}') ?? null,
);
export const currentPushTokenVar = makeVar<string | null>(
  Cookies.get(`${config.name}:auth:push-token`) || null,
);

export const setAuth = ({ user, token }: { user: IUser; token: string }) => {
  const userToSet = {
    ...user,
    permissions: (PermissionsMap as any)[user.role],
  };
  currentUserVar(userToSet);
  Cookies.set(`${config.name}:auth:user`, JSON.stringify(userToSet), {
    ...config.cookies,
  });
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set(`${config.name}:auth:token`, token, {
    ...config.cookies,
    expires: 1,
  });
};

export const setMe = (user: IUser) => {
  const userToSet = {
    ...user,
    permissions: (PermissionsMap as any)[user.role],
  };
  currentUserVar(userToSet);
  Cookies.set(`${config.name}:auth:user`, JSON.stringify(userToSet), {
    ...config.cookies,
  });
};

export const setPushToken = (token: any) => {
  currentPushTokenVar(token);
  Cookies.set(`${config.name}:auth:push-token`, token, { ...config.cookies });
};

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  localStorage.setItem('forgot-store', '');
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach(key => {
    Cookies.remove(key, { ...config.cookies });
  });
};

export const initiateClearAuth = () => {
  toast(
    JSON.stringify({
      type: 'logout',
      title: 'Are you sure you want to log out?',
    }),
    { duration: 1000 * 10, id: 'logout' },
  );
};
