import { useReactiveVar } from '@apollo/client';
import {
  ClipboardDocumentListIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { RichTextArea } from 'components/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useMatch, useSearch } from 'react-location';
import { wrapClick } from 'utils';
import { LocationGenerics } from 'router/location';
import { currentUserVar } from 'apollo/cache/auth';

interface AdmissionCycleInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  handleCancel: () => void;
  initialValues: any;
  values: any;
}

const PointersInfoForm: FC<AdmissionCycleInfoFormProps> = ({
  handleNext,
  handlePrevious,
  handleCancel,
  values,
  initialValues,
}) => {
  const searchParams = useSearch<LocationGenerics>();

  const currentUser = useReactiveVar(currentUserVar);

  const { params } = useMatch<LocationGenerics>();

  const academicCycleId = searchParams?.id;

  const [copiedValue, setCopiedValue] = useState('');

  const form = useFormik({
    initialValues: {
      pointers: [] as any[],
    },
    // validationSchema: AdmissionProgrammeSchema,
    onSubmit: async ({ ...formValues }) => {
      handleNext(formValues);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    form.setValues({
      pointers: initialValues?.pointers || [],
    });
  }, [initialValues]);

  useEffect(() => {
    setTimeout(() => {
      setCopiedValue('');
    }, 4000);
  }, [copiedValue]);

  const dynamicValues = [
    {
      label: 'Student ID',
      value: '${studentId}',
    },
    {
      label: 'Student Name',
      value: '${studentName}',
    },
    {
      label: 'School',
      value: '${school}',
    },
    {
      label: 'Programme',
      value: '${programmeName}',
    },
    {
      label: 'Programme Duration',
      value: '${programmeDuration}',
    },
    {
      label: 'Offering Type',
      value: '${offeringType}',
    },
    {
      label: 'Starting Year',
      value: '${startingYear}',
    },
    {
      label: 'Campus',
      value: '${campus}',
    },
    {
      label: 'Reporting Date',
      value: '${reportingDate}',
    },
  ];

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden"
    >
      <div className="flex flex-1 flex-col space-y-6 divide-gray-200 overflow-y-auto p-6">
        <div className="flex-1 space-y-6">
          <div className="space-y-5 rounded-md p-3">
            <div>
              <span className="text-xs font-light">Instructions </span>
              <div className="mt-2 grid grid-cols-2 gap-6">
                <div className="col-span-2 text-sm text-gray-500">
                  Click to copy the dynamic values below to use them in your
                  pointers
                </div>
                <div className="col-span-2 flex flex-row flex-wrap items-center gap-2">
                  {dynamicValues?.map(value => (
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        navigator.clipboard.writeText(value?.value);
                        setCopiedValue(value?.value);
                      }}
                      className="group relative flex cursor-pointer flex-row self-center rounded-md bg-gray-100 px-2 py-1 text-sm text-gray-600 transition-all duration-150 hover:bg-gray-200"
                    >
                      {value?.label}
                      <span className="absolute right-0 top-1.5 flex flex-row bg-gradient-to-r from-gray-200 to-gray-200 pl-1.5 pr-1 opacity-0 transition-all duration-100 group-hover:opacity-100">
                        {value.value === copiedValue && (
                          <span className="mr-1 text-[10px]">Copied</span>
                        )}{' '}
                        <ClipboardDocumentListIcon className="h-4 w-4" />
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {form?.values?.pointers?.map((_, index) => (
            <div className="space-y-5 rounded-md border-2 border-dashed p-3">
              <div>
                <span className="text-xs font-light">Pointer {index + 1}</span>
                <div className="mt-2 grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <RichTextArea
                      id={`pointers.${index}`}
                      label=""
                      placeholder="eg. Students should ensure that their index numbers..."
                      {...form}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-3 flex flex-row-reverse">
                <button
                  onClick={() =>
                    form.setFieldValue(
                      'pointers',
                      form.values?.pointers?.filter(
                        (_, itemIndex) => index !== itemIndex,
                      ),
                    )
                  }
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-gray-300 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row justify-center">
          <button
            // disabled={loading}
            onClick={wrapClick(() =>
              form.setFieldValue('pointers', [...form.values?.pointers, '']),
            )}
            className="inline-flex w-full justify-center rounded border-2 border-dashed border-gray-300 bg-transparent px-4 py-2 text-base  font-medium text-gray-500 shadow-sm transition-all duration-150 hover:border-solid hover:shadow-sm focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:bg-gray-600 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon className="mr-1 h-5 w-5" />
            Add Pointer
          </button>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          disabled={false}
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-300 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Continue
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PointersInfoForm;
