import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, VendorForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { VendorSchema } from 'components/forms/vendor/schema';

const GET_VENDOR = gql`
  query GetVendorLean($filter: VendorFilter!) {
    vendor: getVendor(filter: $filter) {
      _id
      code
      email
      name
      phone
      description
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: ID!, $input: VendorInput!) {
    vendor: updateVendor(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateVendorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_VENDOR, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateVendor, { loading: updating }] = useMutation(UPDATE_VENDOR);

  const form = useFormik({
    initialValues: {
      code: '',
      email: '',
      name: '',
      phone: '',
      description: '',
    },
    validationSchema: VendorSchema,
    onSubmit: async ({ code, ...values }) => {
      await updateVendor({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data?.vendor?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Vendor updated successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not update Vendor' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      name: data?.vendor?.name || '',
      code: data?.vendor?.code || '',
      email: data?.vendor?.email || '',
      phone: data?.vendor?.phone || '',
      description: data?.vendor?.description || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.vendor, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Edit Vendor Information"
      description="Provide the details to update vendor"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing Vendor...' : 'Edit Vendor'}
          </button>
        </>
      )}
    >
      {data?.vendor?._id && <VendorForm form={form} />}
    </Modal>
  );
}
