import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { currentUserVar } from 'apollo/cache/auth';

interface AdmissionProgrammePickerContainerProps {
  filter?: {
    school?: { eq: string };
    department?: { eq: string };
    programme?: { eq: string };
    cycle?: { eq: string };
    deleted?: { eq: boolean };
    level?: { eq: string };
    _id?: { eq: string };
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_ADMISSION_PROGRAMMES = gql`
  query GetAdmissionProgrammes($filter: AdmissionProgrammeFilter) {
    programmes: getAdmissionProgrammes(filter: $filter) {
      _id
      code
      name
    }
  }
`;

const AdmissionProgrammePickerContainer: FC<
  AdmissionProgrammePickerContainerProps
> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_ADMISSION_PROGRAMMES, {
    variables: {
      filter: {
        ...(filter ? filter : {}),
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? 'admissionProgramme'}
      label={label ?? 'Admission Programme'}
      placeholder={
        (data?.programmes ?? []).length > 0
          ? 'Select Programme'
          : 'No admission programmes found'
      }
      optionsLoading={loading}
      options={(data?.programmes ?? [])?.map((admission_programme: any) => ({
        label: {
          title: admission_programme.name as string,
        },
        value: rawId ? admission_programme._id : admission_programme,
      }))}
      {...form}
      disabled={form.disabled || (data?.programmes ?? []).length === 0}
    />
  );
};

export default AdmissionProgrammePickerContainer;
