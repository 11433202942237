import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useMemo } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import { MoonLoader } from 'react-spinners';
import BarChart from 'components/charts/bar-chart';
import numeral from 'numeral';
dayjs.extend(weekOfYear);

interface ShortlistedBarChartContainerProps {
  cycle?: string;
  numberOfDays?: number;
  dates: {
    start: string;
    end: string;
  };
}

const GET_SHORTLISTED_STATS = gql`
  query Query($filter: CountShortlistFilter) {
    countShortlistedByDay(filter: $filter)
  }
`;

// const GET_APPLICATIONS_STATS = gql`
//   query Query($filter: CountApplicationsFilter) {
//     countApplicationsByDay(filter: $filter)
//   }
// `;

const ShortlistedDayBarChartContainer: FC<
  ShortlistedBarChartContainerProps
> = ({ cycle, dates, numberOfDays }) => {
  const days = numberOfDays || 30;
  const filters = useMemo(() => {
    const end = dayjs(dates?.start || new Date());
    const start = dayjs(dates?.end || end.subtract(days, 'day'));

    return {
      createdAt: {
        between: {
          start: start.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD'),
        },
      },
      ...(cycle && {
        cycle: {
          eq: cycle,
        },
      }),
    };
  }, [cycle, dates, days]);

  const { loading, data } = useQuery(GET_SHORTLISTED_STATS, {
    variables: {
      filter: filters,
    },
  });

  // const { loading: loadingApplications, data: applications } = useQuery(
  //   GET_APPLICATIONS_STATS,
  //   {
  //     variables: {
  //       filter: filters,
  //     },
  //   }
  // );

  const colorUnder = 'rgb(1, 124, 184)';
  const colorGrad = 'rgb(210, 82, 233)';

  const barData: any[] = data?.countShortlistedByDay?.map((record: any) => ({
    day: record.date,
    graduates: record?.graduate,
    graduatesColor: colorGrad,
    undergraduates: record?.undergraduate,
    undergraduatesColor: colorUnder,
  }));

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col self-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Shortlisted{' '}
            <span className="text-gray-500">(last {days} days)</span>
          </h3>
          <p className="text-xs text-gray-400">
            Each date represents a date admissions where processed to
            shortlisted
          </p>
        </div>

        <div>{loading && <MoonLoader size={20} />}</div>
      </div>
      <BarChart
        keys={['graduates', 'undergraduates']}
        indexBy="day"
        xLabel="Date"
        xLabelFormat={value => `${dayjs(value).format('Do MMM YY')}`}
        yLabel="No. of Shortlisted"
        data={barData || []}
      />
    </>
  );
};

export default ShortlistedDayBarChartContainer;
