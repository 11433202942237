import { classNames, wrapClick } from 'utils';
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import OfferAdmissionsForm from 'components/forms/offer-programme';
import { useEffect } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import * as Yup from 'yup';
import { OfferProgrammeInitialValues } from 'components/forms/offer-programme/schema';

const OFFER_PROGRAMME = gql`
  mutation OfferAdmission($input: OfferAdmissionInput!) {
    offerAdmission(input: $input) {
      _id
      code
    }
  }
`;

const GET_SHORTLISTED = gql`
  query GetShortlisting($filter: ShortlistFilter!, $populate: [String]) {
    shortlist: getShortlisting(filter: $filter, populate: $populate) {
      _id
      code
      createdAt
      updatedAt
      applicant {
        code
        email
        _id
      }
      cycle {
        _id
        code
        name
      }
      examinationScore
      interviewScore
      programme {
        _id
        name
        requiresInterview
        requiresExamination
        level
      }
      lastUpdatedBy {
        _id
        name
      }
      application {
        personalInformation {
          profileImageUrl
          firstName
          lastName
        }
        programmeInformation {
          preferredOfferingType
          preferredCampus {
            _id
          }
        }
      }
      offeredProgramme {
        _id
        name
        school {
          _id
          name
        }
        department {
          _id
          name
        }
      }
      offeredProgrammeRemark
      offeringType
      campus {
        _id
      }
      startingYear
    }
  }
`;

export default function OfferProgrammeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();

  const currentUser = useReactiveVar(currentUserVar);

  const [
    getShortlisted,
    {
      data: shortlistData,
      loading: shortlistLoading,
      refetch: refetchShortlisting,
    },
  ] = useLazyQuery(GET_SHORTLISTED, {
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'no-cache',
  });

  const [offerProgramme, { loading }] = useMutation(OFFER_PROGRAMME);

  const form = useFormik({
    initialValues: {
      school: '',
      department: '',
      campus: '',
      programme: '',
      level: '',
      offeredProgrammeRemark: '',
      offeringType: '',
      startingYear: '1',
    },
    validationSchema: Yup.object().shape({
      school: Yup.string().required(),
      department: Yup.string().required(),
      programme: Yup.string().required(),
      offeringType: Yup.string().required(),
    }),
    onSubmit: async values => {
      await offerProgramme({
        variables: {
          input: {
            shortlistId: searchParams.id,
            offeredProgramme: values?.programme,
            offeredProgrammeRemark: values?.offeredProgrammeRemark,
            offeringType: values?.offeringType,
            campus: values?.campus,
            startingYear: parseInt(values?.startingYear),
          },
        },
      }).then(({ data }) => {
        if (data?.offerAdmission) {
          toast(
            JSON.stringify({
              type: 'success',
              title: `Applicant has been offered a new programme`,
            }),
          );
          refetch?.();
          refetchShortlisting?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not offer new programme',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (open) {
      getShortlisted({
        variables: {
          filter: {
            _id: {
              eq: searchParams.id,
            },
          },
          populate: [
            'lastUpdatedBy',
            'programme',
            'applicant',
            'cycle',
            'application.personalInformation',
            'application.programmeInformation',
            'offeredProgramme',
          ],
        },
      });
    }
    if (open === false) {
      form.setValues(OfferProgrammeInitialValues);
    }
  }, [open]);

  useEffect(() => {
    const payload = {
      offeringType:
        shortlistData?.shortlist?.offeringType ||
        shortlistData?.shortlist.application?.programmeInformation
          ?.preferredOfferingType,
      campus:
        shortlistData?.shortlist?.campus?._id ||
        shortlistData?.shortlist.application?.programmeInformation
          ?.preferredCampus?._id,
      startingYear: shortlistData?.shortlist?.startingYear ?? '1',
    };

    // if the user has a department or school already
    form.setValues({
      ...form.values,
      ...(currentUser?.school?._id && { school: currentUser?.school?._id }),
      ...(currentUser?.department?._id && {
        department: currentUser?.department?._id,
      }),
      ...payload,
    });

    if (shortlistData?.shortlist?.offeredProgramme) {
      form.setValues({
        ...payload,
        ...(shortlistData?.shortlist?.offeredProgramme?.school?._id && {
          school: shortlistData?.shortlist?.offeredProgramme?.school?._id,
        }),
        ...(shortlistData?.shortlist?.offeredProgramme?.department?._id && {
          department:
            shortlistData?.shortlist?.offeredProgramme?.department?._id,
        }),
        ...(shortlistData?.shortlist?.offeredProgramme?._id && {
          programme: shortlistData?.shortlist?.offeredProgramme?._id,
        }),
        ...(shortlistData?.shortlist?.offeredProgrammeRemark && {
          offeredProgrammeRemark:
            shortlistData?.shortlist?.offeredProgrammeRemark,
        }),
      });
    }
  }, [shortlistData]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Offer Applicant Programme"
      description="Choose a new programme to offer applicant"
      renderActions={() => (
        <button
          type="button"
          disabled={loading || shortlistLoading || !form.values?.programme}
          className={classNames(
            loading ? 'cursor-not-allowed' : 'cursor-pointer',
            'inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-400 sm:ml-3 sm:w-auto sm:text-sm',
          )}
          onClick={wrapClick(form.handleSubmit)}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      )}
    >
      <OfferAdmissionsForm form={form} />
    </Modal>
  );
}
