import { FC } from 'react';
import { Avatar } from 'components/core';
import { classNames } from 'utils';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import config from 'config';
import _ from 'lodash';
import parseHtml from 'html-react-parser';
import { EXAMINATION_GRADING, ExaminationType } from 'utils/general';

interface AdmissionProgrammeViewProps {
  admissionProgramme: {
    code: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    duration: string;
    school: {
      _id: string;
      code: string;
      name: string;
    };
    level: string;
    department: {
      _id: string;
      code: string;
      name: string;
      description: string;
      level: string;
    };
    programme: {
      _id: string;
      code: string;
      name: string;
      description: string;
      level: string;
    };
    tracks: string[];
    offerings: {
      campus: {
        _id: string;
        code: string;
        name: string;
      };
      types: string[];
    }[];
    requirements: {
      type: string;
      description: string;
      specifications: {
        subject: string;
        requiredGrade: number;
      }[];
    }[];
    requiresInterview: boolean;
    maximumInterviewScore: number;
    requiredInterviewScore: number;
    interviewScoreWeight: number;
    requiresExamination: boolean;
    maximumExaminationScore: number;
    requiredExaminationScore: number;
    examinationScoreWeight: number;
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      gender: string;
      role: string;
      meta: {
        isFirstLogin: boolean;
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      gender: string;
      role: string;
      meta: {
        isFirstLogin: boolean;
        isSuspended: boolean;
      };
    };
  };
}

const AdmissionProgrammeViewLite: FC<AdmissionProgrammeViewProps> = ({
  admissionProgramme,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <span className="text-xs font-light">Programme Information</span>

        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Programme Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.programme?.code || 'N/A'}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Programme Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.programme?.name || 'N/A'}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Department Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.department?.name || 'N/A'}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Available Tracks
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.tracks?.join(', ') || 'N/A'}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Requirement Types
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.requirements
                ?.map(requirement => requirement.type?.replaceAll('_', ' '))
                ?.join(', ') || 'N/A'}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Programme Duration (Years)
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.duration || 'Not set'}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Available Offerings
            </span>
            <div className="mt-1 w-full ">
              <table className="min-w-full divide-y divide-gray-300 rounded border border-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Campus
                    </th>
                    <th
                      scope="col"
                      className="w-[15%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                    >
                      Day
                    </th>
                    <th
                      scope="col"
                      className="w-[15%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                    >
                      Evening
                    </th>
                    <th
                      scope="col"
                      className="w-[15%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                    >
                      Weekend
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {admissionProgramme?.offerings?.map(
                    (step: any, idx: number) => (
                      <tr key={idx} className="divide-x divide-gray-200">
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                          {step?.campus?.name || 'N/A'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-700">
                          {step?.types?.includes('DAY') ? 'Yes' : 'No'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-700">
                          {step?.types?.includes('EVENING') ? 'Yes' : 'No'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-700">
                          {step?.types?.includes('WEEKEND') ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionProgrammeViewLite;
