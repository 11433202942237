import { XMarkIcon } from '@heroicons/react/24/outline';
import { RichTextArea } from 'components/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect } from 'react';
import { classNames, wrapClick } from 'utils';
import { Switch } from '@headlessui/react';

const EXAMINATION_GRADING = {
  ALEVEL: [
    { label: '- select -', value: '' },
    { label: 'A*', value: 1 },
    { label: 'A', value: 2 },
    { label: 'B', value: 3 },
    { label: 'C', value: 4 },
    { label: 'D', value: 5 },
    { label: 'E', value: 6 },
    { label: 'U', value: 7 },
  ],
  OLEVEL: [
    { label: '- select -', value: '' },
    { label: 'A*', value: 1 },
    { label: 'A', value: 2 },
    { label: 'B', value: 3 },
    { label: 'C', value: 4 },
    { label: 'D', value: 5 },
    { label: 'E', value: 6 },
    { label: 'F', value: 7 },
    { label: 'G', value: 8 },
    { label: 'U', value: 9 },
  ],
  WASSCE: [
    { label: '- select -', value: '' },
    { label: 'A1', value: 1 },
    { label: 'B2', value: 2 },
    { label: 'B3', value: 3 },
    { label: 'C4', value: 4 },
    { label: 'C5', value: 5 },
    { label: 'C6', value: 6 },
    { label: 'D7', value: 7 },
    { label: 'E8', value: 8 },
    { label: 'F9', value: 9 },
  ],
  SSSCE: [
    { label: '- select -', value: '' },
    { label: 'A', value: 1 },
    { label: 'B', value: 2 },
    { label: 'C', value: 3 },
    { label: 'D', value: 4 },
    { label: 'E', value: 5 },
    { label: 'F', value: 6 },
  ],
};

const subjects = [
  'ANIMAL HUSBANDRY',
  'BASKETRY',
  'BIOLOGY',
  'BUILDING CONST',
  'BUSINESS MANAGEMENT',
  'CERAMICS',
  'CHEMISTRY',
  'CHRISTIAN RELIGIOUS STUDIES',
  'CLERICAL OFFICE DUTIES',
  'CLOTHING & TEXTILES',
  'CROP HUSB & HORTICULTURE',
  'DAGBANI',
  'DANGME',
  'ECONOMICS',
  'ENGLISH LANGUAGE',
  'EWE',
  'FANTE',
  'FINANCIAL ACCOUNTING',
  'FOODS & NUTRITION',
  'FRENCH',
  'GA',
  'GENERAL KNOWLEDGE IN ART',
  'GENERAL AGRICULTURE',
  'GEOGRAPHY',
  'GOVERNMENT',
  'GRAPHIC DESIGN',
  'HISTORY',
  'INFO. & COMM. TECHNOLOGY',
  'INTEGRATED SCIENCE',
  'ISLAMIC REL STUD',
  'JEWELLERY',
  'LEATHERWORK',
  'WOODWORK',
  'LITERATURE-IN-ENGLISH',
  'MANAGEMENT IN LIVING',
  'MATHEMATICS(CORE)',
  'MATHEMATICS(ELECT)',
  'MGT IN LIVING',
  'MUSIC',
  'PHYSICS',
  'PICTURE MAKING',
  'PRIN OF COST ACCOUNTING',
  'SCULPTURE',
  'SOCIAL STUDIES',
  'TECHNICAL DRAWING',
  'TEXTILES',
  'TWI(AKUAPEM)',
  'TWI(ASANTE)',
  'TYPEWRITING (40 W.P.M.)',
  'GONJA',
  'NZEMA',
];

const EXAMINATION_SUBJECTS = {
  ALEVEL: [
    { label: '- select -', value: '' },
    ...subjects.map(subject => ({ label: subject, value: subject })),
  ],
  OLEVEL: [
    { label: '- select -', value: '' },
    ...subjects.map(subject => ({ label: subject, value: subject })),
  ],
  WASSCE: [
    { label: '- select -', value: '' },
    ...subjects.map(subject => ({ label: subject, value: subject })),
  ],
  SSSCE: [
    { label: '- select -', value: '' },
    ...subjects.map(subject => ({ label: subject, value: subject })),
  ],
};

interface RequirementsInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const RequirementsInfoForm: FC<RequirementsInfoFormProps> = ({
  initialValues,
  values,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    onSubmit: values => {
      // normalise items here
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    // console.log({ vals: form?.values })
  }, [form]);

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id,
      ),
    ]);
  };

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden"
    >
      <div className="flex-1 space-y-6 divide-y divide-gray-200 overflow-y-auto p-6">
        {[...(values?.programmeInfo?.requirementTypes || [])]?.map(
          (requirementType: string, idx: number) => {
            const id = `requirements.${idx}`;
            // console.log("ID: ", id)
            const specifications = _.get(
              form.values,
              `${id}.specifications`,
              [],
            );
            // console.log("SPECIFICATIONS: ", specifications)
            if (_.get(form.values, `${id}.type`) !== requirementType) {
              form.setFieldValue(`${id}.type`, requirementType);
              form.setFieldValue(`${id}.enabled`, true);
            }

            return (
              <div className="pt-6 first:pt-0">
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-bold uppercase">
                    {requirementType.replaceAll('_', ' ')} Requirements
                  </span>

                  <Switch.Group as="div" className="">
                    <Switch
                      checked={_.get(form.values, `${id}.enabled`)}
                      onChange={(_val: boolean) =>
                        form.setFieldValue(`${id}.enabled`, _val)
                      }
                      className={classNames(
                        _.get(form.values, `${id}.enabled`)
                          ? 'bg-primary-600'
                          : 'bg-gray-200 dark:bg-gray-700',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          _.get(form.values, `${id}.enabled`)
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-800',
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-2 grid gap-6">
                  <div className="">
                    <RichTextArea
                      id={`${id}.description`}
                      label="Description"
                      placeholder="eg. Requirements for this programme is only accepted based on evaluation from G-Tech..."
                      required={true}
                      disabled={!Boolean(_.get(form.values, `${id}.enabled`))}
                      {...form}
                    />
                  </div>
                  {['WASSCE', 'SSSCE'].includes(requirementType) && (
                    <>
                      <div className="p-0">
                        <span className="text-xs font-light uppercase">
                          Minimum Required Grade for{' '}
                          {Math.abs(
                            _.get(
                              form.values,
                              `${id}.numberOfPassedSubjects`,
                              '',
                            ),
                          )}{' '}
                          subjects ({requirementType})
                        </span>
                        <div className="grid grid-cols-5">
                          <div className="col-span-4">
                            <select
                              id={`${id}.defaultMinimumGrade`}
                              name={`${id}.defaultMinimumGrade`}
                              value={Math.abs(
                                _.get(
                                  form.values,
                                  `${id}.defaultMinimumGrade`,
                                  '',
                                ),
                              )}
                              disabled={
                                !Boolean(_.get(form.values, `${id}.enabled`))
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              className={classNames(
                                _.get(
                                  form.errors,
                                  `${id}.defaultMinimumGrade`,
                                ) &&
                                  _.get(
                                    form.touched,
                                    `${id}.defaultMinimumGrade`,
                                  )
                                  ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                                  : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                                ' border-1 block h-[38px] w-full border-gray-200 placeholder:text-xs placeholder:font-light focus:border disabled:bg-gray-100 sm:text-sm',
                              )}
                            >
                              {_.get(
                                EXAMINATION_GRADING,
                                requirementType,
                                [],
                              ).map((option: any, iddxx) => (
                                <option
                                  key={iddxx}
                                  value={parseInt(option.value)}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <input
                              type="number"
                              name={`${id}.numberOfPassedSubjects`}
                              id={`${id}.numberOfPassedSubjects`}
                              value={Math.abs(
                                _.get(
                                  form.values,
                                  `${id}.numberOfPassedSubjects`,
                                  '',
                                ),
                              )}
                              disabled={
                                !Boolean(_.get(form.values, `${id}.enabled`))
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              placeholder="No. of subjects"
                              className={classNames(
                                _.get(
                                  form.errors,
                                  `${id}.numberOfPassedSubjects`,
                                ) &&
                                  _.get(
                                    form.touched,
                                    `${id}.numberOfPassedSubjects`,
                                  )
                                  ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                                  : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                                ' border-1 block h-[38px] w-full border-gray-200 placeholder:text-xs placeholder:font-light focus:border disabled:bg-gray-100 sm:text-sm',
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py">
                        <span className="block text-sm font-medium text-gray-700">
                          Specifications *
                        </span>
                        <div className="mt-1 w-full ">
                          <table className="min-w-full divide-y divide-gray-300 rounded border border-gray-300">
                            <thead className="bg-gray-50">
                              <tr className="divide-x divide-gray-200">
                                <th
                                  scope="col"
                                  className="w-full whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                >
                                  Subject
                                </th>
                                <th
                                  scope="col"
                                  className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                                >
                                  Minimum Grade
                                </th>
                                <th
                                  scope="col"
                                  className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                                >
                                  Mandatory
                                </th>
                                <th
                                  scope="col"
                                  className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900"
                                ></th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {_.times(specifications?.length + 1, iddx => {
                                const idd = `${id}.specifications.${iddx}`;
                                const isLast =
                                  (specifications?.length || 0) === iddx;
                                const specificationSubjects =
                                  specifications?.map(
                                    (spec: any) => spec?.subject,
                                  );
                                return (
                                  <tr
                                    key={iddx}
                                    className="divide-x divide-gray-200"
                                  >
                                    <td className="p-0">
                                      <select
                                        key={iddx}
                                        id={`${idd}.subject`}
                                        name={`${idd}.subject`}
                                        value={_.get(
                                          form.values,
                                          `${idd}.subject`,
                                          '',
                                        )}
                                        disabled={
                                          !Boolean(
                                            _.get(form.values, `${id}.enabled`),
                                          )
                                        }
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        className={classNames(
                                          _.get(
                                            form.errors,
                                            `${idd}.subject`,
                                          ) &&
                                            _.get(
                                              form.touched,
                                              `${idd}.subject`,
                                            )
                                            ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                                            : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                                          'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border disabled:bg-gray-100 sm:text-sm',
                                        )}
                                      >
                                        {_.get(
                                          EXAMINATION_SUBJECTS,
                                          requirementType,
                                          [],
                                        ).map((option: any, iddxx) => (
                                          <option
                                            disabled={specificationSubjects.includes(
                                              option.value,
                                            )}
                                            key={iddxx}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className="p-0">
                                      <select
                                        key={iddx}
                                        id={`${idd}.requiredGrade`}
                                        name={`${idd}.requiredGrade`}
                                        value={_.get(
                                          form.values,
                                          `${idd}.requiredGrade`,
                                          '',
                                        )}
                                        disabled={
                                          !Boolean(
                                            _.get(form.values, `${id}.enabled`),
                                          )
                                        }
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        className={classNames(
                                          _.get(
                                            form.errors,
                                            `${idd}.requiredGrade`,
                                          ) &&
                                            _.get(
                                              form.touched,
                                              `${idd}.requiredGrade`,
                                            )
                                            ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                                            : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                                          'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border disabled:bg-gray-100 sm:text-sm',
                                        )}
                                      >
                                        {_.get(
                                          EXAMINATION_GRADING,
                                          requirementType,
                                          [],
                                        ).map((option: any, iddxx) => (
                                          <option
                                            key={iddxx}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className="p-0">
                                      <select
                                        key={iddx}
                                        id={`${idd}.mandatory`}
                                        name={`${idd}.mandatory`}
                                        value={_.get(
                                          form.values,
                                          `${idd}.mandatory`,
                                          'no',
                                        )}
                                        disabled={
                                          !Boolean(
                                            _.get(form.values, `${id}.enabled`),
                                          )
                                        }
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        className={classNames(
                                          _.get(
                                            form.errors,
                                            `${idd}.mandatory`,
                                          ) &&
                                            _.get(
                                              form.touched,
                                              `${idd}.mandatory`,
                                            )
                                            ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                                            : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                                          'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border disabled:bg-gray-100 sm:text-sm',
                                        )}
                                      >
                                        <option value={'yes'}>Yes</option>
                                        <option value={'no'}>No</option>
                                      </select>
                                    </td>
                                    <td className="flex h-[38px] w-[38px] items-center justify-center whitespace-nowrap p-0 text-right text-sm text-gray-500 dark:text-gray-400">
                                      {!isLast &&
                                        Boolean(
                                          _.get(form.values, `${id}.enabled`),
                                        ) && (
                                          <button
                                            type="button"
                                            onClick={wrapClick(() =>
                                              removeItem(
                                                `${id}.specifications`,
                                                iddx,
                                              ),
                                            )}
                                            className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                          >
                                            <XMarkIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          },
        )}
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default RequirementsInfoForm;
