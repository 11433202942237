import { FC } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import _ from 'lodash';
import { classNames } from 'utils';

interface RadioInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  options: {
    value: string;
    label: string;
    description?: string;
  }[];
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
}

const RadioInput: FC<RadioInputProps> = ({
  id,
  options,
  values,
  handleChange,
  label,
  errors,
  touched,
  required,
}) => {
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required ? <span className="text-red-500">*</span> : ''}
      </label>
      <div className="relative mt-1">
        <fieldset>
          <legend className="sr-only">{label}</legend>
          <div className="inline-flex h-[38px] items-center space-x-6">
            {options.map(option => (
              <div key={option.value} className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id={id}
                    aria-describedby={`${option.value}-description`}
                    name={id}
                    type="radio"
                    onChange={handleChange}
                    value={option.value}
                    checked={_.get(values, id) === option.value}
                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                  />
                </div>
                <div className="ml-3 space-x-3 text-sm">
                  <label
                    htmlFor={option.value}
                    className="font-medium text-gray-700"
                  >
                    {option.label}
                  </label>
                  {option.description && (
                    <span
                      id={`${option.value}-description`}
                      className="font-light text-gray-500"
                    >
                      {option.description}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default RadioInput;
