import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { MailingView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_MAILING = gql`
  query GetMailing($filter: MailingFilter!, $populate: [String]) {
    mailing: getMailing(filter: $filter, populate: $populate) {
      _id
      sentCount
      failedCount
      recipientType
      status
      message
      createdBy {
        _id
        name
      }
      subject
      total
      code
      createdAt
      cycle {
        _id
        name
      }
      recipientsApplicationCodes
    }
  }
`;

export default function ViewMailingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_MAILING, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['createdBy', 'cycle'],
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Mailing Information"
      description="Details of mailing are shown below"
      renderActions={() => <></>}
    >
      <MailingView mailing={data?.mailing} />
    </Modal>
  );
}
