import lodash from 'lodash';

const SingleShimmer = () => (
  <div className="flex h-10 w-full min-w-[120px]  flex-col justify-center space-y-2">
    <div className="h-3 w-full animate-pulse rounded bg-gray-400 dark:bg-gray-400" />
  </div>
);

const DoubleShimmer = () => (
  <div className="flex h-10 w-full min-w-[120px]  flex-col justify-center space-y-2">
    <div className="h-3 w-full animate-pulse rounded bg-gray-400 dark:bg-gray-400" />
    <div className="h-3 w-2/3 animate-pulse rounded bg-gray-300 dark:bg-gray-400" />
  </div>
);

const AvatarShimmer = () => (
  <div className="flex h-10 items-center space-x-3">
    <div className="h-10 w-10 animate-pulse rounded-full bg-gray-400 dark:bg-gray-400" />
    <div className="min-w-[120px] flex-1 space-y-2">
      <div className="h-3 w-full animate-pulse rounded bg-gray-400 dark:bg-gray-400" />
      <div className="h-3 w-2/3 animate-pulse rounded bg-gray-300 dark:bg-gray-400" />
    </div>
  </div>
);

const ActionsShimmer = ({ actionsCount }: { actionsCount: number }) => (
  <div className="flex h-10 items-center space-x-1">
    {lodash.times(actionsCount, idx => (
      <div
        key={idx}
        className="h-6 w-6 animate-pulse rounded-full odd:bg-gray-400 even:bg-gray-300 dark:bg-gray-400"
      />
    ))}
  </div>
);

const Shimmers = {
  SingleShimmer,
  DoubleShimmer,
  AvatarShimmer,
  ActionsShimmer,
};
export default Shimmers;
