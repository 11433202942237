import * as Yup from 'yup';

export const VendorSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is a required field'),
  name: Yup.string().required('Name is a required field'),
  phone: Yup.string()
    .required('Phone is a required field')
    .when({
      is: (value: string) => value && value[0] === '0',
      then: Yup.string()
        .length(10)
        .matches(/^\d+$/, 'Must be a numeric string with 10 digits'),
      otherwise: Yup.string()
        .length(12)
        .matches(/^\d+$/, 'Must be a numeric string with 12 digits'),
    }),
  description: Yup.string().required('Description is a required field'),
});

export const VendorInitialValues = {
  email: '',
  name: '',
  phone: '',
  description: '',
};
