import { gql, useLazyQuery } from '@apollo/client';
import { Avatar, TextInput } from 'components/core';
import { SchoolPicker, UsersPicker } from 'containers';
import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';

export interface DepartmentFormProps {
  form: FormikProps<{
    head: any;
    code: string;
    name: string;
    phone: string;
    digitalAddress: string;
    officeAddress: string;
    school: string;
    email: string;
  }>;
}

const GET_USER = gql`
  query GetUser($filter: UserFilter!) {
    getUser(filter: $filter) {
      _id
      profileImageUrl
      name
      staffId
    }
  }
`;

const DepartmentForm: FC<DepartmentFormProps> = ({ form }) => {
  const [getUser, { loading, data: user }] = useLazyQuery(GET_USER, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getUser({
      variables: {
        filter: {
          ...(form?.values?.head?._id
            ? { _id: { eq: form?.values?.head?._id } }
            : null),
        },
      },
    });
  }, [form]);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Department Information</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <SchoolPicker
              id="school"
              label="School"
              placeholder="eg. Accra East School"
              required={true}
              {...form}
              rawId={true}
            />
          </div>

          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="eg. 01-04"
              disabled={true}
              {...form}
            />
          </div>

          <div className="col-span-2 space-y-1">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="eg. Computer Science"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="phone"
              label="Phone"
              type="text"
              placeholder="eg. 0267658663"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="email"
              label="Email"
              type="text"
              placeholder="eg. dcs@gimpa.edu.gh"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="digitalAddress"
              label="Digital Address"
              type="text"
              placeholder="eg. GH-182-0292"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="officeAddress"
              label="Office Address"
              type="text"
              placeholder="eg. 2nd Kudu Center Light, Block B"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2 pt-6">
            <span className="text-xs font-light">
              Head of Department Information
            </span>
            <div className="mt-2 grid grid-cols-4 gap-6">
              <div>
                <div className="col-span-2">
                  <Avatar
                    alt={
                      (form?.values?.head?._id && user?.getUser?.name) ||
                      'user_image'
                    }
                    src={
                      (form?.values?.head?._id &&
                        user?.getUser?.profileImageUrl) ||
                      ''
                    }
                    size="xl"
                  />
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {(form?.values?.head?._id && user?.getUser?.name) || 'N/A'}
                  </div>
                </div>
              </div>
              <div className="col-span-3">
                <UsersPicker
                  id="head._id"
                  label="Head"
                  placeholder="Select Department Head or search by name"
                  required={true}
                  rawId
                  {...form}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentForm;
