import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { AdmissionCycleView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_ADMISSION_CYCLE = gql`
  query GetAdmissionCycle($filter: AdmissionCycleFilter!, $populate: [String]) {
    admissionCycle: getAdmissionCycle(filter: $filter, populate: $populate) {
      _id
      code
      name
      description
      noticeToApplicants
      generalEntryRequirements
      openingDate
      closingDate
      academicCalendar {
        year
        openingDate
        orientationDate
        matriculationDate
      }
      createdBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        role
        meta {
          isSuspended
        }
      }
      lastUpdatedBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        role
        meta {
          isSuspended
        }
      }
      createdAt
      updatedAt
      schoolFeesDates {
        commitmentFeeDate
        outstandingFeesDate
        provisionalRegistrationDate
      }
    }
  }
`;

export default function ViewAdmissionCycleContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: old => ({
        ...old,
        modal: 'update',
        admissionCycle: searchParams.id,
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Admission Cycle Information"
      description="Details of admission cycle are shown below"
      renderActions={() => (
        <>
          {withPermissions([
            '*:*',
            'admission-cycles:*',
            'admission-cycles:update',
          ])(
            <button
              type="button"
              className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>,
          )}
        </>
      )}
    >
      <AdmissionCycleView admissionCycle={data?.admissionCycle} />
    </Modal>
  );
}
