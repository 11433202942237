import { FC, useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionProgrammeForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import { currentConfigVar } from 'apollo/cache/config';
import { removeTypename } from 'utils/general';

const GET_ADMISSION_PROGRAMME = gql`
  query GetAdmissionProgrammeLean(
    $filter: AdmissionProgrammeFilter!
    $populate: [String]
  ) {
    admissionProgramme: getAdmissionProgramme(
      filter: $filter
      populate: $populate
    ) {
      _id
      code
      name
      school {
        _id
        code
        name
      }
      level
      programme {
        _id
        code
        name
      }
      department {
        _id
        code
        name
      }
      tracks
      offerings {
        campus {
          _id
          code
          name
        }
        types
      }
      requirements {
        type
        description
        enabled
        specifications {
          subject
          requiredGrade
          mandatory
        }
        defaultMinimumGrade
        numberOfPassedSubjects
      }
      duration
      requiresInterview
      maximumInterviewScore
      requiredInterviewScore
      interviewScoreWeight
      requiresExamination
      maximumExaminationScore
      requiredExaminationScore
      examinationScoreWeight
      admissionQuota
      hasAdmissionQuota
    }
  }
`;

const UPDATE_ADMISSION_PROGRAMME = gql`
  mutation UpdateAdmissionProgramme(
    $id: ID!
    $input: UpdateAdmissionProgrammeInput!
  ) {
    admissionProgramme: updateAdmissionProgramme(id: $id, input: $input) {
      _id
    }
  }
`;

interface UpdateAdmissionProgrammeContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateAdmissionProgrammeContainer: FC<
  UpdateAdmissionProgrammeContainerProps
> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['school', 'programme', 'offerings.campus'],
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
    fetchPolicy: 'no-cache',
  });
  const [updateAdmissionProgramme] = useMutation(UPDATE_ADMISSION_PROGRAMME);

  const form = useFormik({
    initialValues: {
      programmeInfo: {
        programme: null as any,
        school: null as any,
        department: null as any,
        tracks: [] as any[],
        offerings: [] as any[],
        requirementTypes: [] as any[],
        duration: null as any,
        hasAdmissionQuota: false,
        admissionQuota: 0,
      },
      requirementsInfo: {
        requirements: [] as any[],
      },
      examinationInfo: {
        requiresExamination: false,
        requiresInterview: false,
        maximumInterviewScore: 0,
        maximumExaminationScore: 0,
        requiredExaminationScore: 0,
        requiredInterviewScore: 0,
        examinationScoreWeight: 0,
        interviewScoreWeight: 0,
      },
    },
    // validationSchema: AdmissionProgrammeSchema,
    onSubmit: async values => {
      await updateAdmissionProgramme({
        variables: {
          id: searchParams.id,
          input: {
            ...values.examinationInfo,
            programme: lodash.get(values.programmeInfo.programme, '_id'),
            cycle: params.admissionCycle,
            tracks: values.programmeInfo.tracks,
            offerings: values.programmeInfo.offerings?.map(offering => ({
              campus: offering.campus,
              types: offering?.types,
            })),
            hasAdmissionQuota: values?.programmeInfo?.hasAdmissionQuota,
            admissionQuota: values?.programmeInfo?.admissionQuota,
            duration: values.programmeInfo?.duration,
            requirements: removeTypename(
              values.requirementsInfo?.requirements?.map(
                (requirement: any) => ({
                  ...requirement,
                  defaultMinimumGrade: parseInt(
                    `${requirement?.defaultMinimumGrade}`,
                  ),
                  numberOfPassedSubjects: parseInt(
                    `${requirement?.numberOfPassedSubjects}`,
                  ),
                  specifications: requirement?.specifications?.map(
                    (spec: any) => ({
                      ...spec,
                      mandatory: spec?.mandatory === 'yes' ? true : false,
                    }),
                  ),
                }),
              ),
            ),
          },
        },
      }).then(({ data }) => {
        if (data?.admissionProgramme?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Programme updated successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not update programme',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    const fetchedData = removeTypename(data?.admissionProgramme);

    form.setValues({
      programmeInfo: {
        school: fetchedData?.school?._id as any,
        department: fetchedData?.department?._id as any,
        programme: fetchedData?.programme || null,
        tracks: fetchedData?.tracks || [],
        offerings:
          fetchedData?.offerings?.map((offering: any) => ({
            ...offering,
            campus: offering?.campus?._id,
          })) || [],
        requirementTypes: lodash.map(fetchedData?.requirements, 'type') || [],
        duration: fetchedData?.duration,
        hasAdmissionQuota: fetchedData?.hasAdmissionQuota,
        admissionQuota: fetchedData?.admissionQuota,
      },
      requirementsInfo: {
        requirements:
          fetchedData?.requirements?.map((requirement: any) => ({
            ...requirement,
            defaultMinimumGrade: parseInt(
              `${requirement?.defaultMinimumGrade}`,
            ),
            numberOfPassedSubjects: parseInt(
              `${requirement?.numberOfPassedSubjects}`,
            ),
            specifications: requirement?.specifications?.map((spec: any) => ({
              ...spec,
              mandatory: spec?.mandatory ? 'yes' : 'no',
            })),
          })) || [],
      },
      examinationInfo: {
        requiresExamination: fetchedData?.requiresExamination || false,
        requiresInterview: fetchedData?.requiresInterview || false,
        maximumInterviewScore: fetchedData?.maximumInterviewScore || 0,
        maximumExaminationScore: fetchedData?.maximumExaminationScore || 0,
        requiredExaminationScore: fetchedData?.requiredExaminationScore || 0,
        requiredInterviewScore: fetchedData?.requiredInterviewScore || 0,
        examinationScoreWeight: fetchedData?.examinationScoreWeight || 0,
        interviewScoreWeight: fetchedData?.interviewScoreWeight || 0,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.admissionProgramme, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Programme Information"
      description="Provide the details to update programme"
      size="4xl"
      loading={loading}
      hideActions={true}
    >
      {data?.admissionProgramme?._id && (
        <AdmissionProgrammeForm form={form} isCompleted={true} />
      )}
    </Modal>
  );
};

export default UpdateAdmissionProgrammeContainer;
