import { useReactiveVar } from '@apollo/client';
import { FC } from 'react';
import { useSearch, MakeGenerics, Navigate, Outlet } from 'react-location';
import { currentTokenVar } from 'apollo/cache/auth';

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  };
}>;

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>();
  const currentToken = useReactiveVar(currentTokenVar);

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate to={search?.redirect ?? '/'} replace />;
  }
  return (
    <div className="h-screen">
      <div className="relative flex min-h-full items-center justify-center">
        <img
          className="absolute inset-0 z-0 hidden h-full w-full object-cover lg:block"
          src={require('assets/gimpa-stones.jpg')}
          alt=""
        />
        <div className="z-10 flex flex-col space-y-10">
          <div className="flex items-center justify-center space-x-2">
            <img
              className="h-12 w-12"
              src={require('assets/logo.png')}
              alt="Waya Money"
            />
            <h1 className="text-5xl font-extrabold tracking-wider text-white">
              GIMPA
            </h1>
          </div>
          <div className="flex-col justify-center rounded bg-white/80 p-6 backdrop-blur-xl sm:p-10 lg:flex-none">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
