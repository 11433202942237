import { TextArea, TextInput } from 'components/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import DocumentUpload from 'components/core/document-upload';

export type FeesType = {
  type?: string;
  fileUrl?: string;
};

interface TuitionFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    description: string;
    fees: any[];
  }>;
}

const TuitionForm: FC<TuitionFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Tuition Information</span>
        <div className="mb-2 mt-2 grid grid-cols-3 gap-6">
          <div>
            <TextInput
              id="code"
              label="Tuition Code"
              type="text"
              placeholder="e.g. 07"
              disabled={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Trimester Programmes"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextArea
              id="description"
              label="Description"
              placeholder="e.g. This is the fees structure for all..."
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3 grid h-full grid-cols-2 gap-5">
            <div className="">
              <div className="mt-2 grid grid-cols-2 gap-6">
                <div className="col-span-2 h-36 w-full">
                  <DocumentUpload
                    id="fees.0.fileUrl"
                    label="Local Supplementary Sheet"
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="mt-2 grid grid-cols-2 gap-6">
                <div className="col-span-2 h-36 w-full">
                  <DocumentUpload
                    id="fees.1.fileUrl"
                    label="International Supplementary Sheets"
                    {...form}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TuitionForm;
