import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { FC } from 'react';

interface MailingViewProps {
  mailing: {
    _id: string;
    sentCount: string;
    failedCount: string;
    recipientType: string;
    status: string;
    message: string;
    subject: string;
    total: string;
    code: string;
    createdAt: string;
    recipientsApplicationCodes: string[];
    cycle: {
      _id: string;
      name: string;
    };
    createdBy: {
      _id: string;
      name: string;
    };
  };
}

const MailingView: FC<MailingViewProps> = ({ mailing }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Basic Information</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Recipient Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.recipientType || 'N/A'}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Subject
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.subject || 'N/A'}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Message
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.message || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Statistical Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Total
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.total || 'N/A'}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Sent Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {`${mailing?.sentCount}` || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Failed Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {`${mailing?.failedCount}` || 'N/A'}
            </div>
          </div>
        </div>
      </div>

      {mailing?.recipientType === 'APPLICATIONS' && (
        <div className="pt-6">
          <span className="text-xs font-light">Applications Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="col-span-3">
              <span className="block text-sm font-light text-gray-700">
                Codes
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {mailing?.recipientsApplicationCodes?.join(', ') || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.createdAt
                ? dayjs(mailing?.createdAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {mailing?.createdBy?.name || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailingView;
