import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useState } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import { PieChart } from 'components';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { classNames } from 'utils';
import { MoonLoader } from 'react-spinners';
import { LocationGenerics } from 'router/location';
import { useMatch, useSearch } from 'react-location';
import { currentConfigVar } from 'apollo/cache/config';
import { ButtonGroup } from 'components/buttons/button-group';
dayjs.extend(weekOfYear);

interface ShortlistLevelChartContainerProps {
  status?: 'PENDING' | 'SUBMITTED';
  cycle?: string;
}

const LevelChart = ({ status, cycle }: { status?: string; cycle?: string }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && {
    cycle: { eq: cycle },
  };

  const GET_SHORTLIST_STATS = gql`
    query Query(
      $undergraduateFilter: ShortlistFilter
      $graduateFilter: ShortlistFilter
    ) {
      undergraduate: getShortlistCount(filter: $undergraduateFilter)
      graduate: getShortlistCount(filter: $graduateFilter)
    }
  `;

  const { loading, data } = useQuery(GET_SHORTLIST_STATS, {
    variables: {
      undergraduateFilter: {
        applicationLevel: {
          eq: 'UNDERGRADUATE',
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
      graduateFilter: {
        applicationLevel: {
          eq: 'GRADUATE',
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval,
  });
  const pieData = [
    {
      color: '#017CB8',
      bgColor: 'bg-blue-600',
      id: 'Undergraduates',
      label: 'Undergraduates',
      value: data?.undergraduate || 0,
    },
    {
      color: '#d352e9',
      bgColor: 'bg-blue-400',
      id: 'Graduates',
      label: 'Graduates',
      value: data?.graduate || 0,
    },
  ];

  return (
    <>
      <PieChart data={pieData} />
      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="flex space-x-4 rounded-lg bg-white p-2"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  'flex items-center justify-center rounded-md p-2.5',
                )}
              >
                <span className="text-lg text-white" aria-hidden="true"></span>
              </div>
            </div>
            <div className="flex h-full flex-1 flex-col justify-between space-y-2 text-left">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format('0,0')}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const TypeChart = ({ status, cycle }: { status?: string; cycle?: string }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const GET_SHORTLIST_STATS = gql`
    query Query(
      $localFilter: ShortlistFilter
      $internationalFilter: ShortlistFilter
    ) {
      local: getShortlistCount(filter: $localFilter)
      international: getShortlistCount(filter: $internationalFilter)
    }
  `;

  const cycleFilters = cycle && {
    cycle: { eq: cycle },
  };

  const { loading, data } = useQuery(GET_SHORTLIST_STATS, {
    variables: {
      localFilter: {
        applicationType: {
          eq: 'LOCAL',
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
      internationalFilter: {
        applicationType: {
          eq: 'INTERNATIONAL',
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval,
  });

  const pieData = [
    {
      color: '#89BD2E',
      bgColor: 'bg-blue-600',
      id: 'local',
      label: 'Local',
      value: data?.local || 0,
    },
    {
      color: '#F6C609',
      bgColor: 'bg-blue-400',
      id: 'International',
      label: 'International',
      value: data?.international || 0,
    },
  ];

  return (
    <>
      <PieChart data={pieData} />
      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="flex space-x-4 rounded-lg bg-white p-2"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  'flex items-center justify-center rounded-md p-2.5',
                )}
              >
                <span className="text-lg text-white" aria-hidden="true"></span>
              </div>
            </div>
            <div className="flex h-full flex-1 flex-col justify-between space-y-2 text-left">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format('0,0')}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ShortlistedLevelChartContainer: FC<ShortlistLevelChartContainerProps> = ({
  status,
  cycle,
}) => {
  const [chartType, setChartType] = useState('LEVEL');

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Shortlisted Groups
          </h3>
          <p className="text-xs text-gray-400">
            Displays shortlisted demography
          </p>
        </div>

        <div className="flex flex-col">
          <ButtonGroup
            buttons={[
              { label: 'Level', value: 'LEVEL' },
              { label: 'Type', value: 'TYPE' },
            ]}
            onChangeValue={value => setChartType(value || '')}
            selectedValue={chartType}
          />
        </div>
      </div>
      {chartType === 'LEVEL' ? (
        <LevelChart status={status} cycle={cycle} />
      ) : (
        <TypeChart status={status} cycle={cycle} />
      )}
    </>
  );
};

export default ShortlistedLevelChartContainer;
