import { ResponsivePie } from '@nivo/pie';
import { FC } from 'react';

interface SemiPieChartProps {
  config?: {
    innerRadius?: number;
  };
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
    bgColor: string;
  }[];
}

const SemiPieChart: FC<SemiPieChartProps> = ({ data, config }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    innerRadius={config?.innerRadius}
    padAngle={0.7}
    cornerRadius={5}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    startAngle={-90}
    endAngle={90}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.2]],
    }}
    colors={{ datum: 'data.color' }}
    enableArcLinkLabels={false}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={'white'}
  />
);

export default SemiPieChart;
