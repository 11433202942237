import { gql, useQuery } from '@apollo/client';
import { FC, useEffect, useMemo } from 'react';
import { useMatch, useNavigate, useSearch } from 'react-location';
import CreateAdmissionLetterContainer from './create';
import UpdateAdmissionLetterContainer from './update';
import ViewAdmissionLetterContainer from './view';
import { Avatar } from 'components/core';
import lodash from 'lodash';
import dayjs from 'dayjs';
import { ActionButton, Shimmers, TableComponent } from 'components';
import { Action } from 'components/buttons/action-button';
import { LocationGenerics } from 'router/location';
import { classNames, useUrlState, withPermissions, wrapClick } from 'utils';
import config from 'config';
import { ExportDataContainer } from 'containers';
import { NewspaperIcon } from '@heroicons/react/24/outline';

export const GET_ADMISSION_LETTERS = gql`
  query GetAdmissionLetters(
    $filter: AdmissionLetterFilter
    $pagination: Pagination
    $search: SearchOperator
    $sort: AdmissionLetterSort
    $populate: [String]
  ) {
    items: getAdmissionLetters(
      filter: $filter
      pagination: $pagination
      search: $search
      sort: $sort
      populate: $populate
    ) {
      _id
      code
      name
      createdAt
      updatedAt
      types
      pointers
      createdBy {
        _id
        name
        profileImageUrl
      }
    }
    itemsCount: getAdmissionLettersCount(filter: $filter, search: $search)
  }
`;

const AdmissionLettersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action as any,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [school, setSchool] = useUrlState('school');
  const [level, setLevel] = useUrlState<string>('admissionletter');
  const [__, setSearch] = useUrlState('search');

  const variables = useMemo(
    () => ({
      pagination: {
        limit: Number(searchParams.pageSize || 12),
        skip: Number(
          (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
        ),
      },
      ...((searchParams?.search || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search,
              fields: ['code', 'name'],
              options: ['CaseInsensitive'],
            },
          }
        : {}),
      filter: {
        admissionCycle: {
          eq: params.admissionCycle,
        },
        ...(level && { level: { eq: level } }),
        ...(school && { school: { eq: school } }),
      },
      sort: {},
      populate: ['createdBy'],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_ADMISSION_LETTERS,
    {
      variables,
      //pollInterval,
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    setSearch('');
  }, []);

  return (
    <main className="flex h-screen flex-1 flex-col overflow-hidden bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
          <TableComponent
            title={'Admission Letters'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, 'items', []),
              total: lodash.get(data, 'itemsCount', 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, 'itemsCount', 0) /
                  (searchParams?.pageSize || config.constants.pageSize),
              ),
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="User"
                variables={variables}
              />
            )}
            defaultView="grid"
            renderHeaderItems={() => (
              <div className="mt-4 space-x-2 sm:ml-16 sm:mt-0 sm:flex"></div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Admission Letter
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Types
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Pointers
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Created By
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Last Updated At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                {!level && (
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderGridLoader={() => (
              <>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex px-4 pb-8 pt-4">
                    <div className="h-12 w-12 rounded bg-gray-400" />
                    <dd className="ml-4 flex flex-1 items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex px-4 pb-8 pt-4">
                    <div className="h-12 w-12 rounded bg-gray-400" />
                    <dd className="ml-4 flex flex-1 items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex px-4 pb-8 pt-4">
                    <div className="h-12 w-12 rounded bg-gray-400" />
                    <dd className="ml-4 flex flex-1 items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
              </>
            )}
            renderGridItem={item => (
              <div
                key={item?._id}
                className="relative overflow-hidden rounded bg-white shadow-sm ring-1 ring-gray-900/5"
              >
                <div className="flex flex-row p-3 sm:p-4">
                  <div className="mr-3 rounded-lg bg-primary-600 p-3">
                    <NewspaperIcon className="h-5 w-5 text-white" />
                  </div>
                  <div className="flex flex-col">
                    <p className="line-clamp-2 font-semibold text-gray-900">
                      {item?.name}
                    </p>
                    <span className="text-sm">
                      {item?.pointers?.length || 0} Pointers
                    </span>
                    <div className="flex items-center space-x-4 text-xs text-gray-600">
                      <div className="line-clamp-3 flex items-center space-x-1">
                        {item?.description}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between bg-gray-50 px-3 py-1.5 sm:px-4">
                  <div className="flex items-center space-x-4 text-xs text-gray-600">
                    <div className="flex items-center space-x-1"></div>
                  </div>

                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />
                    {withPermissions([
                      '*:*',
                      'admission-cycles:*',
                      'admission-cycles:update',
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, 'update')}
                      />,
                    )}
                  </div>
                </div>
              </div>
            )}
            renderItem={item => (
              <tr
                key={item._id}
                className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                onClick={wrapClick(dispatchAction(item._id, 'view'))}
              >
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.name || 'N/A'}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.code || 'N/A'}
                  </div>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <span
                    className={classNames(
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs  text-gray-500 dark:bg-gray-600 dark:text-gray-100',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.types?.join(', ') || 'Unknown'}</span>
                  </span>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.pointers.length || 0} Pointers
                  </div>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm">
                  <div className="flex items-center">
                    {item?.createdBy ? (
                      <>
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            alt={item?.createdBy?.name || 'N A'}
                            src={item?.createdBy?.profileImageUrl || ''}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-600 dark:text-gray-100">
                            {item?.createdBy?.name || 'N/A'}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-left text-gray-600 dark:text-gray-100">
                        Unavailable
                      </div>
                    )}
                  </div>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-600 dark:text-gray-100">
                    {item?.updatedAt
                      ? dayjs(item?.updatedAt).format('DD/MM/YYYY hh:mm A')
                      : 'None'}
                  </div>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />
                    {withPermissions([
                      '*:*',
                      'admissions:*',
                      'admissions:manage',
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, 'update')}
                      />,
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(['*:*', 'admissions:*', 'admissions:manage'])(
        <CreateAdmissionLetterContainer
          open={modal === 'create'}
          setOpen={(val: boolean) => setModal(val ? 'create' : undefined)}
          refetch={refetch}
        />,
      )}
      {!!searchParams.id?.length && (
        <>
          <UpdateAdmissionLetterContainer
            open={modal === 'update'}
            setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
            refetch={refetch}
          />
          <ViewAdmissionLetterContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />
        </>
      )}
    </main>
  );
};

export { AdmissionLettersPage };
