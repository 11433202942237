import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MailingForm } from 'components';
import { MailingSchema } from 'components/forms/mailing/schema';

const CREATE_MAILING = gql`
  mutation CreateMailing($input: CreateMailingInput!) {
    createMailing(input: $input) {
      _id
    }
  }
`;

export default function CreateMailingContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMailing, { loading }] = useMutation(CREATE_MAILING);

  const form = useFormik({
    initialValues: {
      subject: '',
      cycle: '',
      message: '',
      recipientType: '',
      recipientsApplicationCodes: [] as any,
    },
    validationSchema: MailingSchema,
    onSubmit: async ({ cycle, ...values }) => {
      await createMailing({
        variables: {
          input: {
            ...(cycle && { cycle: cycle }),
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data.createMailing._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Mailing created successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Mailing',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Mailing"
      description="Provide the details to add a new mailing"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? 'Adding Mailing...' : 'Add Mailing'}
          </button>
        </>
      )}
    >
      <MailingForm form={form} />
    </Modal>
  );
}
