import { FC, useState } from 'react';
import { classNames } from 'utils';
import _ from 'lodash';
import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextAreaProps {
  id: string;
  label: string;
  rows?: number;
  placeholder?: string;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  required?: boolean;
  disabled?: boolean;
}

const RichTextArea: FC<RichTextAreaProps> = ({
  id,
  rows,
  values,
  handleChange,
  handleBlur,
  placeholder,
  label,
  errors,
  touched,
  required,
  disabled,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required ? <span className="text-red-500">*</span> : ''}
      </label>
      <div className="mt-1">
        <Quill
          id={id}
          value={_.get(values, id)}
          onChange={handleChange(id)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={placeholder ?? ''}
          className={classNames(
            'block w-full rounded !border-gray-100 shadow-sm',
            disabled ? 'bg-gray-100' : '',
          )}
          readOnly={disabled}
          theme="snow"
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { color: [] },
                { background: [] },
              ],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: [] },
              ],
              ['link'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'background',
            'color',
            'align',
          ]}
          style={{
            borderColor:
              _.get(errors, id) && _.get(touched, id)
                ? focused
                  ? 'rgb(239, 68, 68)'
                  : 'rgb(239, 38, 38)'
                : focused
                  ? '#017CB8'
                  : 'rgb(209, 213, 219)',
            borderWidth: '1px',
            boxShadow:
              _.get(errors, id) && _.get(touched, id)
                ? focused
                  ? '0 0 0 0 #000, 0 0 0 1px rgb(239, 68, 68)'
                  : '0 0 0 0 #000'
                : focused
                  ? '0 0 0 0 #000, 0 0 0 1px #017CB8'
                  : '0 0 0 0 #000',
          }}
        />
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {errors[id]}
        </p>
      ) : null}
    </>
  );
};

export default RichTextArea;
