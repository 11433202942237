import { FC } from 'react';
import { ICurrentConfig } from 'apollo/cache/config';
import _ from 'lodash';
import lodash from 'lodash';

const ProgrammeInfoSummary: FC<{
  data: any;
  values: any;
  config: ICurrentConfig;
}> = ({ data, values, config: { dateFormat } }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200 rounded border border-dashed border-gray-300 p-3 dark:border-gray-600">
      <div className="">
        <span className="text-xs font-light">Programmes Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Preferred Offering Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(data?.preferredOfferingType) || 'N/A'}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Preferred Campus
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.preferredCampus?.name || 'N/A'}
            </div>
          </div>
          {data?.programmeSelections?.map((_: any, idx: any) => {
            return (
              <div className="col-span-4">
                <span className="block text-sm font-light text-gray-700">
                  Programme Option {idx + 1}
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.programmeSelections?.[idx]?.programme?.name || 'N/A'} -{' '}
                  {data?.programmeSelections?.[idx]?.school?.name || 'N/A'}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeInfoSummary;
