import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import numeral from 'numeral';
import { FC } from 'react';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames } from 'utils';
import { currentUserVar } from 'apollo/cache/auth';

const statsMapGeneral = [
  { name: 'Schools', accessor: 'schools' },
  { name: 'Departments', accessor: 'departments' },
  { name: 'Programmes', accessor: 'programmes' },
  { name: 'Users', accessor: 'users' },
  { name: 'Vendors', accessor: 'vendors' },
  { name: 'Applicants', accessor: 'applicants' },
];

const statsMapSchoolAdmin = [
  { name: 'Department', accessor: 'departments' },
  { name: 'Programmes', accessor: 'programmes' },
  { name: 'Approved', accessor: 'approved' },
  { name: 'Declined', accessor: 'declined' },
  { name: 'Admitted', accessor: 'admitted' },
  { name: 'Accepted', accessor: 'accepted' },
];

const statsMapSchoolDean = [
  { name: 'Admitted', accessor: 'admitted' },
  { name: 'Shortlisted', accessor: 'shortlisted' },
  { name: 'Accepted', accessor: 'admitted' },
];

const statsMapCycle = [
  { name: 'Programmes', accessor: 'admissionProgrammes' },
  { name: 'Applicants', accessor: 'applicants' },
  { name: 'Paid Invoices', accessor: 'paidInvoices' },
  { name: 'Shortlisted', accessor: 'shortlisted' },
  { name: 'Accepted', accessor: 'admitted' },
  { name: 'Vendors', accessor: 'vendors' },
];

const statsMapInvoice = [
  { name: 'Applicants', accessor: 'applicants' },
  { name: 'Paid Invoices', accessor: 'paidInvoices' },
  { name: 'Vendors', accessor: 'vendors' },
];

const OVERVIEW_SUMMARY_GENERAL = gql`
  query Query(
    $invoiceFilter: InvoiceFilter
    $shortlistApprovedFilter: ShortlistFilter
    $shortlistDeclinedFilter: ShortlistFilter
    $admissionsFilter: AdmissionFilter
  ) {
    schools: getSchoolsCount
    departments: getDepartmentsCount
    programmes: getProgrammesCount
    users: getUsersCount
    vendors: getVendorsCount
    applicants: getApplicationsCount
    paidInvoices: getInvoicesCount(filter: $invoiceFilter)
    approved: getShortlistCount(filter: $shortlistApprovedFilter)
    declined: getShortlistCount(filter: $shortlistDeclinedFilter)
    admitted: getAdmissionsCount(filter: $admissionsFilter)
  }
`;

const OVERVIEW_SUMMARY_SCHOOL_ADMIN = gql`
  query Query(
    $shortlistApprovedFilter: ShortlistFilter
    $shortlistDeclinedFilter: ShortlistFilter
    $admissionsFilter: AdmissionFilter
    $acceptedFilter: AdmissionFilter
  ) {
    departments: getDepartmentsCount
    programmes: getProgrammesCount
    approved: getShortlistCount(filter: $shortlistApprovedFilter)
    declined: getShortlistCount(filter: $shortlistDeclinedFilter)
    admitted: getAdmissionsCount(filter: $admissionsFilter)
    accepted: getAdmissionsCount(filter: $acceptedFilter)
  }
`;

const OVERVIEW_SUMMARY_CYCLE = gql`
  query Query(
    $admissionProgrammeFilter: AdmissionProgrammeFilter
    $applicationsFilter: ApplicationFilter
    $invoiceFilter: InvoiceFilter
    $shortlistFilter: ShortlistFilter
    $shortlistApprovedFilter: ShortlistFilter
    $shortlistDeclinedFilter: ShortlistFilter
    $admissionsFilter: AdmissionFilter
    $acceptedFilter: AdmissionFilter
  ) {
    admissionProgrammes: getAdmissionProgrammesCount(
      filter: $admissionProgrammeFilter
    )
    applicants: getApplicationsCount(filter: $applicationsFilter)
    paidInvoices: getInvoicesCount(filter: $invoiceFilter)
    shortlisted: getShortlistCount(filter: $shortlistFilter)
    approved: getShortlistCount(filter: $shortlistApprovedFilter)
    declined: getShortlistCount(filter: $shortlistDeclinedFilter)
    admitted: getAdmissionsCount(filter: $admissionsFilter)
    accepted: getAdmissionsCount(filter: $acceptedFilter)
    vendors: getVendorsCount
  }
`;

const getStatisticsStructure = (role: string, cycle?: string) => {
  if (cycle) {
    return statsMapCycle;
  } else {
    switch (role) {
      case 'ADMISSIONS_OFFICER':
        return statsMapGeneral;
      case 'SCHOOL_ADMINISTRATOR':
        return statsMapSchoolAdmin;
      case 'DEAN':
        return statsMapSchoolDean;
      case 'FINANCE_OFFICER':
        return statsMapGeneral;
      default:
        return statsMapGeneral;
    }
  }
};

const getGQLQuery = (role: string, cycle?: string) => {
  if (cycle) {
    return OVERVIEW_SUMMARY_CYCLE;
  } else {
    switch (role) {
      case 'ADMISSIONS_OFFICER':
        return OVERVIEW_SUMMARY_GENERAL;
      case 'SCHOOL_ADMINISTRATOR':
        return OVERVIEW_SUMMARY_SCHOOL_ADMIN;
      case 'DEAN':
        return OVERVIEW_SUMMARY_GENERAL;
      case 'FINANCE_OFFICER':
        return OVERVIEW_SUMMARY_GENERAL;
      default:
        return OVERVIEW_SUMMARY_GENERAL;
    }
  }
};

const OverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();

  const currentUser = useReactiveVar(currentUserVar);

  const statsMap = getStatisticsStructure(
    currentUser?.role,
    searchParams?.cycle,
  );
  const query = getGQLQuery(currentUser?.role, searchParams?.cycle);

  const cycleFilter = searchParams?.cycle && {
    cycle: {
      eq: searchParams?.cycle,
    },
  };

  const filters = {
    invoiceFilter: {
      ...cycleFilter,
      status: {
        eq: 'PAID',
      },
    },
    admissionProgrammeFilter: {
      ...cycleFilter,
    },
    applicationsFilter: {
      ...cycleFilter,
    },
    admissionsFilter: {
      ...cycleFilter,
    },
    shortlistFilter: {
      ...cycleFilter,
    },
    acceptedFilter: {
      enrolled: {
        eq: true,
      },
    },
    shortlistApprovedFilter: {
      ...cycleFilter,
      status: {
        eq: 'APPROVED',
      },
    },
    shortlistDeclinedFilter: {
      ...cycleFilter,
      status: {
        eq: 'DECLINED',
      },
    },
  };

  const { data } = useQuery(query, {
    variables: {
      ...filters,
    },
    pollInterval,
  });

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        System Overview
      </h3>
      <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded bg-white shadow md:grid-cols-3">
        {statsMap.map((item, index) => (
          <div
            key={item.name}
            className={classNames(
              index !== 0 && index !== 3 ? 'border-l border-gray-200' : '',
              index >= 3 ? 'border-t border-gray-200' : '',
              'px-4 py-5 sm:p-6',
            )}
          >
            {/* <div className="w-10 h-10 bg-gray-300">

          </div> */}
            <div>
              <dt className="text-sm font-normal text-gray-900">{item.name}</dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                  {numeral(data?.[item.accessor] || 0).format(
                    item.accessor === 'totalBilled' ? '0,0.00' : '0,0',
                  )}
                </div>
              </dd>
            </div>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default OverviewContainer;
