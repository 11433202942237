import { gql, useQuery } from '@apollo/client';
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface AdmissionLetterPickerContainerProps {
  filter?: {
    cycle?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_ADMISSION_LETTERS = gql`
  query GetAdmissionLetters($filter: AdmissionLetterFilter) {
    admissionletters: getAdmissionLetters(filter: $filter) {
      _id
      name
    }
  }
`;

const AdmissionLetterPickerContainer: FC<
  AdmissionLetterPickerContainerProps
> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_ADMISSION_LETTERS, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        ...(filter?.cycle && {
          admissionCycle: {
            eq: filter?.cycle,
          },
        }),
      },
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? 'admissionletter'}
      label={label ?? 'AdmissionLetter'}
      placeholder="Select AdmissionLetter"
      optionsLoading={loading}
      options={(data?.admissionletters ?? [])?.map((admissionletter: any) => ({
        label: {
          title: admissionletter.name as string,
        },
        value: rawId ? admissionletter._id : admissionletter,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default AdmissionLetterPickerContainer;
