import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Header, SelectInput, Shimmers, TableComponent } from 'components';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, withPermissions, classNames } from 'utils';
import config from 'config';
import { PlusIcon } from '@heroicons/react/24/outline';
import CreateMailingContainer from './create';
import ViewMailingContainer from './view';
import { currentConfigVar } from 'apollo/cache/config';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import dayjs from 'dayjs';
import AdmissionCyclePickerContainer from 'containers/admission-cycle-picker';

const GET_MAILING = gql`
  query GetMailings(
    $populate: [String]
    $filter: MailingFilter
    $pagination: Pagination
    $search: SearchOperator
  ) {
    items: getMailings(
      populate: $populate
      filter: $filter
      pagination: $pagination
      search: $search
    ) {
      _id
      code
      subject
      sentCount
      failedCount
      recipientType
      status
      total
      cycle {
        _id
        name
      }
      createdAt
      createdBy {
        name
        _id
      }
    }
    itemsCount: getMailingsCount(filter: $filter, search: $search)
  }
`;

const MailingPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [admissionCycle, setAdmissionCycle] = useUrlState('cycle');
  const [recipientType, setRecipientType] = useUrlState('recipientType');

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search,
              fields: ['subject', 'message', 'status'],
            },
          }
        : {}),
      filter: {
        ...(admissionCycle && { cycle: { eq: admissionCycle } }),
        ...(recipientType && { recipientType: { eq: recipientType } }),
      },
      sort: {},
      populate: ['cycle', 'createdBy'],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_MAILING, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex h-screen flex-1 flex-col overflow-hidden bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(['*:*', 'campuses:*', 'campuses:create'])(
              <button
                type="button"
                onClick={wrapClick(() => setModal('create'))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New Mailing
              </button>,
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
          <TableComponent
            title={'mailing'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, 'items', []),
              total: lodash.get(data, 'itemsCount', 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, 'itemsCount', 0) /
                  (searchParams?.pageSize || config.constants.pageSize),
              ),
            }}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 space-x-2 sm:ml-16 sm:mt-0 sm:flex">
                <SelectInput
                  id="recipientType"
                  labelHidden={true}
                  label="Recipient Type"
                  options={['ALL', 'SUBMITTED', 'PAID']}
                  values={{ recipientType: recipientType }}
                  placeholder="Select a type"
                  handleChange={(e: any) => {
                    setRecipientType(e.target.value);
                  }}
                  handleBlur={() => null}
                />
                <AdmissionCyclePickerContainer
                  id="admissionCycle"
                  labelHidden={true}
                  rawId={true}
                  setFieldValue={(field: any, value: string) =>
                    setAdmissionCycle(value)
                  }
                  values={{ admissionCycle: admissionCycle }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Subject | Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Recipients
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900  dark:bg-gray-800 dark:text-gray-100"
                >
                  Cycle
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Sent | Failed
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={item => (
              <tr
                key={item._id}
                className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                onClick={wrapClick(dispatchAction(item._id, 'view'))}
              >
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {`${item?.subject.length > 35 ? `${item?.subject}`.slice(0, 35) : item?.subject} ${item?.subject.length > 35 ? '...' : ''}` ||
                      'N/A'}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {item?.code || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <span
                    className={classNames(
                      !item?.recipientType
                        ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                        : '',
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.recipientType || 'Unknown'}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.cycle?.name || 'None'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="font-medium text-gray-900 dark:text-gray-100">{`Sent: ${item?.sentCount}`}</div>
                  <div className="font-medium text-gray-400 dark:text-gray-400">{`Failed: ${item?.failedCount}`}</div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <span
                    className={classNames(
                      item?.status === 'COMPLETE'
                        ? `bg-green-100 text-green-800 dark:bg-[#17f853] dark:text-gray-50`
                        : '',
                      item?.status === 'INPROGRESS'
                        ? `bg-yellow-100 text-yellow-800 dark:bg-[#dac75d] dark:text-gray-50`
                        : '',
                      item?.status === 'PENDING'
                        ? `bg-gray-100 text-gray-800 dark:bg-[#b6b461] dark:text-gray-50`
                        : '',
                      !item?.status
                        ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                        : '',
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || 'Unknown'}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="text-gray-900 dark:text-gray-100">
                    {dayjs(item?.createdAt).format(dateFormat + ' - HH:mm a') ||
                      'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(['*:*', 'mailing:*', 'mailing:create'])(
        <CreateMailingContainer
          open={modal === 'create'}
          setOpen={(val: boolean) => setModal(val ? 'create' : undefined)}
          refetch={refetch}
        />,
      )}

      {!!searchParams.id?.length && (
        <>
          <ViewMailingContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MailingPage;
