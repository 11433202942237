import { FC, useMemo, useRef } from 'react';
import { useUrlState, wrapClick } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from '@heroicons/react/20/solid';
import { ApplicationDetailsView } from 'components';

interface ApplicationDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const formSteps = [
  {
    name: 'Personal Info',
    description: 'Details',
    accessor: 'personalInformation',
    SummaryComponent: ApplicationDetailsView.Personal,
  },
];

const EducationDetailsContainer: FC<ApplicationDetailsContainerProps> = ({
  values,
  code,
}) => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const [page, setPage] = useUrlState('page');
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: 'w-[1000px]',
  });

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div ref={printRef} className="flex flex-1 flex-col overflow-hidden">
        <div className="no-scrollbar flex-1 space-y-6 divide-y divide-primary-500/50 overflow-y-auto p-6 print:p-0">
          <ApplicationDetailsView.Education data={values} />
        </div>
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="button"
          onClick={wrapClick(() => setPage(page + 1))}
          className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          onClick={wrapClick(() => setPage(page - 1))}
          className="inline-flex w-full justify-center rounded border border-gray-200 bg-white px-4 py-2 text-base font-medium text-gray-600 shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default EducationDetailsContainer;
