import { FC } from 'react';
import { Header } from 'components';
import SettingsSecurityContainer from './security';
import SettingsPersonalContainer from './personal';
import SettingsPersonalizationContainer from './personalization';

const navigation: any[] = [];

const SettingsPage: FC = () => {
  return (
    <main className="flex h-screen flex-1 flex-col overflow-hidden bg-gray-50">
      <Header />
      {/* Primary column */}
      <div className="flex flex-1 flex-col overflow-hidden overflow-y-auto p-8">
        <div className="divide-y divide-gray-300">
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  This information will be displayed publicly so be careful what
                  you share.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <SettingsPersonalContainer />
            </div>
          </div>
          <div className="grid hidden max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Change password
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Update your password associated with your account.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <SettingsSecurityContainer />
            </div>
          </div>
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  User Personalization
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Please use the options provided to configure your experience
                  on the platform
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <SettingsPersonalizationContainer />
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-6 py-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6">
            {navigation?.map(item => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0">
            <p className="text-center text-sm leading-5 text-gray-500">
              &copy; {new Date().getFullYear()} - Powered by{' '}
              <a
                href="https://www.polymorphlabs.io"
                target={'_blank'}
                className="text-primary-700 hover:text-primary-900"
                rel="noreferrer"
              >
                Polymorph Labs
              </a>
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default SettingsPage;
