import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { DepartmentView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_DEPARTMENTS = gql`
  query GetDepartment($filter: DepartmentFilter!, $populate: [String]) {
    department: getDepartment(filter: $filter, populate: $populate) {
      _id
      code
      digitalAddress
      email
      name
      officeAddress
      phone
      school {
        name
      }
      createdAt
      updatedAt
      lastUpdatedBy {
        name
      }
      createdBy {
        name
      }
    }
  }
`;

export default function ViewDepartmentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_DEPARTMENTS, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['school', 'createdBy', 'lastUpdatedBy'],
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: old => ({
        ...old,
        modal: 'update',
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Department Information"
      description="Details of programme are shown below"
      renderActions={() => (
        <>
          {withPermissions(['*:*', 'departments:*', 'departments:update'])(
            <button
              type="button"
              className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>,
          )}
        </>
      )}
    >
      <DepartmentView department={data?.department} />
    </Modal>
  );
}
