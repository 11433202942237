import packageJson from './../../package.json';
import firebaseConfig from './firebase';

if (process.env.NODE_ENV === 'production') {
  const variables = [
    'REACT_APP_APOLLO_URI',
    // "REACT_APP_SENTRY_DSN"
  ];
  for (let variable of variables) {
    if (!process.env[variable]) {
      throw new Error(`Kindly Provide Variable ${variable} In Env`);
    }
  }
}

interface Config {
  env: 'production' | 'staging' | 'sandbox' | 'test' | 'development';
  name: string;
  rest: {
    uri: string;
  };
  asset: {
    uri: string;
  };
  apollo: {
    uri: string;
    name: string;
    version: string;
  };
  sentry: {
    dsn: string;
    env: 'production' | 'staging' | 'sandbox' | 'test';
  };
  cookies: {
    domain: string;
    secure: boolean;
    sameSite: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    vapidKey: string;
  };
  constants: {
    page: number;
    pageSize: number;
    dateFormat: string;
  };
}

const config: Config = {
  env: (process.env.NODE_ENV as any) ?? 'development',

  name: packageJson.name,
  asset: {
    uri: process.env.REACT_APP_ASSET_URI ?? 'http://127.0.0.1:4000/api/assets',
  },
  rest: {
    uri: process.env.REACT_APP_BASE_URI ?? 'http://127.0.0.1:4000/api',
  },
  apollo: {
    uri: process.env.REACT_APP_APOLLO_URI ?? 'http://127.0.0.1:4000/graphql',
    name: packageJson.name,
    version: packageJson.version,
  },
  sentry: {
    dsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://examplePublicKey@o0.ingest.sentry.io/0',
    env: process.env.REACT_APP_SENTRY_ENV as any,
  },
  cookies: {
    domain: process.env.REACT_APP_DOMAIN ?? 'localhost',
    secure: true,
    sameSite: 'strict',
  },
  firebase: firebaseConfig,
  constants: {
    page: 1,
    pageSize: 10,
    dateFormat: '',
  },
};

export default config;
