import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import numeral from 'numeral';
import { FC } from 'react';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames } from 'utils';
import { currentUserVar } from 'apollo/cache/auth';
import ApplicantsLineChartContainer from 'containers/applicants-line-chart';
import ApplicantsLevelChartContainer from 'containers/applications-pie-chart';
import ApplicantsBarChartContainer from 'containers/applicants-bar-chart';

const statsMapCycle = [
  { name: 'Applications', accessor: 'applications' },
  { name: 'Shortlisted', accessor: 'shortlisted' },
  { name: 'Admitted', accessor: 'admitted' },
  { name: 'Accepted', accessor: 'accepted' },
];

const OVERVIEW_SUMMARY_CYCLE = gql`
  query Query(
    $applicationsFilter: ApplicationFilter
    $shortlistFilter: ShortlistFilter
    $admissionsFilter: AdmissionFilter
    $acceptedFilter: AdmissionFilter
  ) {
    applications: getApplicationsCount(filter: $applicationsFilter)
    shortlisted: getShortlistCount(filter: $shortlistFilter)
    admitted: getAdmissionsCount(filter: $admissionsFilter)
    accepted: getAdmissionsCount(filter: $acceptedFilter)
  }
`;

const OverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();

  const cycle = searchParams?.cycle;

  const query = OVERVIEW_SUMMARY_CYCLE;

  const cycleFilters = {
    applicationsFilter: {
      ...(cycle && {
        cycle: {
          eq: searchParams?.cycle,
        },
      }),
    },
    admissionsFilter: {
      ...(cycle && {
        cycle: {
          eq: searchParams?.cycle,
        },
      }),
    },
    shortlistFilter: {
      ...(cycle && {
        cycle: {
          eq: searchParams?.cycle,
        },
      }),
    },
    acceptedFilter: {
      ...(cycle && {
        cycle: {
          eq: searchParams?.cycle,
        },
      }),
      enrolled: {
        eq: true,
      },
    },
  };

  const { data } = useQuery(query, {
    variables: {
      ...cycleFilters,
    },
    pollInterval,
  });

  const currentUser = useReactiveVar(currentUserVar);

  const statsMap = statsMapCycle;

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Report Overview
      </h3>
      <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded bg-white shadow md:grid-cols-4">
        {!searchParams?.cycle &&
          statsMap.map((item, index) => (
            <div
              key={item.name}
              className={classNames(
                index !== 0 && index !== 4 ? 'border-l border-gray-200' : '',
                index >= 4 ? 'border-t border-gray-200' : '',
                'px-4 py-5 sm:p-6',
              )}
            >
              <div>
                <dt className="text-sm font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                    {numeral(data?.[item.accessor] || 0).format(
                      item.accessor === 'totalBilled' ? '0,0.00' : '0,0',
                    )}
                  </div>
                </dd>
              </div>
            </div>
          ))}

        {searchParams?.cycle &&
          statsMapCycle.map((item, index) => (
            <div
              key={item.name}
              className={classNames(
                index !== 0 && index !== 3 ? 'border-l border-gray-200' : '',
                index >= 3 ? 'border-t border-gray-200' : '',
                'px-4 py-5 sm:p-6',
              )}
            >
              <div>
                <dt className="text-sm font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                    {numeral(data?.[item.accessor] || 0).format(
                      item.accessor === 'totalBilled' ? '0,0.00' : '0,0',
                    )}
                  </div>
                </dd>
              </div>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default OverviewContainer;
