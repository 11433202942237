import { AdmissionFormInputs } from 'apollo/graphql/queries';
import dayjs from 'dayjs';
import {
  convertMonthsToYearsAndMonths,
  generateReference,
  numberToWords,
} from 'utils/general';
import _ from 'lodash';
import parseHtml from 'html-react-parser';
import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { currentUserVar } from 'apollo/cache/auth';
import { currentConfigVar } from 'apollo/cache/config';

export const UndergraduateStudent = ({
  admissionData,
  paymentDates,
  applicationData,
  commitmentFee,
  credentials,
  startingYear,
}: AdmissionFormInputs) => {
  const isMoreThanAYear = (admissionData?.programme?.duration || 0) >= 12;

  return (
    <ol className="mt-2 list-decimal space-y-3 text-justify text-xs">
      <li className=" text-justify">
        I have the pleasure to inform you that the Ghana Institute of Management
        and Public Administration (GIMPA) has offered you admission to the{' '}
        <b>{admissionData?.programme?.school?.name}</b> to pursue a{' '}
        <b>
          {convertMonthsToYearsAndMonths(
            admissionData?.programme?.duration || 0,
          )}
        </b>{' '}
        programme of study{' '}
        <b>
          {isMoreThanAYear &&
            ` (starting from year ${numberToWords(startingYear || 1)})`}
        </b>
        {', '}
        <span className="font-bold capitalize">
          {' '}
          In-person {`${admissionData?.offeringType}`.toLocaleLowerCase()}{' '}
          Session
        </span>{' '}
        {'['}
        {
          admissionData?.application?.programmeInformation?.preferredCampus
            ?.name
        }
        {']'}, leading to the award of a <b>{admissionData?.programme?.name}</b>
        .
      </li>
      <li className=" text-justify">
        <b>
          Despite the fact that this offer is for{' '}
          {_.capitalize(
            admissionData?.application?.programmeInformation
              ?.preferredOfferingType,
          )}{' '}
          Session at the {'['}
          {
            admissionData?.application?.programmeInformation?.preferredCampus
              ?.name
          }
          {']'} based on the exigencies of the time, the school, may, with
          adequate notice to you, change the session and/or location or the mode
          of offering the programme.
        </b>
      </li>
      <li>
        Your Student Identification (ID) Number is{' '}
        <b>{credentials?.studentId}</b>. This is the number that you will use
        throughout your studies in the Institute. Your default PIN is{' '}
        <b>{credentials?.pin}</b>. You must change it immediately after the
        first use. Visit the Institutes website (www.gimpa.edu.gh) and click on
        &apos;<b>MIS Web</b>&apos; to access the student portal login page. You
        will be provided with a GIMPA e-mail account after your first
        registration
      </li>
      <li className="text-justify">
        The first semester will commence on{' '}
        <b>
          {dayjs(admissionData?.cycle?.academicCalendar?.openingDate).format(
            'dddd, MMMM Do, YYYY',
          )}
        </b>
        . An orientation will be held for all fresh students on{' '}
        <b>
          {dayjs(
            admissionData?.cycle?.academicCalendar?.orientationDate,
          ).format('dddd, MMMM Do, YYYY')}
        </b>
        . The venue will be communicated to you.{' '}
        <b>
          {' '}
          Please, keep your application email address and WhatsApp number
          active. Please note that attendance/participation in orientation
          programmes is compulsory.
        </b>
      </li>
      <li>
        Kindly note that you <b>cannot defer</b> your programme of study after
        the second week during the first year. If for some reason you should
        decide to defer your programme to the next academic year, your
        application for deferment should be sent to the Dean of the{' '}
        <b>
          {'['}
          {admissionData?.programme?.school?.name.trim()}
          {']'}
        </b>
        , through your Head of Department.{' '}
        <b>
          Your application will only be considered after you have registered
        </b>
        .
      </li>
      <li>
        Should you decide to withdraw from the programme before the third week
        of registration, GIMPA shall refund your fees to you less than{' '}
        <b>30% of the total amount payable</b>, to be retained as administrative
        charges. Beyond this date, <b>NO REFUND</b> shall be made.
      </li>
      <li>
        It is mandatory for all fresh students to be matriculated and to sign
        the Matricula. You will be expected to participate in this ceremony when
        you enroll. The date and form will be communicated to you.
      </li>
      <li>
        <b>
          {' '}
          Information on Tuition fee and other institutional charges as well as
          the breakdown of the fees can be found on the attached supplementary
          sheet. The tuition fee may be revised in subsequent years.
        </b>
      </li>
      <li>
        <b>
          In order that the offer of admission made to you is confirmed, you are
          required to pay a non-refundable commitment fee of &nbsp;
          {commitmentFee} on or before {paymentDates?.commitment} (this forms
          part of your first semester fees). You are to submit proof of payment
          together with the completed acceptance letter (attached), to your
          School/Faculty. The outstanding portion of the semester fee should be
          paid on or before {paymentDates?.outstanding}
        </b>
        .
      </li>
      <li>
        <b>
          {'['}Please note that graduate students can only register after paying
          hundred percent (100%) of their semester/session/trimester fees]{' '}
        </b>
        . Your ID number should be quoted on the pay-in-slip.{' '}
        <b>
          {' '}
          Undergraduate students can only register after paying fifty (50%) of
          their register semester/session/trimester fees{']'}.{' '}
        </b>
      </li>
      <li>
        <b>
          A qualified applicant who has been offered admission but fails to meet
          the deadline for the payment of the commitment fee shall be deemed not
          to have taken the offer.{' '}
        </b>
      </li>
      <li>
        <b>
          You will go through registration formalities each semester for the
          duration of your programme.
        </b>{' '}
        Registration is done online via the Institute’s website,
        www.gimpa.edu.gh. You will need your student ID number and PIN to gain
        access to the student’s registration portal. You will be able to
        register only if you have paid the required fee. Student Registration
        Guidelines are available on the Institute’s website.
      </li>
      <li>
        <b>
          Students who fail to register by the close of a registration period
          will not receive any grades even if they attend lectures and take
          examinations. Registration is on semester basis.
        </b>
      </li>
      <li>
        The Institute requires that you are declared medically fit by its Head
        of Medical Services. In this regard, you will be medically examined at
        the Institutional clinic soon after the beginning of the First Semester.
        Note that you will be expected to pay for the chest X-ray at the
        Institute’s Clinic
      </li>
      <li>
        Kindly note that GIMPA reserves the right to revoke this offer in the
        event that discrepancies are detected in the statements and
        documentations submitted as part of your application.
      </li>

      <li>
        You may contact the following telephone numbers for any assistance when
        in difficulty:
        <b>{` ${admissionData?.department?.phone}`}</b>
      </li>
      <li>
        Please indicate your acceptance of this offer by completing the
        electronic acceptance form from your application dashboard. Step by step
        guideline is provided on your dashboard
      </li>
      <li>
        Please, accept our congratulations on your admission to the Ghana
        Institute of Management and Public Administration.
      </li>
    </ol>
  );
};

export const GraduateStudent = ({
  admissionData,
  paymentDates,
  applicationData,
  commitmentFee,
  credentials,
  startingYear,
}: AdmissionFormInputs) => {
  const isMoreThanAYear = (admissionData?.programme?.duration || 0) >= 12;
  return (
    <ol className="mt-2 list-decimal space-y-3 text-justify text-xs">
      <li className=" text-justify">
        I have the pleasure to inform you that the Ghana Institute of Management
        and Public Administration (GIMPA) has offered you admission to the{' '}
        <b>{admissionData?.programme?.school?.name}</b> to pursue a{' '}
        <b>
          {convertMonthsToYearsAndMonths(
            admissionData?.programme?.duration || 0,
          )}
        </b>{' '}
        programme of study{' '}
        <b>
          {isMoreThanAYear &&
            ` (starting from year ${numberToWords(startingYear || 1)})`}
          {', '}
        </b>
        <span className="font-bold capitalize">
          {' '}
          In-person {`${admissionData?.offeringType}`.toLocaleLowerCase()}{' '}
          Session
        </span>{' '}
        {'['}
        {
          admissionData?.application?.programmeInformation?.preferredCampus
            ?.name
        }
        {']'}, leading to the award of a <b>{admissionData?.programme?.name}</b>
        .
      </li>
      <li className=" text-justify ">
        <b>
          Despite the fact that this offer is for{' '}
          {_.capitalize(
            admissionData?.application?.programmeInformation
              ?.preferredOfferingType,
          )}{' '}
          Session at the {'['}
          {
            admissionData?.application?.programmeInformation?.preferredCampus
              ?.name
          }
          {']'} based on the exigencies of the time, the school, may, with
          adequate notice to you, change the session and/or location or the mode
          of offering the programme.
        </b>
      </li>
      <li>
        Your Student Identification (ID) Number is{' '}
        <b>{credentials?.studentId}</b>. This is the number that you will use
        throughout your studies in the Institute. Your default PIN is{' '}
        <b>{credentials?.pin}</b>. You must change it immediately after the
        first use. Visit the Institutes website (www.gimpa.edu.gh) and click on
        &apos;<b>MIS Web</b>&apos; to access the student portal login page. You
        will be provided with a GIMPA e-mail account after your first
        registration
      </li>
      <li className="text-justify">
        The first semester will commence on{' '}
        <b>
          {dayjs(admissionData?.cycle?.academicCalendar?.openingDate).format(
            'dddd, MMMM Do, YYYY',
          )}
        </b>
        . An orientation will be held for all fresh students on{' '}
        <b>
          {dayjs(
            admissionData?.cycle?.academicCalendar?.orientationDate,
          ).format('dddd, MMMM Do, YYYY')}
        </b>
        . The venue will be communicated to you.{' '}
        <b>
          {' '}
          Please, keep your application email address and WhatsApp number
          active. Please note that attendance/participation in orientation
          programmes is compulsory.
        </b>
      </li>
      <li>
        Kindly note that you <b>cannot defer</b> your programme of study after
        the second week during the first year. If for some reason you should
        decide to defer your programme to the next academic year, your
        application for deferment should be sent to the Dean of the{' '}
        <b>
          {'['}
          {admissionData?.programme?.school?.name.trim()}
          {']'}
        </b>
        , through your Head of Department.{' '}
        <b>
          Your application will only be considered after you have registered
        </b>
        .
      </li>
      <li>
        Should you decide to withdraw from the programme before the third week
        of registration, GIMPA shall refund your fees to you less than{' '}
        <b>30% of the total amount payable</b>, to be retained as administrative
        charges. Beyond this date, <b>NO REFUND</b> shall be made.
      </li>
      <li>
        It is mandatory for all fresh students to be matriculated and to sign
        the Matricula. You will be expected to participate in this ceremony when
        you enroll. The date and form will be communicated to you.
      </li>
      <li>
        <b>
          {' '}
          Information on Tuition fee and other institutional charges as well as
          the breakdown of the fees can be found on the attached supplementary
          sheet. The tuition fee may be revised in subsequent years.
        </b>
      </li>
      <li>
        <b>
          In order that the offer of admission made to you is confirmed, you are
          required to pay a non-refundable commitment fee of &nbsp;
          {commitmentFee} on or before {paymentDates?.commitment} (this forms
          part of your first semester fees). You are to submit proof of payment
          together with the completed acceptance letter (attached), to your
          School/Faculty. The outstanding portion of the semester fee should be
          paid on or before {paymentDates?.outstanding}
        </b>
        .
      </li>
      <li>
        <b>
          {'['}Please note that graduate students can only register after paying
          hundred percent (100%) of their semester/session/trimester fees]{' '}
        </b>
        . Your ID number should be quoted on the pay-in-slip.{' '}
        <b>
          {' '}
          Undergraduate students can only register after paying fifty (50%) of
          their register semester/session/trimester fees{']'}.{' '}
        </b>
      </li>
      <li>
        <b>
          A qualified applicant who has been offered admission but fails to meet
          the deadline for the payment of the commitment fee shall be deemed not
          to have taken the offer.{' '}
        </b>
      </li>
      <li>
        <b>
          You will go through registration formalities each semester for the
          duration of your programme.
        </b>{' '}
        Registration is done online via the Institute’s website,
        www.gimpa.edu.gh. You will need your student ID number and PIN to gain
        access to the student’s registration portal. You will be able to
        register only if you have paid the required fee. Student Registration
        Guidelines are available on the Institute’s website.
      </li>
      <li>
        <b>
          Students who fail to register by the close of a registration period
          will not receive any grades even if they attend lectures and take
          examinations. Registration is on semester basis.
        </b>
      </li>
      <li>
        The Institute requires that you are declared medically fit by its Head
        of Medical Services. In this regard, you will be medically examined at
        the Institutional clinic soon after the beginning of the First Semester.
        Note that you will be expected to pay for the chest X-ray at the
        Institute’s Clinic
      </li>
      <li>
        Kindly note that GIMPA reserves the right to revoke this offer in the
        event that discrepancies are detected in the statements and
        documentations submitted as part of your application.
      </li>

      <li>
        You may contact the following telephone numbers for any assistance when
        in difficulty:
        <b>{` ${admissionData?.department?.phone}`}</b>
      </li>
      <li>
        Please indicate your acceptance of this offer by completing the
        electronic acceptance form from your application dashboard. Step by step
        guideline is provided on your dashboard
      </li>
      <li>
        Please, accept our congratulations on your admission to the Ghana
        Institute of Management and Public Administration.
      </li>
    </ol>
  );
};

interface AdmissionLetterTemplate {
  pointers?: string[];
  variables?: any;
}

export const AdmissionLetterPointerTemplate = ({
  pointers,
  variables,
}: AdmissionLetterTemplate) => {
  const processTemplateData = ({ value }: { value: string }) => {
    try {
      return _.template(value)(variables);
    } catch (error) {
      return value;
    }
  };

  return (
    <ol className="mt-2 list-decimal space-y-3 text-justify text-xs">
      {pointers?.map(pointer => {
        return <li>{parseHtml(processTemplateData({ value: pointer }))}</li>;
      })}
    </ol>
  );
};

interface AdmissionLetterTemplateProps {
  printRef: any;
  variables: any;
  pointers: any[];
}

export const AdmissionLetterTemplate: FC<AdmissionLetterTemplateProps> = ({
  variables,
  printRef,
  pointers,
}) => {
  return (
    <article
      style={{ fontFamily: `'Times New Roman', Times, serif` }}
      ref={printRef}
      id={'printAdmission'}
      className="times-font relative z-0 mb-0 mt-0 h-full space-y-10 divide-y divide-gray-200 rounded-lg border-gray-300 px-12 py-5 print:border-0"
    >
      <div className="grid grid-cols-6">
        <div className="col-span-6 mb-0">
          <h3 className="mb-0 text-center text-lg font-bold">
            GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION [GIMPA]
          </h3>
          <h3 className="text-center text-sm text-gray-500">
            MOTTO: EXCELLENCE IN LEADERSHIP, MANAGEMENT AND ADMINISTRATION
          </h3>
        </div>

        <div className="col-span-6 grid grid-cols-6">
          <div className="col-span-2 flex flex-col text-xs">
            <span className="mb-5">
              <b>Our Ref:</b>{' '}
              {generateReference(variables?.admitted?.code as string, {
                schoolName: variables?.admitted?.programme?.school
                  ?.name as string,
                admissionDate: variables?.admitted?.cycle?.academicCalendar
                  ?.openingDate as string,
                programmeName: variables?.admitted?.programme?.name as string,
              })}
            </span>
            <span>
              <b>Your Ref:</b>........................
            </span>
          </div>

          <div className="col-span-2 flex flex-row justify-center">
            <div
              style={{ width: '120px', height: '120px' }}
              className="group relative mb-2 flex flex-col justify-start self-start rounded-sm object-cover"
            >
              <img
                className="h-full w-full flex-shrink-0 rounded-md object-contain"
                src={require('assets/logo.png')}
                alt="organization image"
              />
            </div>
          </div>

          <div className="col-span-2 flex flex-col text-xs">
            <span className="self-end text-left font-semibold">
              {variables?.postalAddress}
            </span>
            <span className="mb-2 self-end text-left font-semibold">
              {variables?.address}
            </span>
            <span className="self-end text-left">
              {dayjs(new Date(variables?.admissionDate)).format(
                'MMMM DD, YYYY',
              )}
            </span>
          </div>
        </div>

        <div className="col-span-4 mb-1 flex flex-col text-xs">
          <span className="font-bold">{variables?.applicantName}</span>
          <span>{variables?.applicantPostalAddress}</span>
          <span className="mb-5">{variables?.applicantAddress}</span>
          <span className="font-bold">Dear {variables?.applicantName},</span>
        </div>

        <div className="relative col-span-2 flex flex-row justify-center">
          <div
            style={{ width: '100px', height: '100px' }}
            className="group absolute -top-10 mb-2 flex cursor-pointer  flex-col justify-start self-start rounded-none object-cover"
          >
            <img
              className="h-full w-full flex-shrink-0 rounded-none object-cover"
              src={variables?.applicantImage}
              alt="person"
            />
          </div>
        </div>

        <div className="col-span-6 border-b border-black text-center ">
          <span className="font-semibold">
            {variables?.admissionLetterTitle}
          </span>
        </div>

        <div className="col-span-6 pl-5 text-justify">
          <AdmissionLetterPointerTemplate
            pointers={pointers || []}
            variables={variables || {}}
          />
        </div>

        <br></br>

        <div className="col-span-6 mb-3 flex flex-col text-xs">
          <span className="mb-3">
            <i>We look forward to welcoming you to the Centre of Excellence.</i>
          </span>
          <span>Yours sincerely,</span>
        </div>

        <div className="col-span-3 flex flex-col text-xs">
          <span>
            <b>{variables?.signatureName}</b>
          </span>
          {variables?.signatureUrl && (
            <div className="relative h-14">
              <div
                style={{ zIndex: -1 }}
                className="absolute left-0 top-0  flex h-14 w-auto flex-col justify-center"
              >
                <img
                  src={variables?.signatureUrl}
                  className="h-full w-full object-contain"
                />
              </div>
            </div>
          )}
          <span>
            <b>{variables?.signaturePortfolio}</b>
          </span>
          <span className="italic">(For: Secretary of the Institute)</span>
          <span>
            Cc: <b>Dean, {variables?.school}</b>
          </span>
        </div>

        <div className="col-span-3">
          <div className="w-60 text-center text-xs">
            <p className="mb-3">
              GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION <br />{' '}
              DIRECTOR OF ACADEMIC AFFAIRS <br /> ADMITTED
            </p>
            <p>
              DATE:{' '}
              {dayjs(new Date(variables?.admissionDate)).format(
                'MMMM DD, YYYY',
              )}
            </p>
          </div>
        </div>

        <div className="col-span-6 mt-10 flex flex-row justify-center text-xs">
          <div className="flex flex-col">
            <span className="self-center">
              Tel: (233) 033 209 5432 (Ext: 1074), 030 290 8076 (Ext: 1074), 050
              162 0138
            </span>
            <span className="self-center">
              <b>E-mail:</b> admissions@gimpa.edu.gh <b>Website:</b>{' '}
              www.gimpa.edu.gh
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

interface AdmissionLetterViewProps {
  admissionletter: {
    _id: any;
    code: any;
    name: any;
    types: any[];
    title: any;
    signatureName: any;
    signaturePortfolio: any;
    signatureUrl: any;
    pointers: any[];
    address: any;
    postalAddress: any;
    createdBy: {
      _id: any;
      name: any;
    };
    lastUpdatedBy: {
      name: any;
      _id: any;
    };
    createdAt: any;
    updatedAt: any;
  };
  printRef?: any;
}

export const AdmissionLetterView: FC<AdmissionLetterViewProps> = ({
  admissionletter,
  printRef,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const variables = {
    organizationName:
      'GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION [GIMPA]',
    admissionLetterTitle: admissionletter?.title,
    signatureUrl: admissionletter?.signatureUrl,
    signatureName: admissionletter?.signatureName,
    signaturePortfolio: admissionletter?.signaturePortfolio,
    applicantImage:
      'https://onyx-studio-test.s3.us-west-2.amazonaws.com/1732768749662',
    applicantName: 'APPLICANT NAME',
    applicantPostalAddress: 'APPLICANT POSTAL ADDRESS',
    applicantAddress: 'APPLICANT ADDRESS',
    admissionDate: '2020-10-10',
    address: admissionletter?.address,
    postalAddress: admissionletter?.postalAddress,
  };

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <AdmissionLetterTemplate
          printRef={printRef}
          variables={variables}
          pointers={admissionletter?.pointers}
        />
      </div>

      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionletter?.createdAt
                ? dayjs(admissionletter?.createdAt).format(
                    dateFormat + ' hh:mm A',
                  )
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionletter?.createdBy?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionletter?.updatedAt
                ? dayjs(admissionletter?.updatedAt).format(
                    dateFormat + ' hh:mm A',
                  )
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionletter?.lastUpdatedBy?.name || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
