import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { AdmissionLetterView } from 'components/cards/admission-letter-view';

export const GET_ADMISSION_LETTER = gql`
  query GetAdmissionLetter(
    $filter: AdmissionLetterFilter!
    $populate: [String]
  ) {
    admissionletter: getAdmissionLetter(filter: $filter, populate: $populate) {
      _id
      code
      name
      types
      name
      title
      signatureName
      signaturePortfolio
      signatureUrl
      pointers
      address
      postalAddress
      createdBy {
        _id
        name
      }
      lastUpdatedBy {
        name
        _id
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewAdmissionLetterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_LETTER, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['createdBy', 'lastUpdatedBy'],
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: old => ({
        ...old,
        modal: 'update',
      }),
    });
  };

  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Admission Letter',
    bodyClass: 'w-full',
  });

  return (
    <Modal
      open={open}
      setOpen={() => setOpen(false)}
      loading={loading}
      size="5xl"
      title="Admission Letter Information"
      description="Details of admission letter are shown below"
      renderActions={() => (
        <>
          {withPermissions(['*:*', 'admissions:*', 'admissions:manage'])(
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>,
          )}
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => handlePrint())}
          >
            Print Preview
          </button>
        </>
      )}
    >
      <AdmissionLetterView
        admissionletter={data?.admissionletter}
        printRef={printRef}
      />
    </Modal>
  );
}
