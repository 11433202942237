import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { AdmissionTransferForm, Avatar, Modal } from 'components';
import { useSearch, useNavigate, useMatches } from 'react-location';
import { LocationGenerics } from 'router/location';
import AdmittedView from 'components/cards/admitted-applicant-view';
import { withPermissions, wrapClick } from 'utils';
import { Action } from 'components/buttons/action-button';
import { useFormik } from 'formik';
import {
  AdmissionTransferInitialValues,
  AdmissionTransferSchema,
} from 'components/forms/admission-transfer/schema';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useEffect } from 'react';
import AdmissionTransferSingleForm from 'components/forms/admission-transfer-single';

const GET_ADMISSION = gql`
  query GetAdmission($filter: AdmissionFilter!, $populate: [String]) {
    admission: getAdmission(filter: $filter, populate: $populate) {
      _id
      applicant {
        code
        email
        _id
      }
      code
      programme {
        name
        _id
        code
        department {
          code
          name
        }
        level
      }
      updatedAt
      createdAt
      application {
        code
        personalInformation {
          firstName
          lastName
          profileImageUrl
        }
        _id
      }
      shortlist {
        startingYear
        offeredProgramme {
          _id
          name
        }
        offeredProgrammeRemark
      }
      department {
        name
        _id
      }
      school {
        _id
        name
      }
      createdBy {
        _id
        name
      }
      indexNumber
    }
  }
`;

const TRANSFER_ADMISSION = gql`
  mutation TransferAdmissionsToDifferentCycle(
    $input: TransferAdmissionsInput!
  ) {
    transferAdmissionsToDifferentCycle(input: $input) {
      transferCount
    }
  }
`;

export default function TransferSingleAdmissionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const [transferSingleAdmission, { loading: loadingAdmissionTransfer }] =
    useMutation(TRANSFER_ADMISSION);

  const searchParams = useSearch<LocationGenerics>();

  const matches = useMatches<LocationGenerics>();
  const cycle = matches?.[0]?.params?.admissionCycle;
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: [
        'programme',
        'applicant',
        'programme.department',
        'application.personalInformation',
        'shortlist',
        'shortlist.offeredProgramme',
        'department',
        'school',
        'createdBy',
      ],
    },
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'no-cache',
  });

  // console.log({data})

  const navigateItem =
    (action: Exclude<Action, 'expand' | 'goto' | 'clone'>) => () => {
      navigate({
        search: old => ({
          ...old,
          modal: action,
        }),
      });
    };

  const form = useFormik({
    initialValues: AdmissionTransferInitialValues,
    validationSchema: AdmissionTransferSchema,
    onSubmit: async values => {
      await transferSingleAdmission({
        variables: {
          input: {
            ...values,
            applicationIds: [data?.admission?.application?._id],
          },
        },
      }).then(({ data }) => {
        if (data.transferAdmissionsToDifferentCycle.transferCount) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Transfer initialized successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Transfer',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      ...form.values,
      sourceAdmissionProgrammeId: data?.admission?.programme?._id,
      sourceCycleId: cycle || '',
    });
  }, [cycle, open, data?.admission]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Transfer Admission"
      description="Details of the admitted applicant are shown below"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={
              loading ||
              form.isSubmitting ||
              !_.isEmpty(form.errors) ||
              loadingAdmissionTransfer ||
              !!!form?.values?.destinationAdmissionProgrammeId
            }
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-75 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading || loadingAdmissionTransfer
              ? 'Starting Transfer...'
              : 'Start Transfer'}
          </button>
        </>
      )}
    >
      <div className="space-y-6 divide-y divide-gray-200">
        <div>
          <span className="text-xs font-light">Applicant Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="">
              <Avatar
                alt={
                  [
                    (
                      data?.admission?.application?.personalInformation
                        ?.lastName || ''
                    )?.trim(),
                  ]
                    .join(' ')
                    .trim() || 'N A'
                }
                src={
                  data?.admission?.application?.personalInformation
                    ?.profileImageUrl
                }
                size="xl"
              />
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.admission?.application?.code || 'N/A'}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  data?.admission?.application?.personalInformation?.lastName,
                  data?.admission?.application?.personalInformation?.firstName,
                ].join(' ') || 'N/A'}
              </div>
            </div>

            <div></div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Email
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.admission?.applicant?.email || 'N/A'}
              </div>
            </div>
            {data?.admission?.indexNumber && (
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Index Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.admission?.indexNumber || 'N/A'}
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <span className="text-xs font-light">Programme Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Programme
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.admission?.programme?.name || 'N/A'}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Department
              </span>
              <div className="mt-1 block w-full capitalize sm:text-sm">
                {data?.admission?.department?.name || 'N/A'}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                School
              </span>
              <div className="mt-1 block w-full capitalize sm:text-sm">
                {data?.admission?.school?.name || 'N/A'}
              </div>
            </div>
          </div>
        </div>

        <div>
          <span className="text-xs font-light">
            Transfer Details Information
          </span>
          <AdmissionTransferSingleForm form={form} />
        </div>
      </div>
    </Modal>
  );
}
