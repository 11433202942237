import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, SchoolForm } from 'components';
import { SchoolSchema } from 'components/forms/school/schema';

const CREATE_SCHOOL = gql`
  mutation CreateSchool($input: SchoolInput!) {
    createSchool(input: $input) {
      _id
    }
  }
`;

const GET_DEANS = gql`
  query GetUsers($filter: UserFilter) {
    getUsers(filter: $filter) {
      _id
      code
      email
      name
    }
  }
`;

export default function CreateSchoolContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createSchool, { loading }] = useMutation(CREATE_SCHOOL);
  const { data: deans } = useQuery(GET_DEANS, {
    variables: {
      filter: {
        role: {
          eq: 'DEAN',
        },
      },
    },
  });

  const form = useFormik({
    initialValues: {
      dean: null as any,
      digitalAddress: '',
      email: '',
      name: '',
      officeAddress: '',
      phone: '',
      yearEstablished: '',
    },
    validationSchema: SchoolSchema,
    onSubmit: async values => {
      await createSchool({
        variables: {
          input: {
            ...values,
            dean: values.dean?._id,
          },
        },
      }).then(({ data }) => {
        if (data.createSchool._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'School created successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not create School' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New School"
      description="Provide the details to add a new school"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? 'Adding School...' : 'Add School'}
          </button>
        </>
      )}
    >
      <SchoolForm form={form} />
    </Modal>
  );
}
