import { classNames, wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { AdmissionProgrammeForm, Modal } from 'components';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import { removeTypename } from 'utils/general';

const CREATE_ADMISSION_PROGRAMME = gql`
  mutation CreateAdmissionProgramme($input: AdmissionProgrammeInput!) {
    admissionProgramme: createAdmissionProgramme(input: $input) {
      _id
    }
  }
`;

export default function CreateAdmissionProgrammeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const [createAdmissionProgramme, { loading }] = useMutation(
    CREATE_ADMISSION_PROGRAMME,
  );

  const form = useFormik({
    initialValues: {
      programmeInfo: {
        school: null as any,
        department: null as any,
        programme: null as any,
        tracks: [] as any[],
        offerings: [] as any[],
        requirementTypes: [] as any[],
        duration: 0,
        hasAdmissionQuota: false,
        admissionQuota: 0,
      },
      requirementsInfo: {
        requirements: [] as any[],
      },
      examinationInfo: {
        requiresExamination: false,
        requiresInterview: false,
        maximumInterviewScore: 0,
        maximumExaminationScore: 0,
        requiredExaminationScore: 0,
        requiredInterviewScore: 0,
        examinationScoreWeight: 0,
        interviewScoreWeight: 0,
      },
    },
    onSubmit: async values => {
      console.log({ values: values.requirementsInfo });

      await createAdmissionProgramme({
        variables: {
          input: {
            ...values.examinationInfo,
            programme: lodash.get(values.programmeInfo.programme, '_id'),
            cycle: params.admissionCycle,
            tracks: values.programmeInfo.tracks,
            offerings: values.programmeInfo.offerings?.map(offering => ({
              campus: offering.campus,
              types: offering?.types,
            })),
            hasAdmissionQuota: values?.programmeInfo?.hasAdmissionQuota,
            admissionQuota: values?.programmeInfo?.admissionQuota,
            duration: values.programmeInfo?.duration,
            requirements: removeTypename(
              values.requirementsInfo?.requirements?.map(
                (requirement: any) => ({
                  ...requirement,
                  defaultMinimumGrade: parseInt(
                    `${requirement?.defaultMinimumGrade}`,
                  ),
                  numberOfPassedSubjects: parseInt(
                    `${requirement?.numberOfPassedSubjects}`,
                  ),
                  specifications: requirement?.specifications?.map(
                    (spec: any) => ({
                      ...spec,
                      mandatory: spec?.mandatory === 'yes' ? true : false,
                    }),
                  ),
                }),
              ),
            ),
          },
        },
      }).then(({ data }) => {
        if (data?.admissionProgramme?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Admission Programme created successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create admission programme',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Create Admission Programme"
      description="Provide the details to create admission programme"
      hideActions
      loading={loading}
      size="4xl"
    >
      <AdmissionProgrammeForm form={form} />
    </Modal>
  );
}
