import { FC } from 'react';
import _ from 'lodash';
import { ICurrentConfig } from 'apollo/cache/config';
import { classNames } from 'utils';

interface ApplicationDetailsContainerProps {
  data: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const ReferenceInfoView: FC<{ data: any; config: ICurrentConfig }> = ({
  data,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200 rounded">
      <div className="">
        <span className="text-xs font-light">Academic References</span>
        <div className="mt-2 space-y-6">
          {!data?.academic?.length && (
            <div className="mt-1 block w-full sm:text-sm">No references</div>
          )}
          {data?.academic?.map((data: any, idx: any) => (
            <div className="relative mt-5 grid grid-cols-3 gap-6 rounded border border-gray-200 p-3">
              <span className="absolute -top-2 left-4 bg-white px-1 text-xs font-light text-gray-700">
                Reference {idx + 1}
                <span
                  className={classNames(
                    data?.approved === true
                      ? `bg-green-100 text-green-800 dark:bg-[#F87A17] dark:text-gray-50`
                      : '',
                    data?.approved === false
                      ? `bg-gray-100 text-gray-800 dark:bg-[#657383] dark:text-gray-50`
                      : '',
                    'ml-4 inline-flex items-center space-x-1 rounded-full px-2 py-1 text-xs',
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 10"
                    fill="currentColor"
                    className="h-1 w-1"
                  >
                    <circle
                      fillRule="evenodd"
                      cx="5"
                      cy="5"
                      r="5"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>{data?.approved ? 'Approved' : 'Pending'}</span>
                </span>
              </span>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Full Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.name || 'Not Set'}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Organization
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.organization || 'Not Set'}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Role
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.title || 'Not Set'}
                </div>
              </div>

              {data?.approved && (
                <>
                  <hr className="col-span-3" />

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Known Duration
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.knownDuration || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Known As
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.knownAs || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Known Duration Details
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.knownDurationDetails || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Applicant has been known to you as a:
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.knownDurationTo || 'Not Set'}
                    </div>
                  </div>

                  <hr className="col-span-3" />

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Motivation
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.motivation || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Self Discipline
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.selfDiscipline || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Leadership
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.leadership || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Self Confidence
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.selfConfidence || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Maturity
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.maturity || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Academic Maturity
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.academicMaturity || 'Not Set'}
                    </div>
                  </div>

                  <div></div>
                  <div></div>

                  <hr className="col-span-3" />

                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Personal Strengths
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.personalStrengths || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Weaknesses
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.weakness || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      General Assessment
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.generalAssessment || 'Not Set'}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Overall Recommendation
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.recommendation?.overallRecommendation || 'Not Set'}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferenceInfoView;
