import { FormikProps } from 'formik';
import { FC, useMemo, useState } from 'react';
import admisssionLetterFormSteps from './form-steps';
import SummaryPage from './summary';
import { StepWizard } from 'components';

interface AdmissionLetterFormProps {
  form: FormikProps<{
    generalInfo: {
      title: string;
      name: string;
      signatureName: string;
      signaturePortfolio: string;
      address: string;
      postalAddress: string;
      signatureUrl: string;
    };
    pointersInfo: {
      pointers: string[];
    };
  }>;
  isCompleted?: boolean;
}

const AdmissionLetterConfigForm: FC<AdmissionLetterFormProps> = ({
  form,
  isCompleted = false,
}) => {
  const [step, setStep] = useState(0);

  const filteredFormSteps = useMemo(
    () =>
      admisssionLetterFormSteps
        .filter(_step => Object.keys(form.values).includes(_step.accessor))
        .map((_step, href) => ({ ..._step, href })),
    [Object.keys(form.values)],
  );

  const steps = useMemo(
    () =>
      [
        ...filteredFormSteps,
        {
          name: 'Preview',
          FormComponent: SummaryPage,
          accessor: 'summary',
          description: 'Summary of everything added so far',
        },
      ].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps],
  );

  const [lastStep, setLastStep] = useState(isCompleted ? steps.length - 1 : 0);

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];

    return (
      <FormComponent
        handleNext={handleNextStep(accessor)}
        initialValues={(form.values as any)[accessor]}
        values={form.values}
        handlePrevious={handlePreviousStep}
        handleCancel={handleCancel}
        handleStep={setStep}
        handleSubmit={form.handleSubmit}
        submitLoading={form.isSubmitting}
        steps={filteredFormSteps}
      />
    );
  };

  return (
    <div className="relative flex h-[75vh] flex-1 flex-col overflow-hidden bg-white shadow-xl dark:bg-gray-900">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">{renderForm()}</div>
    </div>
  );
};

export { AdmissionLetterConfigForm };
