import { Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TextInput } from 'components/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC } from 'react';
import { classNames, wrapClick } from 'utils';

interface ExaminationsInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const ExaminationsInfoForm: FC<ExaminationsInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    onSubmit: values => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden"
    >
      <div className="flex-1 space-y-6 divide-y divide-gray-200 overflow-y-auto p-6">
        <div>
          <span className="text-xs font-light">Examinations Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="">
              <label
                htmlFor={'requiresExamination'}
                className="block text-sm font-medium text-gray-700"
              >
                Requires Entrance Examinations?
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `requiresExamination`)}
                    onChange={(_val: boolean) =>
                      form.setFieldValue(`requiresExamination`, _val)
                    }
                    className={classNames(
                      _.get(form.values, 'requiresExamination')
                        ? 'bg-primary-600'
                        : 'bg-gray-200 dark:bg-gray-700',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, 'requiresExamination')
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-800',
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            {_.get(form.values, 'requiresExamination') && (
              <>
                <div className="col-start-1">
                  <TextInput
                    id="maximumExaminationScore"
                    label="Maximum Examination Score"
                    type="number"
                    placeholder="eg. 75"
                    required={true}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="requiredExaminationScore"
                    label="Required Examination Score"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    max={_.get(form.values, 'maximumExaminationScore')}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="examinationScoreWeight"
                    label="Examination Score Weight"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    postText="%"
                    max={100}
                    min={0}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Interview Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="">
              <label
                htmlFor={'requiresInterview'}
                className="block text-sm font-medium text-gray-700"
              >
                Requires Entrance Interview?
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `requiresInterview`)}
                    onChange={(_val: boolean) =>
                      form.setFieldValue(`requiresInterview`, _val)
                    }
                    className={classNames(
                      _.get(form.values, 'requiresInterview')
                        ? 'bg-primary-600'
                        : 'bg-gray-200 dark:bg-gray-700',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, 'requiresInterview')
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-800',
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            {_.get(form.values, 'requiresInterview') && (
              <>
                <div className="col-start-1">
                  <TextInput
                    id="maximumInterviewScore"
                    label="Maximum Interview Score"
                    type="number"
                    placeholder="eg. 75"
                    required={true}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="requiredInterviewScore"
                    label="Required Interview Score"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    max={_.get(form.values, 'maximumInterviewScore')}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="interviewScoreWeight"
                    label="Interview Score Weight"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    postText="%"
                    max={100}
                    min={0}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ExaminationsInfoForm;
