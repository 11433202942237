import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import { PieChart } from 'components';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { classNames } from 'utils';
import { MoonLoader } from 'react-spinners';
import { LocationGenerics } from 'router/location';
import { useSearch } from 'react-location';
import { currentConfigVar } from 'apollo/cache/config';
import BarChart from 'components/charts/bar-chart';
import { getRandomHexColor } from 'utils/get-random-color';
import { CirclePacking } from '@nivo/circle-packing';
import CirclePackingChart from 'components/charts/circle-packing-chart';
import { toAbbreviation } from 'utils/general';
dayjs.extend(weekOfYear);

interface ApplicantsCampusChartContainerProps {
  cycle?: string;
}

const GET_APPLICATIONS_STATS = gql`
  query Query {
    countApplicationsByDepartment
  }
`;

const ApplicationDepartmentCirclesChartContainer: FC<
  ApplicantsCampusChartContainerProps
> = ({ cycle }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && { cycle: { eq: cycle } };

  const { loading, data } = useQuery(GET_APPLICATIONS_STATS, {
    variables: {
      filter: {
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval,
  });

  const colors = [
    '#5E95FC',
    '#27d163',
    '#FFD36C',
    '#F8649F',
    '#72DBDB',
    '#A955A7',
  ];

  const circlesData = data?.countApplicationsByDepartment?.map(
    (department: any, index: number) => ({
      name: department?.name,
      value: department?.count,
      color: colors?.[index] || getRandomHexColor(),
    }),
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Departments
          </h3>
          <p className="text-xs text-gray-400">
            Displays department choices within applications
          </p>
        </div>
        {loading && <MoonLoader size={20} />}
      </div>

      <CirclePackingChart
        data={{
          name: 'main',
          color: getRandomHexColor(),
          children: circlesData,
        }}
        idAccessor="name"
        valueAccessor="value"
        showLeavesOnly={true}
        chartLabel={data => `${toAbbreviation(data.id)}`}
      />
    </>
  );
};

export default ApplicationDepartmentCirclesChartContainer;
