import { FC } from 'react';
import { Avatar } from 'components/core';
import { classNames } from 'utils';
import dayjs from 'dayjs';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';

interface VendorViewProps {
  vendor: {
    _id: string;
    code: string;
    email: string;
    name: string;
    phone: string;
    profileImageUrl: string;
    meta: {
      isSuspended: boolean;
    };
    createdAt: string;
    description: string;
    updatedAt: string;
    createdBy: {
      name: string;
      email: string;
    };
    lastUpdatedBy: {
      name: string;
      email: string;
    };
  };
}

const VendorView: FC<VendorViewProps> = ({ vendor }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Vendor Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div className="row-span-3 flex flex-col items-center justify-center space-y-3">
            <Avatar
              alt={[(vendor?.name || '')?.trim()].join(' ').trim() || 'N A'}
              src={vendor?.profileImageUrl || ''}
              size="lg"
            />

            <span
              className={classNames(
                vendor?.meta?.isSuspended
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : '',
                !vendor?.meta?.isSuspended
                  ? `bg-gray-100 text-[#32cd51] dark:bg-[#32cd51] dark:text-gray-50`
                  : '',
                'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="h-1.5 w-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>{!vendor?.meta?.isSuspended ? 'Active' : 'Inactive'}</span>
            </span>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Vendor ID
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.name || 'N/A'}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.description || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.email || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.phone || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {dayjs(vendor?.createdAt).format(dateFormat + ' hh:mm A') ||
                'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="... mt-1 block w-full  truncate sm:text-sm">
              {vendor?.createdBy?.email || 'N/A'}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {dayjs(vendor?.updatedAt).format(dateFormat + ' hh:mm A') ||
                'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {vendor?.lastUpdatedBy?.name || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorView;
