import * as Yup from 'yup';

export const AdmissionCycleFormSchema = Yup.object().shape({
  code: Yup.string().notRequired(),
  name: Yup.string().required('Name is a required field'),
  noticeToApplicants: Yup.string()
    .not(['<p><br></p>'], 'Notice to Applicants is a required field')
    .required('Notice to Applicants is a required field'),
  openingDate: Yup.date().required('Opening Date is a required field'),
  description: Yup.string().required('Description is a required field'),
  closingDate: Yup.date().required('Closing Date is a required field'),
  supplementaryDocuments: Yup.string(),
  generalEntryRequirements: Yup.string()
    .not(['<p><br></p>'], 'General Entry Requirements is a required field')
    .required('General Entry Requirements is a required field'),
  academicCalendar: Yup.object().shape({
    matriculationDate: Yup.date().required(
      'Matriculation Date is a required field',
    ),
    openingDate: Yup.date().required('Opening Date is a required field'),
    orientationDate: Yup.date().required(
      'Orientation Date is a required field',
    ),
    year: Yup.string().required('Academic Year is a required field'),
  }),
  schoolFeesDates: Yup.object().shape({
    commitmentFeeDate: Yup.date().required(
      'Commitment Fee Date is a required field',
    ),
    outstandingFeesDate: Yup.date().required(
      'Outstanding Fees Date is a required field',
    ),
    provisionalRegistrationDate: Yup.date().required(
      'Personal Registration Fee Date is a required field',
    ),
  }),
});
