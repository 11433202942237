import { FC } from 'react';
import BarLoader from 'react-spinners/BarLoader';

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center space-y-6">
      <span className="text-sm text-gray-600">Loading details...</span>
      <BarLoader
        color={'#017CB8'}
        loading={true}
        cssOverride={{ width: '30%' }}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
