import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ProgrammeForm, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { ProgrammeFormProps } from 'components/forms/programme';
import { ProgrammeSchema } from 'components/forms/programme/schema';

const GET_PROGRAMME = gql`
  query GetProgrammeLean($filter: ProgrammeFilter!) {
    programme: getProgramme(filter: $filter) {
      _id
      code
      name
      description
      level
      department {
        _id
      }
      createdAt
      updatedAt
      school {
        _id
      }
    }
  }
`;

const UPDATE_PROGRAMME = gql`
  mutation UpdateProgramme($id: ID!, $input: UpdateProgrammeInput!) {
    updateProgramme(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateProgrammeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateProgramme, { loading: updating }] =
    useMutation(UPDATE_PROGRAMME);

  const form = useFormik<ProgrammeFormProps['form']['values']>({
    initialValues: {
      code: '',
      name: '',
      description: '',
      level: '',
      department: '',
      school: '',
    },
    validationSchema: ProgrammeSchema,
    onSubmit: async ({ code, ...values }) => {
      await updateProgramme({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data.updateProgramme._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Programme Edited Successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Programme',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form?.validateForm();
  }, [form?.values]);

  useEffect(() => {
    form.setValues({
      school: data?.programme?.school?._id || '',
      code: data?.programme?.code || '',
      name: data?.programme?.name || '',
      description: data?.programme?.description || '',
      level: data?.programme?.level || '',
      department: data?.programme?.department?._id || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.programme, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Programme Information"
      loading={loading}
      description="Provide the details to update programme"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating || !form?.isValid}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-300 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing Programme...' : 'Edit Programme'}
          </button>
        </>
      )}
    >
      {data?.programme?._id && <ProgrammeForm form={form} />}
    </Modal>
  );
}
