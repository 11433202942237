import { gql, useLazyQuery } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Avatar, SelectInput, TextInput } from 'components/core';
import { UsersPicker } from 'containers';
import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { classNames, wrapClick, wrapOnchange } from 'utils';
import _ from 'lodash';

interface InvoiceFormProps {
  form: FormikProps<{
    name: string;
    currency: string;
    application: any;
    type: string;
    items: any;
    applicationCode: string;
  }>;
}

const GET_APPLICATION = gql`
  query GetApplication($filter: ApplicationFilter!) {
    application: getApplication(filter: $filter) {
      _id
      code
      personalInformation {
        lastName
        middleName
        firstName
        profileImageUrl
      }
      type
      status
      cycle {
        _id
        name
      }
      level
    }
  }
`;

const InvoiceForm: FC<InvoiceFormProps> = ({ form }) => {
  const [
    getApplication,
    { loading: loadingApplication, data: applicationData },
  ] = useLazyQuery(GET_APPLICATION, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (applicationData?.application) {
      form.setValues({
        ...form.values,
        application: applicationData?.application,
      });
    }
  }, [applicationData]);

  const foundApplication = !!form.values?.application;
  const isSubmitted = form.values?.application?.status === 'SUBMITTED';

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Application Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <TextInput
              id="applicationCode"
              label="Application Code"
              type="text"
              placeholder="e.g. application code"
              required={true}
              {...form}
            />
          </div>

          <div className="pt-5">
            <button
              type="button"
              disabled={loadingApplication}
              className="mt-1 inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(() =>
                getApplication({
                  variables: {
                    filter: {
                      code: {
                        eq: form.values?.applicationCode,
                      },
                    },
                  },
                }),
              )}
            >
              {loadingApplication ? 'Searching...' : 'Search'}
            </button>
          </div>

          <div className="">
            <Avatar
              alt={
                [
                  (
                    applicationData?.applicant?.personalInformation?.lastName ||
                    ''
                  )?.trim(),
                ]
                  .join(' ')
                  .trim() || 'N A'
              }
              src={
                applicationData?.application?.personalInformation
                  ?.profileImageUrl || ''
              }
              size="lg"
            />
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {applicationData?.application
                ? [
                    applicationData?.application.personalInformation?.lastName,
                    applicationData?.application.personalInformation?.firstName,
                  ].join(' ')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {applicationData?.application?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {applicationData?.application?.status || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Type</span>
            <div className="mt-1 block w-full sm:text-sm">
              {applicationData?.application?.type || 'N/A'}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className="text-xs font-light">Invoice Details</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <TextInput
              id="name"
              label="Label"
              type="text"
              placeholder="e.g. Surcharge for G-tech verification"
              required={true}
              disabled={!isSubmitted || !foundApplication}
              {...form}
            />
          </div>

          <div>
            <SelectInput
              id="type"
              label="Invoice Type"
              placeholder="Invoice type"
              options={['EXAMINATION', 'SURCHARGE']}
              required={true}
              disabled={!isSubmitted || !foundApplication}
              {...form}
            />
          </div>

          <div>
            <SelectInput
              id="currency"
              label="Choose currency"
              placeholder="Eg. GHS"
              options={['USD', 'GHS']}
              required={true}
              disabled={!isSubmitted || !foundApplication}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className="text-xs font-light">Items Information</span>
        <div className="mt-2 w-full ">
          <table className="min-w-full divide-y divide-gray-300 rounded border border-gray-300">
            <thead className="bg-gray-50">
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                >
                  Item
                </th>
                <th
                  scope="col"
                  className="w-[16%] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                >
                  UnitPrice
                </th>
                <th
                  scope="col"
                  className="w-[38px] whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {_.times(form.values.items?.length + 1, idx => {
                const id = `items.${idx}`;
                const isLast = form.values.items?.length === idx;

                const removeItem = (field: string, id: number) => {
                  form.setFieldValue(field, [
                    ..._.get(form.values, field)?.filter(
                      (val: string, _id: number) => _id !== id,
                    ),
                  ]);
                };

                return (
                  <tr key={idx} className="divide-x divide-gray-200">
                    <td className="p-0">
                      <input
                        key={idx}
                        id={`${id}.item`}
                        name={`${id}.item`}
                        type="text"
                        disabled={!isSubmitted || !foundApplication}
                        value={form.values?.items?.[idx]?.item || ''}
                        onChange={wrapOnchange(value => {
                          form.setFieldValue(
                            `${id}.item`,
                            value?.length ? value : null,
                          );
                          form.setFieldTouched(`${id}.item`, true);
                        })}
                        className={classNames(
                          _.get(form.errors, `${id}.item`) &&
                            _.get(form.touched, `${id}.item`)
                            ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                          'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                        )}
                        placeholder="Enter item description"
                      />
                    </td>

                    <td className="p-0">
                      <input
                        key={idx}
                        id={`${id}.unitPrice`}
                        name={`${id}.unitPrice`}
                        type="number"
                        value={form.values?.items?.[idx]?.unitPrice}
                        onChange={wrapOnchange(value => {
                          form.setFieldValue(
                            `${id}.unitPrice`,
                            value?.length ? value : null,
                          );
                        })}
                        disabled={!isSubmitted || !foundApplication}
                        className={classNames(
                          _.get(form.errors, `${id}.unitPrice`) &&
                            _.get(form.touched, `${id}.unitPrice`)
                            ? 'border border-red-600 focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-200 focus:border-primary-500 focus:ring-primary-500',
                          'block h-[38px] w-full border-0 placeholder:text-xs placeholder:font-light focus:border sm:text-sm',
                        )}
                        placeholder="eg. 30"
                      />
                    </td>

                    <td className="flex h-[38px] w-[38px] items-center justify-center whitespace-nowrap p-0 text-right text-sm text-gray-500 dark:text-gray-400">
                      {!isLast && (
                        <button
                          type="button"
                          onClick={wrapClick(() => removeItem('items', idx))}
                          className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForm;
