import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { Avatar } from 'components/core';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { FC } from 'react';

interface SchoolViewProps {
  school: {
    code: string;
    name: string;
    phone: string;
    email: string;
    officeAddress: string;
    digitalAddress: string;
    dean: {
      _id: string;
      name: string;
      email: string;
      phone: string;
      profileImageUrl: string;
      staffId: string;
    };
    meta: {
      programmesCount: number;
      departmentsCount: number;
    };
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
  };
}

const SchoolView: FC<SchoolViewProps> = ({ school }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">School Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.code || 'N/A'}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.name || 'N/A'}
            </div>
          </div>
        </div>
      </div>

      {school?.dean && (
        <div>
          <span className="text-xs font-light">Dean Information</span>
          <div className="mt-2 grid grid-cols-3 gap-6">
            <div className="row-span-3 flex flex-col items-center justify-center space-y-3">
              <Avatar
                alt={school.dean?.name || 'user_image'}
                src={school.dean?.profileImageUrl || ''}
                size="xl"
              />
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Staff ID
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {school.dean?.staffId || 'N/A'}
              </div>
            </div>

            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {school.dean?.name || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pt-6">
        <span className="text-xs font-light">Contact Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.phone || 'N/A'}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.email || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Digital Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.digitalAddress || 'N/A'}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Office Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.officeAddress || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">School Statistics</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Departments Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(school?.meta?.departmentsCount || 0).format('0,0')}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Programmes Count
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(school?.meta?.programmesCount || 0).format('0,0')}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.createdAt
                ? dayjs(school?.createdAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.createdBy?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.updatedAt
                ? dayjs(school?.updatedAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {school?.lastUpdatedBy?.name || 'N/A'}
            </div>
          </div>
        </div>
        {school?.deleted && (
          <p className="mt-2 text-sm text-red-500">
            This school has been deleted
          </p>
        )}
      </div>
    </div>
  );
};

export default SchoolView;
