import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, SchoolForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { SchoolSchema } from 'components/forms/school/schema';

const GET_SCHOOL = gql`
  query GetSchoolLean($filter: SchoolFilter!) {
    school: getSchool(filter: $filter) {
      _id
      code
      dean {
        _id
        code
        name
        email
        phone
        role
      }
      digitalAddress
      email
      name
      phone
      officeAddress
      yearEstablished
    }
  }
`;

const UPDATE_SCHOOL = gql`
  mutation UpdateSchool($id: ID!, $input: SchoolInput!) {
    school: updateSchool(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateSchoolContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SCHOOL, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateSchool, { loading: updating }] = useMutation(UPDATE_SCHOOL);

  const form = useFormik({
    initialValues: {
      dean: null as any,
      digitalAddress: '',
      email: '',
      name: '',
      officeAddress: '',
      phone: '',
      yearEstablished: '',
    },
    validationSchema: SchoolSchema,
    onSubmit: async values => {
      await updateSchool({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
            ...(values.dean ? { dean: values.dean._id } : {}),
          },
        },
      }).then(({ data }) => {
        if (data?.school?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'School updated successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not update School' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      name: data?.school?.name || '',
      officeAddress: data?.school?.officeAddress || '',
      digitalAddress: data?.school?.digitalAddress || '',
      yearEstablished: data?.school?.yearEstablished || '',
      email: data?.school?.email || '',
      phone: data?.school?.phone || '',
      dean: data?.school?.dean || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.school, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit School Information"
      loading={loading}
      description="Provide the details to update school"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing School...' : 'Edit School'}
          </button>
        </>
      )}
    >
      {data?.school?._id && <SchoolForm form={form} />}
    </Modal>
  );
}
