import { FC } from 'react';
import _ from 'lodash';
import lodash from 'lodash';
import dayjs from 'dayjs';

const EmploymentInfoSummary: FC<{ data: any; config: any }> = ({
  data,
  config,
}) => {
  console.log({ data });
  return (
    <div className="space-y-6 divide-y divide-gray-200 rounded border border-dashed border-gray-300 p-3 dark:border-gray-600">
      <div className="">
        <span className="text-xs font-light">Employment History</span>
        <div className="mt-2 space-y-6">
          {!data?.length && (
            <div className="mt-1 block w-full sm:text-sm">
              No employment data
            </div>
          )}
          {data?.map((data: any, idx: any) => (
            <div className="relative mt-2 grid grid-cols-4 gap-6 rounded border border-gray-200 p-3">
              <span className="absolute -top-2 left-4 bg-white px-1 text-xs font-light text-gray-700">
                Employment {idx + 1}
              </span>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Organization
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.organization || 'Not Set'}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Role
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.title || 'Not Set'}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Start Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.startDate
                    ? dayjs(data?.startDate).format('DD MMMM, YYYY')
                    : 'Not Set'}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  End Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.endDate
                    ? dayjs(data?.endDate).format('DD MMMM, YYYY')
                    : 'Not Set'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmploymentInfoSummary;
