import _ from 'lodash';
import { FC } from 'react';

interface TableBodyComponentProps<TData = any> {
  data: {
    rows: TData[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  renderColumns?: FC;
  renderItem?: FC<TData>;
  renderLoader?: FC;
  loading?: boolean;
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({
  renderColumns,
  data,
  renderItem,
  loading,
  renderLoader,
}) => {
  return (
    <div className="no-scrollbar -my-2 flex flex-1 overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-0">
      <div className="no-scrollbar flex min-w-full flex-1 flex-col overflow-y-auto align-middle sm:px-6 lg:px-6">
        <table
          className="min-w-full border-separate divide-y divide-gray-200 border-x border-gray-200 dark:divide-gray-700 dark:border-gray-700 "
          style={{ borderSpacing: 0 }}
        >
          <thead className="bg-gray-50 dark:bg-gray-800">
            {renderColumns?.({}) ?? (
              <tr>
                {Object.keys(data.rows[0])
                  .filter(field => !_.isObject(data.rows[0][field]))
                  .map(field => (
                    <th
                      scope="col"
                      className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-600 dark:text-gray-300"
                    >
                      {_.startCase(field)}
                    </th>
                  ))}
              </tr>
            )}
          </thead>
          <tbody className="divide-y divide-gray-200 overflow-y-auto bg-white dark:divide-gray-700 dark:bg-gray-900">
            {loading
              ? (renderLoader?.({}) ?? (
                  <tr>
                    <td className="flex-1 whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm  text-gray-500">
                      <div className="h-3 w-full animate-pulse rounded bg-gray-500 dark:bg-gray-400" />
                    </td>
                  </tr>
                ))
              : data.rows?.map(
                  item =>
                    renderItem?.(item) ?? (
                      <tr key={item._id}>
                        {Object.keys(data.rows[0])
                          .filter(field => !_.isObject(data.rows[0][field]))
                          .map(field => (
                            <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                              {_.isBoolean(item[field])
                                ? item[field]
                                  ? 'Yes'
                                  : 'No'
                                : item[field]}
                            </td>
                          ))}
                      </tr>
                    ),
                )}
          </tbody>
          <tfoot className=" sticky bottom-0 hidden bg-gray-50 dark:bg-gray-800">
            {renderColumns?.({}) ?? (
              <tr>
                {Object.keys(data.rows[0])
                  .filter(field => !_.isObject(data.rows[0][field]))
                  .map(field => (
                    <th
                      scope="col"
                      className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-600 dark:text-gray-300"
                    >
                      {_.startCase(field)}
                    </th>
                  ))}
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TableBodyComponent;
