import { FC } from 'react';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { useReactiveVar } from '@apollo/client';

interface InvoiceViewProps {
  invoice: {
    _id: any;
    code: any;
    createdAt: any;
    paidAt: any;
    vendor: {
      name: any;
    };
    totalAmount: any;
    name: any;
    mode: any;
    currency: any;
    application: {
      personalInformation: {
        lastName: any;
        firstName: any;
        middleName: any;
      };
      code: any;
      cycle: {
        name: any;
        _id: any;
      };
      level: any;
      type: any;
    };
    status: any;
    applicant: {
      email: any;
      _id: any;
    };
    vendorReference: any;
    fxRates: {
      USDXGHS: number;
    };
  };
}

const InvoiceView: FC<InvoiceViewProps> = ({ invoice }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Invoice Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Invoice Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {invoice?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Total
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {`${invoice?.currency} ${(invoice?.totalAmount || 0) / 100}` ||
                'N/A'}
            </div>
          </div>

          {invoice?.currency === 'USD' && invoice?.status === 'PAID' && (
            <div>
              <span className="block text-sm font-light text-gray-700">
                FX Rate (USDXGHS)
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {`${invoice?.fxRates?.USDXGHS || 'N/A'}`}
              </div>
            </div>
          )}

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Transaction Reference
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {invoice?.vendorReference || 'Not set'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Application Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {invoice?.application?.code || 'N/A'}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {[
                invoice?.application?.personalInformation?.lastName,
                invoice?.application?.personalInformation?.firstName,
                invoice?.application?.personalInformation?.middleName,
              ].join(' ') || 'N/A'}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Type</span>
            <div className="mt-1 block w-full capitalize sm:text-sm">
              {invoice?.application?.type?.toLowerCase() || 'N/A'}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Level
            </span>
            <div className="mt-1 block w-full capitalize sm:text-sm">
              {invoice?.application?.level?.toLowerCase() || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Applicant Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {invoice?.applicant.email || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {invoice?.createdAt
                ? dayjs(invoice?.createdAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          {invoice?.vendor && (
            <>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Paid At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {invoice?.paidAt
                    ? dayjs(invoice?.paidAt).format(dateFormat + ' hh:mm A')
                    : 'N/A'}
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Vendor
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {invoice?.vendor?.name || 'N/A'}
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Payment Mode
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {invoice?.mode || 'N/A'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
