import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { DepartmentForm, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { DepartmentFormProps } from 'components/forms/department';
import { DepartmentSchema } from 'components/forms/department/schema';

const GET_DEPARTMENT = gql`
  query GetDepartment($filter: DepartmentFilter!) {
    department: getDepartment(filter: $filter) {
      _id
      code
      name
      officeAddress
      digitalAddress
      phone
      school {
        _id
      }
      email
      head {
        _id
        name
        profileImageUrl
      }
    }
  }
`;

const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($id: ID!, $input: UpdateDepartmentInput!) {
    updateDepartment(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateDepartmentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery<any>(GET_DEPARTMENT, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateDepartment, { loading: updating }] =
    useMutation(UPDATE_DEPARTMENT);

  const form = useFormik<DepartmentFormProps['form']['values']>({
    initialValues: {
      code: '',
      name: '',
      digitalAddress: '',
      officeAddress: '',
      phone: '',
      email: '',
      school: '',
      head: '',
    },
    validationSchema: DepartmentSchema,
    onSubmit: async ({ code, ...values }) => {
      await updateDepartment({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
            ...(values.head ? { head: values.head._id } : {}),
          },
        },
      }).then(({ data }) => {
        if (data.updateDepartment._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Department Edited Successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Department',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      school: data?.department?.school?._id || '',
      code: data?.department?.code || '',
      name: data?.department?.name || '',
      digitalAddress: data?.department?.digitalAddress || '',
      officeAddress: data?.department?.officeAddress || '',
      email: data?.department?.email || '',
      phone: data?.department?.phone || '',
      head: data?.department?.head || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.department, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Edit Department Information"
      description="Provide the details to update department"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing Department...' : 'Edit Department'}
          </button>
        </>
      )}
    >
      {data?.department?._id && <DepartmentForm form={form} />}
    </Modal>
  );
}
