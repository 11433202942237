import * as Yup from 'yup';

export const ProgrammeSchema = Yup.object().shape({
  name: Yup.string().required('Name is a required field'),
  level: Yup.string().required('Level is a required field'),
  description: Yup.string().required('Description is a required field'),
  department: Yup.string().required('Department is a required field'),
  school: Yup.string().required('School is a required field'),
});

export const ProgrammeInitialValues = {
  name: '',
  level: '',
  description: '',
  department: '',
  school: '',
};
