import ActionButton, { Action } from 'components/buttons/action-button';
import {
  ExportDataContainer,
  ImportDataContainer,
  SchoolPicker,
} from 'containers';
import { FC, useEffect, useMemo, useState } from 'react';
import { SelectInput, Shimmers, TableComponent } from 'components';
import {
  classNames,
  useDownloadFile,
  useUrlState,
  withPermissions,
  wrapClick,
} from 'utils';
import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { useMatch, useMatches, useNavigate, useSearch } from 'react-location';

import AdmissionProgrammePickerContainer from 'containers/admission-programme-picker';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import CreateAdmissionTransferContainer from './transfer';
import DownloadAdmissionContainer from './download-admission';
import ExportPassportContainer from './export-passport';
import { LocationGenerics } from 'router/location';
import { PuffLoader } from 'react-spinners';
import TransferSingleAdmissionContainer from './transfer-admission';
import ViewAdmittedContainer from './view';
import axios from 'axios';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import { currentUserVar } from 'apollo/cache/auth';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { numberToWords } from 'utils/general';
import toast from 'react-hot-toast';
import { useToggle } from 'react-use';

// import StartAdmissionsContainer from './start-admissions';

const GET_ADMISSIONS = gql`
  query GetAdmissions(
    $filter: AdmissionFilter
    $pagination: Pagination
    $populate: [String]
    $sort: AdmissionSort
    $search: SearchOperator
  ) {
    items: getAdmissions(
      filter: $filter
      pagination: $pagination
      populate: $populate
      sort: $sort
      search: $search
    ) {
      _id
      applicant {
        code
        email
        _id
      }
      code
      programme {
        name
        _id
        code
        department {
          _id
          name
        }
        level
      }
      shortlist {
        startingYear
      }
      updatedAt
      createdAt
      application {
        _id
        personalInformation {
          firstName
          lastName
        }
      }
      indexNumber
    }
    itemsCount: getAdmissionsCount(filter: $filter, search: $search)
  }
`;

const CREATE_EXPORT = gql`
  query GetExportFileUrl($filter: JSON) {
    url: getAdmissionsFileUrl(filter: $filter)
  }
`;

const AdmissionsPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [admissionProgramme, setAdmissionProgramme] =
    useUrlState('admissionProgramme');
  const [level, setLevel] = useUrlState('level');
  const [school, setSchool] = useUrlState('school');
  const [hasIndexNumber, setHasIndexNumber] = useUrlState('hasIndexNumber');
  const matches = useMatches<LocationGenerics>();
  const cycle = matches?.[0]?.params?.admissionCycle;

  useEffect(() => {
    setSchool(currentUser?.school?._id);
  }, [currentUser]);

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search?.toString() || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search?.toString(),
              options: ['CaseInsensitive'],
              fields: [
                'indexNumber',
                'applicant.email',
                'applicant.code',
                'applicationName',
                'applicationEmail',
                'applicationCode',
                'application.gimpaIndexNumber',
              ],
            },
          }
        : {}),
      filter: {
        cycle: {
          eq: params.admissionCycle,
        },
        ...(admissionProgramme && { programme: { eq: admissionProgramme } }),
        ...(school && { school: { eq: school } }),
        ...(level && { applicationLevel: { eq: level } }),
        hasIndexNumber:
          hasIndexNumber === 'With ID'
            ? true
            : hasIndexNumber === 'Without ID'
              ? false
              : undefined,
      },
      sort: {},
      populate: [
        'applicant',
        'programme',
        'programme.department',
        'application.personalInformation',
      ],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_ADMISSIONS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      }),
    });
  }, [navigate]);

  const [createDataExport, { loading: loadingExport }] = useLazyQuery(
    CREATE_EXPORT,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: 'error',
          title: 'An error occurred downloading file',
        }),
      );
    },
    getFileName: () => new Date().toISOString() + 'Admissions' + '_.csv',
  });

  const handleExportAdmitted = () => {
    createDataExport({
      variables: {
        filter: variables?.filter,
      },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => {
      console.log('datum', data);
      if (data.url) {
        window.open(data.url, '_blank');
        // downloadAction(`/public/${data.url}`)
      } else {
        toast(
          JSON.stringify({ type: 'error', title: 'Could not export file' }),
        );
      }
    });
  };

  const [loadingUploadSheet, setLoadingUploadSheet] = useState(false);

  const handleAdmissionsIds = (formData: FormData) => {
    setLoadingUploadSheet(true);

    formData.set('cycle', cycle || '');

    axios
      .post('/admissions/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        if (data) {
          console.log('handleAdmissionsIds', { data });
          toast(
            JSON.stringify({
              type: 'success',
              title: `Updated ${data?.updatedCount} admissions`,
            }),
          );
          setModal('');
          setLoadingUploadSheet(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoadingUploadSheet(false);
      })
      .finally(() => {
        setLoadingUploadSheet(false);
      });
  };

  return (
    <main className="flex h-full flex-1 flex-col overflow-hidden bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
          <TableComponent
            title={'Admission'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, 'items', []),
              total: lodash.get(data, 'itemsCount', 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, 'itemsCount', 0) /
                  (searchParams?.pageSize || config.constants.pageSize),
              ),
            }}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 space-x-2 sm:ml-16 sm:mt-0 sm:flex">
                {school && (
                  <AdmissionProgrammePickerContainer
                    id="admissionProgramme"
                    labelHidden={true}
                    rawId={true}
                    setFieldValue={(field: any, value: string) =>
                      setAdmissionProgramme(value)
                    }
                    filter={{
                      cycle: { eq: params.admissionCycle as string },
                      deleted: { eq: false },
                      ...(school && { school: { eq: school } }),
                    }}
                    values={{}}
                  />
                )}

                {!currentUser?.school?._id && (
                  <SchoolPicker
                    id="school"
                    labelHidden={true}
                    rawId={true}
                    setFieldValue={(field: any, value: string) =>
                      setSchool(value)
                    }
                    filter={{
                      cycle: { eq: params.admissionCycle as string },
                      deleted: { eq: false },
                      ...(school && { school: { eq: school } }),
                    }}
                    disabled={!!currentUser?.school?._id}
                    values={{ school }}
                  />
                )}

                <SelectInput
                  id="level"
                  label=""
                  labelHidden
                  placeholder="Filter Level"
                  options={['UNDERGRADUATE', 'GRADUATE']}
                  handleChange={(event: any) => {
                    setLevel(event.target.value);
                  }}
                  handleBlur={() => null}
                  values={{ level }}
                />

                <SelectInput
                  id="hasIndexNumber"
                  label=""
                  labelHidden
                  placeholder="Filter Index Number"
                  options={['ALL', 'With ID', 'Without ID']}
                  handleChange={(event: any) => {
                    setHasIndexNumber(event.target.value);
                  }}
                  handleBlur={() => null}
                  values={{ hasIndexNumber }}
                />
                {withPermissions(['*:*', 'admissions:*', 'admissions:export'])(
                  <button
                    type="button"
                    disabled={loading}
                    className="flex h-[38px] w-full flex-row justify-center gap-x-2 rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleExportAdmitted}
                  >
                    <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
                    {loadingExport ? 'Exporting...' : 'Admission Sheet'}
                  </button>,
                )}
              </div>
            )}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Admission"
                variables={variables}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Applicant
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Index Number
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Level
                </th>
                {!admissionProgramme && (
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Programme
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Starting Year
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Admitted On
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={item => (
              <tr
                key={item._id}
                className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                onClick={wrapClick(dispatchAction(item._id, 'view'))}
              >
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <div className="font-medium uppercase text-gray-900 dark:text-gray-100">
                    {[
                      item?.application?.personalInformation?.lastName,
                      item?.application?.personalInformation?.firstName,
                    ].join(' ') || 'N/A'}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {item?.applicant?.email || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="font-medium text-gray-400 dark:text-gray-100">
                    {item?.indexNumber || 'Not Set'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500 ">
                  <span
                    className={classNames(
                      item?.programme?.level === 'GRADUATE'
                        ? `bg-orange-100 text-orange-800 dark:bg-[#F87A17] dark:text-gray-50`
                        : '',
                      item?.programme?.level === 'UNDERGRADUATE'
                        ? `bg-primary-100 text-primary-800 dark:bg-[#657383] dark:text-gray-50`
                        : '',
                      !item?.programme?.level
                        ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                        : '',
                      'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="h-1.5 w-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.programme?.level || 'Unknown'}</span>
                  </span>
                </td>
                {!admissionProgramme && (
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.programme?.name || 'N/A'}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="font-medium text-gray-400 dark:text-gray-100">{`${item?.shortlist?.startingYear || 1} (${numberToWords(item?.shortlist?.startingYear || 1)})`}</div>
                </td>

                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="text-gray-400 dark:text-gray-100">
                    {item?.createdAt
                      ? dayjs(item?.createdAt).format('MMM DD, YYYY - HH:mm')
                      : 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />

                    {withPermissions([
                      '*:*',
                      'admissions:*',
                      'admissions:transfer',
                    ])(
                      <ActionButton
                        action="goto"
                        onClick={dispatchAction(item?._id, 'reassign')}
                      />,
                    )}

                    {/* {
                      item?.application?.gimpaIndexNumber && <>
                        {withPermissions(["*:*", "admissions:*", "admissions:manage"])(
                          <ActionButton
                            action="download"
                            onClick={dispatchAction(item?._id, "download")}
                          />
                        )}
                      </>
                    } */}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {/* {withPermissions(["*:*", "voucher-classes:*", "voucher-classes:create"])(
        <StartAdmissionsContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )} */}
      <ImportDataContainer
        entityType="Admission Data"
        setOpen={(val: boolean) => setModal(val ? 'upload' : undefined)}
        open={modal === 'upload'}
        onSubmit={handleAdmissionsIds}
        loading={loadingUploadSheet}
      />

      {withPermissions(['*:*', 'admissions:*', 'admissions:transfer'])(
        <CreateAdmissionTransferContainer
          open={modal === 'transfer'}
          setOpen={(val: boolean) => setModal(val ? 'transfer' : undefined)}
          refetch={refetch}
        />,
      )}

      <ExportPassportContainer
        open={modal === 'investigate'}
        setOpen={(val: boolean) => setModal(val ? 'investigate' : undefined)}
        refetch={refetch}
      />

      {!!searchParams.id?.length && (
        <>
          <ViewAdmittedContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />

          {withPermissions(['*:*', 'admissions:*', 'admissions:transfer'])(
            <TransferSingleAdmissionContainer
              open={modal === 'reassign'}
              setOpen={(val: boolean) => setModal(val ? 'reassign' : undefined)}
              refetch={refetch}
            />,
          )}

          {withPermissions(['*:*', 'admissions:*', 'admissions:manage'])(
            <DownloadAdmissionContainer
              open={modal === 'download'}
              setOpen={(val: boolean) => setModal(val ? 'download' : undefined)}
            />,
          )}
        </>
      )}
    </main>
  );
};

export default AdmissionsPage;
