import { FC, useState } from 'react';
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid';
import { classNames, useUrlState, withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useLocation, useMatch } from 'react-location';

import InvoiceChartContainer from 'containers/invoices-chart';
import { LocationGenerics } from 'router/location';
import { TicketIcon } from '@heroicons/react/24/solid';
import { TicketIcon as TicketOutline } from '@heroicons/react/24/outline';
import UpdateAdmissionCycleContainer from 'pages/admission-cycles/update';
import { currentConfigVar } from 'apollo/cache/config';
import { currentUserVar } from 'apollo/cache/auth';
import dayjs from 'dayjs';
import numeral from 'numeral';
import parseHtml from 'html-react-parser';

// import { Listbox, Transition } from '@headlessui/react';

const activity = [
  {
    id: 1,
    type: 'created',
    person: { name: 'Chelsea Hagon' },
    date: '7d ago',
    dateTime: '2023-01-23T10:32',
  },
  {
    id: 2,
    type: 'edited',
    person: { name: 'Chelsea Hagon' },
    date: '6d ago',
    dateTime: '2023-01-23T11:03',
  },
  {
    id: 3,
    type: 'sent',
    person: { name: 'Chelsea Hagon' },
    date: '6d ago',
    dateTime: '2023-01-23T11:24',
  },
  {
    id: 4,
    type: 'commented',
    person: {
      name: 'Chelsea Hagon',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    comment:
      'Called client, they reassured me the invoice would be paid by the 25th.',
    date: '3d ago',
    dateTime: '2023-01-23T15:56',
  },
  {
    id: 5,
    type: 'viewed',
    person: { name: 'Alex Curren' },
    date: '2d ago',
    dateTime: '2023-01-24T09:12',
  },
  {
    id: 6,
    type: 'paid',
    person: { name: 'Alex Curren' },
    date: '1d ago',
    dateTime: '2023-01-24T09:20',
  },
];
const moods = [
  {
    name: 'Excited',
    value: 'excited',
    icon: FireIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Loved',
    value: 'loved',
    icon: HeartIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-400',
  },
  {
    name: 'Happy',
    value: 'happy',
    icon: FaceSmileIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-400',
  },
  {
    name: 'Sad',
    value: 'sad',
    icon: FaceFrownIcon,
    iconColor: 'text-white',
    bgColor: 'bg-yellow-400',
  },
  {
    name: 'Thumbsy',
    value: 'thumbsy',
    icon: HandThumbUpIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'I feel nothing',
    value: null,
    icon: XMarkIconMini,
    iconColor: 'text-gray-400',
    bgColor: 'bg-transparent',
  },
];

const STATS_SUMMARY_CYCLE = gql`
  query Query(
    $admissionProgrammeFilter: AdmissionProgrammeFilter
    $applicationsFilter: ApplicationFilter
    $invoiceFilter: InvoiceFilter
    $shortlistFilter: ShortlistFilter
  ) {
    admissionProgrammes: getAdmissionProgrammesCount(
      filter: $admissionProgrammeFilter
    )
    applicants: getApplicationsCount(filter: $applicationsFilter)
    paidInvoices: getInvoicesCount(filter: $invoiceFilter)
    shortlisted: getShortlistCount(filter: $shortlistFilter)
  }
`;

const cycleFilters = (cycleId: string) => ({
  invoiceFilter: {
    cycle: {
      eq: cycleId,
    },
    status: {
      eq: 'PAID',
    },
  },
  admissionProgrammeFilter: {
    deleted: {
      eq: false,
    },
    cycle: {
      eq: cycleId,
    },
  },
  applicationsFilter: {
    cycle: {
      eq: cycleId,
    },
  },
  shortlistFilter: {
    cycle: {
      eq: cycleId,
    },
  },
});

const GET_ADMISSION_CYCLE = gql`
  query GetAdmissionCycle($filter: AdmissionCycleFilter!, $populate: [String]) {
    admissionCycle: getAdmissionCycle(filter: $filter, populate: $populate) {
      _id
      code
      name
      description
      noticeToApplicants
      generalEntryRequirements
      openingDate
      closingDate
      academicCalendar {
        year
        openingDate
        orientationDate
        matriculationDate
      }
      createdBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        meta {
          isFirstLogin
          isSuspended
        }
      }
      lastUpdatedBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        meta {
          isFirstLogin
          isSuspended
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_INVOICES = gql`
  query Query(
    $undergraduatesFilter: InvoiceFilter
    $graduatesFilter: InvoiceFilter
    $calculateInvoicesFilter: CalculateInvoicesFilter
  ) {
    undergraduates: getInvoicesCount(filter: $undergraduatesFilter)
    graduates: getInvoicesCount(filter: $graduatesFilter)
    calculateInvoices(filter: $calculateInvoicesFilter)
  }
`;

const AdmissionCyclePage: FC = () => {
  const [selected, setSelected] = useState(moods[5]);
  const location = useLocation<any>();
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar);
  const { params } = useMatch<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const { data, refetch } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      filter: {
        _id: {
          eq: params.admissionCycle,
        },
      },
      populate: ['createdBy', 'lastUpdatedBy'],
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
  });

  const { data: statsData, refetch: refetchStats } = useQuery(
    STATS_SUMMARY_CYCLE,
    {
      variables: {
        ...(params.admissionCycle && {
          ...cycleFilters(params.admissionCycle),
        }),
      },
      notifyOnNetworkStatusChange: false,
      pollInterval,
    },
  );

  const { data: invoiceData, loading: loadingInvoices } = useQuery(
    GET_INVOICES,
    {
      variables: {
        undergraduatesFilter: {
          cycle: { eq: params.admissionCycle },
          applicationLevel: {
            eq: 'UNDERGRADUATE',
          },
          status: {
            eq: 'PAID',
          },
        },
        graduatesFilter: {
          cycle: { eq: params.admissionCycle },
          applicationLevel: {
            eq: 'GRADUATE',
          },
          status: {
            eq: 'PAID',
          },
        },
        calculateInvoicesFilter: {
          cycle: { eq: params.admissionCycle },
          status: {
            eq: 'PAID',
          },
        },
      },
    },
  );

  const totalGrads = invoiceData?.calculateInvoices?.graduate?.GHS / 100;
  const totalUndergrads =
    invoiceData?.calculateInvoices?.undergraduate?.GHS / 100;

  const totalBoth = totalGrads + totalUndergrads;

  const currentUser = useReactiveVar(currentUserVar);

  const stats = [
    {
      name: 'Applicants',
      value: statsData?.applicants || 0,
      changeType: 'positive',
    },
    {
      name: 'Offer Programmes',
      value: statsData?.admissionProgrammes || 0,
      changeType: 'positive',
    },
    {
      name: 'Paid Invoices',
      value: statsData?.paidInvoices || 0,
      changeType: 'negative',
    },
    {
      name: 'Shortlisted Applicants',
      value: statsData?.shortlisted || 0,
      changeType: 'negative',
    },
  ];

  const isPast = new Date(data?.admissionCycle?.closingDate) < new Date();
  const isOngoing =
    new Date(data?.admissionCycle?.openingDate) < new Date() &&
    new Date(data?.admissionCycle?.closingDate) > new Date();
  const status = isOngoing ? 'ON_GOING' : isPast ? 'CLOSED' : 'PENDING';

  return (
    <div className="no-scrollbar overflow-y-auto bg-gray-50 p-4 sm:p-6">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded lg:col-span-3">
          <dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            {stats.map((stat, statIdx) => (
              <div
                key={stat.name}
                className={classNames(
                  statIdx % 2 === 1
                    ? 'sm:border-l'
                    : statIdx === 2
                      ? 'lg:border-l'
                      : '',
                  'flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/10 p-4 sm:p-6 lg:border-t-0',
                )}
              >
                <dt className="text-sm font-medium leading-6 text-gray-500">
                  {stat.name}
                </dt>
                <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Invoice */}
        <div className="-mx-4 bg-white px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 xl:px-16 xl:pb-20 xl:pt-16">
          <div className="">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Admission Cycle Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Details about the Admission Cycle
            </p>
          </div>
          <div className="mt-6">
            <dl className="grid grid-cols-1 sm:grid-cols-12">
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Code
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.code || 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-8 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.name || 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-12 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.description || 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Opening Date
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.openingDate
                    ? dayjs(data?.admissionCycle?.openingDate)?.format(
                        dateFormat,
                      )
                    : 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Closing Date
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.closingDate
                    ? dayjs(data?.admissionCycle?.closingDate)?.format(
                        dateFormat,
                      )
                    : 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {status.replaceAll('_', ' ') || 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-12 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Notice To Applicants
                </dt>
                <dd className="mt-1 rounded-md border border-gray-200 p-4 text-sm leading-6 text-gray-700 sm:mt-2">
                  {parseHtml(data?.admissionCycle?.noticeToApplicants || 'N/A')}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-12 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  General Entry Requirements
                </dt>
                <dd className="mt-1 rounded-md border border-gray-200 p-4 text-sm leading-6 text-gray-700 sm:mt-2">
                  {parseHtml(
                    data?.admissionCycle?.generalEntryRequirements || 'N/A',
                  )}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Created At
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.createdAt
                    ? dayjs(data?.admissionCycle?.createdAt)?.format(
                        dateFormat + ' hh:mm A',
                      )
                    : 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Created By
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.createdBy?.name || 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Last Updated At
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.updatedAt
                    ? dayjs(data?.admissionCycle?.updatedAt)?.format(
                        dateFormat + ' hh:mm A',
                      )
                    : 'N/A'}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Last Updated By
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {data?.admissionCycle?.lastUpdatedBy?.name || 'N/A'}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Invoice summary */}
        <div className="flex flex-col space-y-5">
          {withPermissions(['*:*', 'invoices:*', 'invoices:manage'])(
            <div className="mt-0 rounded bg-white shadow-sm ring-1 ring-gray-900/5">
              <dl className="flex flex-wrap pb-6 ">
                <div className="flex-auto pl-6 pt-6">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Paid Invoices
                  </dt>
                  <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                    {loadingInvoices
                      ? 'Loading...'
                      : `GHC ${numeral(totalBoth).format('0,0.00')}`}{' '}
                  </dd>
                </div>
                <div className="flex-none self-end px-6 pt-4">
                  <dt className="sr-only">Status</dt>
                  <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                    Paid
                  </dd>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none">
                    <span className="sr-only">Graduate</span>
                    <TicketIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    Graduate ({invoiceData?.graduates || 0})
                  </dd>
                  <dd className="text-sm font-semibold text-gray-900">
                    GHC {numeral(totalGrads).format('0,0.00')}
                  </dd>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none">
                    <span className="sr-only">Undergraduate</span>
                    <TicketOutline
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    Undergraduate ({invoiceData?.undergraduates || 0})
                  </dd>
                  <dd className="text-sm font-semibold text-gray-900">
                    GHC {numeral(totalUndergrads).format('0,0.00')}
                  </dd>
                </div>
              </dl>

              <div className="border-t border-gray-900/5 px-6 py-6">
                <span
                  onClick={wrapClick(() => location.history.push('/invoices'))}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Go to Invoices <span aria-hidden="true">&rarr;</span>
                </span>
              </div>
            </div>,
          )}

          <div className="flex h-96 w-full flex-col rounded bg-white p-5 shadow">
            <InvoiceChartContainer cycleId={params.admissionCycle} />
          </div>

          <div></div>
        </div>

        {/* Activity feed */}
        {/* <div className="lg:col-start-3">
          <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
          <ul role="list" className="mt-6 space-y-6">
            {activity.map((activityItem, activityItemIdx) => (
              <li key={activityItem.id} className="relative flex gap-x-4">
                <div
                  className={classNames(
                    activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-6 justify-center'
                  )}
                >
                  <div className="w-px bg-gray-200" />
                </div>
                {activityItem.type === 'commented' ? (
                  <>
                    <img
                      src={activityItem.person.imageUrl}
                      alt=""
                      className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                    />
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">{activityItem.person.name}</span> commented
                        </div>
                        <time
                          dateTime={activityItem.dateTime}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {activityItem.date}
                        </time>
                      </div>
                      <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
                      {activityItem.type === 'paid' ? (
                        <CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                      ) : (
                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                      )}
                    </div>
                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">{activityItem.person.name}</span>{' '}
                      {activityItem.type} the invoice.
                    </p>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </>
                )}
              </li>
            ))}
          </ul> */}

        {/* New comment form */}
        {/* <div className="mt-6 flex gap-x-3">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
              className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
            <form action="#" className="relative flex-auto">
              <div className="overflow-hidden rounded pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="comment" className="sr-only">
                  Add your comment
                </label>
                <textarea
                  rows={2}
                  name="comment"
                  id="comment"
                  className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Add your comment..."
                  defaultValue={''}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    >
                      <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Attach a file</span>
                    </button>
                  </div>
                  <div className="flex items-center">
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="sr-only">Your mood</Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                              <span className="flex items-center justify-center">
                                {selected.value === null ? (
                                  <span>
                                    <FaceSmileIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                    <span className="sr-only">Add your mood</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span
                                      className={classNames(
                                        selected.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                      )}
                                    >
                                      <selected.icon
                                        className="h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="sr-only">{selected.name}</span>
                                  </span>
                                )}
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 -ml-6 mt-1 w-60 rounded bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                {moods.map((mood) => (
                                  <Listbox.Option
                                    key={mood.value}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'bg-gray-100' : 'bg-white',
                                        'relative cursor-default select-none px-3 py-2'
                                      )
                                    }
                                    value={mood}
                                  >
                                    <div className="flex items-center">
                                      <div
                                        className={classNames(
                                          mood.bgColor,
                                          'flex h-8 w-8 items-center justify-center rounded-full'
                                        )}
                                      >
                                        <mood.icon
                                          className={classNames(mood.iconColor, 'h-5 w-5 flex-shrink-0')}
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                    </div>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
                <button
                  type="submit"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Comment
                </button>
              </div>
            </form>
          </div> */}

        {/* </div> */}
      </div>

      {withPermissions([
        '*:*',
        'admission-cycles:*',
        'admission-cycles:update',
      ])(
        <UpdateAdmissionCycleContainer
          open={modal === 'configure'}
          setOpen={(val: boolean) => setModal(val ? 'configure' : undefined)}
          refetch={refetch}
        />,
      )}
    </div>
  );
};

export default AdmissionCyclePage;
