import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionCycleForm } from 'components';
import lodash from 'lodash';
import { AdmissionCycleFormSchema } from 'components/forms/admission-cycle/schema';

const CREATE_ADMISSION_CYCLE = gql`
  mutation CreateAdmissionCycle($input: AdmissionCycleInput!) {
    createAdmissionCycle(input: $input) {
      _id
    }
  }
`;

export default function CreateAdmissionCycleContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createAdmissionCycle, { loading }] = useMutation(
    CREATE_ADMISSION_CYCLE,
  );
  const form = useFormik({
    initialValues: {
      code: '',
      name: '',
      noticeToApplicants: '',
      openingDate: '',
      description: '',
      closingDate: '',
      supplementaryDocuments: '',
      generalEntryRequirements: '',
      academicCalendar: {
        matriculationDate: '',
        openingDate: '',
        orientationDate: '',
        year: '',
      },
      schoolFeesDates: {
        commitmentFeeDate: '',
        outstandingFeesDate: '',
        provisionalRegistrationDate: '',
      },
      signatures: {
        undergraduateName: '',
        undergraduatePortfolio: '',
        undergraduateUrl: '',
        graduateName: '',
        graduatePortfolio: '',
        graduateUrl: '',
      },
    },
    validationSchema: AdmissionCycleFormSchema,
    onSubmit: async values => {
      await createAdmissionCycle({
        variables: {
          input: {
            ...lodash.omit(values, ['code']),
            supplementaryDocuments: [values?.supplementaryDocuments],
          },
        },
      }).then(({ data }) => {
        if (data.createAdmissionCycle._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Admission Cycle Created Successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Admission Cycle',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => (open ? form.resetForm() : setOpen(true))}
      title="Add New Admission Cycle"
      description="Provide the details to add a new admission cycle"
      size="4xl"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? 'Adding Admission Cycle...' : 'Add Admission Cycle'}
          </button>
        </>
      )}
    >
      <AdmissionCycleForm form={form} />
    </Modal>
  );
}
