import { classNames, useUrlState, wrapClick } from 'utils';

import { FC } from 'react';
import _ from 'lodash';
import config from 'config';
import numeral from 'numeral';

interface TableFooterComponentProps<TData = any> {
  data: {
    rows: TData[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  noBorder?: boolean;
}

const TableFooterComponent: FC<TableFooterComponentProps> = ({
  data,
  noBorder,
}) => {
  const [page, setPage] = useUrlState('page');
  const [pageSize] = useUrlState('pageSize');
  const setNextPage = () => setPage((page || config.constants.pageSize) + 1);
  const setPreviousPage = () =>
    setPage((page || config.constants.pageSize) - 1);
  const nextEnabled =
    (page || config.constants.pageSize) < (data?.totalPages || 0);
  const previousEnabled = (page || config.constants.pageSize) > 1;
  const startIndex =
    ((data?.page ?? (page || config.constants.page)) - 1) *
      (data?.pageSize ?? (pageSize || config.constants.pageSize)) +
    1;
  const endIndex = _.min([
    (data?.page ?? (page || config.constants.page)) *
      (data?.pageSize ?? (pageSize || config.constants.pageSize)),
    data?.total || Number.MAX_SAFE_INTEGER,
  ]);

  return (
    <nav
      className={classNames(
        noBorder ? 'border-0' : 'border',
        'flex items-center justify-between border-gray-200 bg-white px-4 py-3  dark:border-gray-700 dark:bg-gray-800 sm:rounded sm:px-6',
        'w-full flex-shrink-0 justify-self-end',
      )}
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700 dark:text-gray-200">
          {data?.total > 0 ? (
            <>
              Showing <span className="font-medium">{startIndex}</span> to{' '}
              <span className="font-medium">{endIndex}</span> of{' '}
              <span className="font-medium">
                {numeral(data.total).format('0,0')}
              </span>
            </>
          ) : (
            'No'
          )}{' '}
          results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          type="button"
          disabled={!previousEnabled}
          onClick={wrapClick(setPreviousPage)}
          className={classNames(
            previousEnabled
              ? 'cursor-pointer bg-white hover:bg-gray-50 dark:bg-gray-800 hover:dark:bg-gray-900'
              : 'cursor-not-allowed bg-gray-100 dark:bg-gray-900',
            'relative inline-flex items-center rounded border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 dark:border-gray-600 dark:text-gray-200',
          )}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={!nextEnabled}
          onClick={wrapClick(setNextPage)}
          className={classNames(
            nextEnabled
              ? 'cursor-pointer  bg-white hover:bg-gray-50 dark:bg-gray-800 hover:dark:bg-gray-900'
              : 'cursor-not-allowed bg-gray-100 dark:bg-gray-900',
            'relative ml-3 inline-flex items-center rounded border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 dark:border-gray-600 dark:text-gray-200',
          )}
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default TableFooterComponent;
