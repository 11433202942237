import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { currentUserVar } from 'apollo/cache/auth';

interface SchoolPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  filter?: {
    _id?: {
      in?: string[];
    };
  };
}

const GET_SCHOOLS = gql`
  query GetSchoolsSelect($filter: SchoolFilter) {
    schools: getSchools(filter: $filter, pagination: { skip: 0, limit: 0 }) {
      _id
      code
      name
    }
  }
`;

const SchoolPickerContainer: FC<SchoolPickerContainerProps> = ({
  id,
  label,
  rawId,
  filter,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_SCHOOLS, {
    variables: {
      filter: {
        ...(filter?._id?.in?.length && { _id: { in: filter?._id?.in || [] } }),
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (currentUser?.department) {
      form?.setFieldValue?.(
        id,
        rawId
          ? currentUser.department?._id
          : {
              _id: currentUser.department._id,
              code: currentUser.department.code,
              name: currentUser.department.name,
            },
      );
    }
  }, [currentUser]);

  return (
    <SearchSelectInput
      id={id ?? 'school'}
      label={label ?? 'School'}
      placeholder="Select School"
      optionsLoading={loading}
      options={(data?.schools ?? [])?.map((school: any) => ({
        label: {
          title: school.name as string,
        },
        value: rawId ? school?._id : school,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default SchoolPickerContainer;
