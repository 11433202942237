import { gql, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useUrlState } from 'utils';
import { currentUserVar } from 'apollo/cache/auth';
import { OverviewContainer } from './containers';
import ApplicationSchoolChartContainer from 'containers/applications-school-chart';
import ApplicationCampusChartContainer from 'containers/applications-bar-campus';
import ApplicationsDayBarChartContainer from '../../containers/applications-level-bar';
import ApplicationsLevelChartContainer from './containers/applications-level-pie';
import ApplicationDepartmentCirclesChartContainer from 'containers/applications-department-chart';
import ShortlistedDayBarChartContainer from 'containers/shortlisted-level-bar';
import ShortlistedLevelChartContainer from './containers/shortlisted-level-pie';
import ShortlistSchoolChartContainer from 'containers/shortlisted-school-chart';
import ShortlistedDepartmentCirclesChartContainer from 'containers/shortlisted-department-chart';
import ShortlistedCampusChartContainer from 'containers/shortlisted-bar-campus';
import AdmittedDayBarChartContainer from 'containers/admitted-level-bar';
import AdmittedLevelChartContainer from './containers/admitted-level-pie';
import AdmittedSchoolChartContainer from 'containers/admitted-school-chart';
import AdmittedDepartmentCirclesChartContainer from 'containers/admitted-department-chart';
import AdmittedCampusChartContainer from 'containers/admitted-bar-campus';

const ReportingPage: FC = () => {
  const [cycle] = useUrlState('cycle');
  const [entity] = useUrlState('entity');
  const [fromDate] = useUrlState('fromDate');
  const [toDate] = useUrlState('toDate');
  const currentUser = useReactiveVar(currentUserVar);

  const range =
    dayjs(fromDate).isValid() && dayjs(toDate).isValid()
      ? {
          start: toDate,
          end: fromDate,
        }
      : { start: '', end: '' };

  return (
    <div className="mx-auto min-h-full flex-1  overflow-y-auto bg-gray-50 p-4 sm:p-6">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
        <div className="col-span-12">
          <OverviewContainer />
        </div>

        <div className="col-span-12 w-full">
          <div className="grid h-[400px] w-full grid-cols-12 gap-4">
            <div className="col-span-8 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              {entity === 'Admitted' ? (
                <AdmittedDayBarChartContainer cycle={cycle} dates={range} />
              ) : entity === 'Shortlisted' ? (
                <ShortlistedDayBarChartContainer cycle={cycle} dates={range} />
              ) : (
                <ApplicationsDayBarChartContainer cycle={cycle} dates={range} />
              )}
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              {entity === 'Admitted' ? (
                <AdmittedLevelChartContainer cycle={cycle} />
              ) : entity === 'Shortlisted' ? (
                <ShortlistedLevelChartContainer cycle={cycle} />
              ) : (
                <ApplicationsLevelChartContainer cycle={cycle} />
              )}
            </div>
          </div>
        </div>

        <div className="col-span-12 w-full">
          <div className="grid h-[400px] w-full grid-cols-12 gap-4">
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              {entity === 'Admitted' ? (
                <AdmittedSchoolChartContainer cycle={cycle} />
              ) : entity === 'Shortlisted' ? (
                <ShortlistSchoolChartContainer cycle={cycle} />
              ) : (
                <ApplicationSchoolChartContainer cycle={cycle} />
              )}
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              {entity === 'Admitted' ? (
                <AdmittedDepartmentCirclesChartContainer cycle={cycle} />
              ) : entity === 'Shortlisted' ? (
                <ShortlistedDepartmentCirclesChartContainer cycle={cycle} />
              ) : (
                <ApplicationDepartmentCirclesChartContainer cycle={cycle} />
              )}
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              {entity === 'Admitted' ? (
                <AdmittedCampusChartContainer cycle={cycle} />
              ) : entity === 'Shortlisted' ? (
                <ShortlistedCampusChartContainer cycle={cycle} />
              ) : (
                <ApplicationCampusChartContainer cycle={cycle} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingPage;
