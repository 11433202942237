import { Fragment, useCallback, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
// import toast from "react-hot-toast";
import { classNames } from 'utils';

export default function ImportDataContainer({
  open,
  setOpen,
  entityType,
  onSubmit,
  loading,
}: {
  open: boolean;
  setOpen: any;
  entityType: string;
  onSubmit?: (value: FormData) => any;
  loading?: boolean;
}) {
  const isLoading = loading;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [file, setFile] = useState<{ [key: string]: any } | null>(null);

  const processData = (dataString: string) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      if (headers && row.length === headers.length) {
        const obj: { [key: string]: string } = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    console.log('done processing...');
    setData(list as any);
    setColumns(headers as any);
  };

  const onDrop = useCallback((acceptedFiles: any[]) => {
    // Do something with the files
    const file = acceptedFiles[0];
    setFile(file);
    console.log('starting processing...');
    const reader = new FileReader();
    reader.onload = evt => {
      /* Parse data */
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 } as any);
      console.log('stoping processing...');
      processData(data);
    };
    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/json': ['.json'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls'],
    },
  });

  const __setOpen = (__open: boolean) => {
    setData([]);
    setColumns([]);
    setOpen(__open);
  };

  const __submit = async (e: any) => {
    e.preventDefault();
    const data = new FormData();
    data.append('file', file as any, file?.name as any);
    // send data to database here
    onSubmit?.(data);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={__setOpen}
      >
        <div className="flex h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block h-[85vh] transform overflow-hidden rounded bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle">
              <div className="flex h-full flex-col">
                <div className="flex-shrink-0 border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                  <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Upload Preview
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Preview of {entityType} to be uploaded
                      </p>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                      <button
                        type="button"
                        {...getRootProps()}
                        className="relative inline-flex items-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      >
                        Choose File
                        <input
                          {...getInputProps()}
                          id="file"
                          name="file"
                          type="file"
                          className="sr-only"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1 overflow-x-auto  overflow-y-scroll bg-white">
                  {acceptedFiles.length < 1 ? (
                    <div className="inset-0  h-full p-6">
                      <div className="flex h-full items-center justify-center rounded border-2 border-dashed border-gray-200">
                        <div className="text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              vectorEffect="non-scaling-stroke"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                          </svg>
                          <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No file selected
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Kindly select a file by clicking the button above.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="h-full min-w-full divide-y divide-gray-200 bg-green-500">
                      <thead className="sticky top-0 z-10 bg-gray-50">
                        <tr>
                          {columns
                            ?.filter((_c: string) => _c.length > 0)
                            ?.map((column: any, idx) => (
                              <th
                                key={idx}
                                scope="col"
                                className={classNames(
                                  idx === 0 ? 'sticky left-0 bg-gray-50' : '',
                                  'whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
                                )}
                              >
                                {column}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {data?.map((datum: any, id) => (
                          <tr key={id}>
                            {columns
                              ?.filter((_c: string) => _c.length > 0)
                              ?.map((column: any, idx) => (
                                <td
                                  key={idx}
                                  className={classNames(
                                    idx === 0 ? 'sticky left-0 bg-white' : '',
                                    'whitespace-nowrap px-6 py-4 text-sm text-gray-500',
                                  )}
                                >
                                  {datum[column]}
                                </td>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="flex-shrink-0 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={isLoading}
                    className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={__submit}
                  >
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => __setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
