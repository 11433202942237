import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useMemo } from 'react';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import { MoonLoader } from 'react-spinners';
import { currentConfigVar } from 'apollo/cache/config';
import BarChart from 'components/charts/bar-chart';
import { getRandomHexColor } from 'utils/get-random-color';
import numeral from 'numeral';
dayjs.extend(weekOfYear);

interface ShortlistedCampusChartContainerProps {
  cycle?: string;
}

const GET_APPLICATIONS_STATS = gql`
  query Query($fields: [String], $filter: CountByCampusFilter) {
    countApplicationsByCampus(fields: $fields, filter: $filter)
  }
`;
const GET_SHORTLISTED_STATS = gql`
  query Query($filter: CountByCampusFilter) {
    countShortlistedByCampus(filter: $filter)
  }
`;

const ShortlistedCampusChartContainer: FC<
  ShortlistedCampusChartContainerProps
> = ({ cycle }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && { cycle: { eq: cycle } };

  const { loading, data } = useQuery(GET_SHORTLISTED_STATS, {
    variables: {
      filter: {
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval,
  });

  const { loading: loadingApplication, data: applicationData } = useQuery(
    GET_APPLICATIONS_STATS,
    {
      variables: {
        filter: {
          ...cycleFilters,
        },
      },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'no-cache',
      pollInterval,
    },
  );

  const colors = [
    '#5E95FC',
    '#27d163',
    '#FFD36C',
    '#F8649F',
    '#72DBDB',
    '#A955A7',
  ].reverse();

  const shortlistedColors = [
    '#2c74fb',
    '#1fa64f',
    '#ffc439',
    '#f63381',
    '#49d1d1',
    '#874485',
  ].reverse();

  const barDataApplications = applicationData?.countApplicationsByCampus
    ?.sort((a: any, b: any) => b?.count - a?.count)
    ?.map((school: any, index: number) => ({
      school: school?.name,
      [`applications`]: school?.count,
      [`applicationsColor`]: colors?.[index] || getRandomHexColor(),
    }));

  const barData = data?.countShortlistedByCampus?.map(
    (school: any, index: number) => ({
      school: school?.name,
      [`shortlisted`]: school?.count,
      [`shortlistedColor`]: shortlistedColors?.[index] || getRandomHexColor(),
    }),
  );

  const percentageShortlisted = useMemo(() => {
    const totalShortlisted = barData?.reduce(
      (acc: number, cur: any) => (cur?.shortlisted || 0) + acc,
      0,
    );

    const totalApplications = barDataApplications?.reduce(
      (acc: number, cur: any) => (cur?.applications || 0) + acc,
      0,
    );

    const percentage = (totalShortlisted / totalApplications) * 100;

    return percentage || undefined;
  }, [barData, barDataApplications]);

  const mergedBarData = useMemo(() => {
    return barDataApplications?.map((data: any, index: number) => {
      const foundShortlisted = barData?.find(
        (shortlistedData: any) => shortlistedData.school === data?.school,
      );

      const totalCampusCount =
        data?.applications + foundShortlisted?.shortlisted;

      const totalApplicationPercent =
        (data?.applications / totalCampusCount) * 100;

      const totalShortlistPercent =
        (foundShortlisted?.shortlisted / totalCampusCount) * 100;

      return {
        ...data,
        applications: numeral(totalApplicationPercent).format('0.00'),
        ...{
          ...foundShortlisted,
          shortlisted: numeral(totalShortlistPercent).format('0.00'),
          [`shortlistedColor`]:
            shortlistedColors?.[index] || getRandomHexColor(),
        },
      };
    });
  }, [barDataApplications, barData]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col self-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Campuses
          </h3>
          <p className="text-xs text-gray-400">
            Displays percentage shortlisted by campus
          </p>
        </div>

        <div>
          <div className="flex flex-col">
            <span className="self-end text-3xl text-gray-600">
              {numeral(percentageShortlisted).format('0')}%
            </span>
            <p className="text-xs text-gray-400">Of overall shortlisted</p>
          </div>
          {loading && <MoonLoader size={20} />}
        </div>
      </div>

      <BarChart
        keys={['applications', 'shortlisted']}
        indexBy="school"
        xLabel="Campuses"
        yLabel="No. of Applicants"
        layout="vertical"
        data={mergedBarData || []}
        tooltipLabel={data => `${data?.indexValue} (${data?.id})`}
        chartLabel={data => `${data?.value}%`}
      />
    </>
  );
};

export default ShortlistedCampusChartContainer;
