import * as Yup from 'yup';

export const phoneNumberValidation = () => {
  return Yup.string()
    .required('Phone Number is a required field')
    .test('len', 'Phone Number is invalid', function (value) {
      if (!value) return true;
      const length = value.length;
      if (value.startsWith('0')) {
        return length === 10;
      } else if (value.startsWith('+')) {
        const afterPlus = value.charAt(1);
        return length >= 11 && length <= 13 && afterPlus !== '0';
      } else {
        return false;
      }
    });
};

export const AdminSchema = Yup.object().shape({
  code: Yup.string()
    .length(6, 'Staff ID must be exactly 6 characters')
    .required('Staff ID is required'),
  lastName: Yup.string()
    .min(3, 'LastName must be at least 3 characters')
    .required('Lastname is required'),
  firstName: Yup.string()
    .min(3, 'Firstname must be at least 3 characters')
    .required('Firstname is required'),
  ghanaCardNumber: Yup.string()
    .length(15, 'Ghana Card Number must be exactly 15 characters')
    .matches(/([A-Z]){3}-\d{9}-\d{1}/g, 'Enter a valid ghana card number')
    .required('Ghana Card Number is required'),
  phoneNumber: phoneNumberValidation(),
  emailAddress: Yup.string()
    .email('Enter a valid email address')
    .required('Email address is required'),
  gender: Yup.string().oneOf(['Male', 'Female']).required('Gender is required'),
  profileImageUrl: Yup.string().url().notRequired(),
});

export const AdminInitialValues = {
  code: '',
  lastName: '',
  firstName: '',
  ghanaCardNumber: '',
  phoneNumber: '',
  emailAddress: '',
  gender: 'Male',
  profileImageUrl: '',
};
