import { useReactiveVar } from '@apollo/client';
import {
  DateFormats,
  DefaultDateFormat,
  DefaultLanguage,
  DefaultNumberFormat,
  DefaultPollingInterval,
  Languages,
  NumberFormats,
  PollingIntervals,
  Theme,
  currentConfigVar,
  setConfig,
} from 'apollo/cache/config';
import { SelectInput } from 'components/core';
import { useFormik } from 'formik';
import { FC } from 'react';
import toast from 'react-hot-toast';

const SettingsPersonalizationContainer: FC = () => {
  const currentConfig = useReactiveVar(currentConfigVar);

  const form = useFormik({
    initialValues: {
      dateFormat: DefaultDateFormat,
      language: DefaultLanguage,
      pollInterval: DefaultPollingInterval,
      moneyFormat: DefaultNumberFormat,
      theme: Theme.Light,
      ...currentConfig,
    },
    onSubmit: values => {
      setConfig(values);
      toast(
        JSON.stringify({
          type: 'success',
          title: 'Settings Updated Successfully',
        }),
      );
    },
  });

  return (
    <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
      <div className="overflow-hidden shadow sm:rounded">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <SelectInput
                id="dateFormat"
                label="Date Format"
                options={[
                  { label: '--- Select Format ---', value: '' },
                  ...DateFormats,
                ]}
                {...form}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <SelectInput
                id="pollInterval"
                label="Refresh Interval"
                options={[
                  { label: '--- Select Interval ---', value: '' },
                  ...PollingIntervals,
                ]}
                {...form}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <SelectInput
                id="moneyFormat"
                label="Money Precision"
                options={[
                  { label: '--- Select Precision ---', value: '' },
                  ...NumberFormats,
                ]}
                {...form}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <SelectInput
                id="language"
                label="Language"
                options={[
                  { label: '--- Select Language ---', value: '' },
                  ...Languages,
                ]}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="space-x-3 bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="reset"
            className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default SettingsPersonalizationContainer;
