import { gql, useQuery } from '@apollo/client';
import { AdmissionCyclePicker, AdmissionProgrammePicker } from 'containers';

import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';

interface AdmissionTransferFormProps {
  form: FormikProps<{
    destinationAdmissionProgrammeId: string;
    destinationCycleId: string;
    sourceAdmissionProgrammeId: string;
    sourceCycleId: string;
  }>;
}

const GET_ADMISSION_PROGRAMME = gql`
  query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!) {
    programme: getAdmissionProgramme(filter: $filter) {
      _id
      code
      name
      programme {
        _id
      }
    }
  }
`;

const AdmissionTransferSingleForm: FC<AdmissionTransferFormProps> = ({
  form,
}) => {
  const { loading, data } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        ...(form?.values?.sourceCycleId
          ? {
              cycle: {
                eq: form?.values?.sourceCycleId || '',
              },
            }
          : {}),
        ...(form?.values?.sourceAdmissionProgrammeId
          ? {
              _id: {
                eq: form?.values?.sourceAdmissionProgrammeId || '',
              },
            }
          : {}),
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
  });

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <AdmissionCyclePicker
              id="sourceCycleId"
              label="Source Cycle"
              rawId={true}
              disabled
              {...form}
            />
          </div>

          <div>
            <AdmissionProgrammePicker
              id="sourceAdmissionProgrammeId"
              label="Source Programme"
              rawId={true}
              disabled
              filter={{
                cycle: {
                  eq: form.values?.sourceCycleId || '',
                },
              }}
              {...form}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <AdmissionCyclePicker
              id="destinationCycleId"
              label="Destination Cycle"
              rawId={true}
              filter={{
                _id: {
                  notEq: form.values.sourceCycleId,
                },
                closingDate: {
                  after: new Date().toISOString(),
                },
              }}
              {...form}
            />
          </div>

          {form?.values?.destinationCycleId &&
            form?.values?.sourceAdmissionProgrammeId && (
              <div>
                <AdmissionProgrammePicker
                  id="destinationAdmissionProgrammeId"
                  label="Destination Programme"
                  rawId={true}
                  filter={{
                    ...(form?.values?.destinationCycleId
                      ? {
                          cycle: {
                            eq: form?.values?.destinationCycleId || '',
                          },
                        }
                      : {}),
                    ...(data?.programme?.programme?._id
                      ? {
                          programme: {
                            eq: data?.programme?.programme?._id || '',
                          },
                        }
                      : {}),
                  }}
                  disabled={loading}
                  {...form}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionTransferSingleForm;
