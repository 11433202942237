import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, TuitionForm } from 'components';
import {
  TuitionInitialValues,
  TuitionSchema,
} from 'components/forms/tuition/schema';
import { FeesType } from 'components/forms/tuition';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';

const CREATE_TUITION = gql`
  mutation CreateTuition($input: TuitionInput!) {
    createTuition(input: $input) {
      _id
    }
  }
`;

export default function CreateTuitionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createTuition, { loading }] = useMutation(CREATE_TUITION);

  const { params } = useMatch<LocationGenerics>();

  const form = useFormik({
    initialValues: TuitionInitialValues,
    validationSchema: TuitionSchema,
    onSubmit: async ({ code, ...values }) => {
      console.log({ datavalues: values });
      await createTuition({
        variables: {
          input: {
            cycle: params?.admissionCycle,
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data.createTuition._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Tuition created successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Tuition',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Tuition"
      description="Provide the details to add a new tuition"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? 'Adding Tuition...' : 'Add Tuition'}
          </button>
        </>
      )}
    >
      <TuitionForm form={form} />
    </Modal>
  );
}
