import { FC, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { currentUserVar } from 'apollo/cache/auth';
import { AdmissionLetterTemplate } from 'components/cards/admission-letter-view';
import { wrapClick } from 'utils';

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  steps: any[];
  values: any;
}

const SummaryPage: FC<SummaryPageProps> = ({
  values,
  handlePrevious,
  handleStep,
  submitLoading,
  steps,
  ...form
}) => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const printRef = useRef<any>();

  const variables = {
    organizationLogoUrl: '',
    organizationName:
      'GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION [GIMPA]',
    admissionLetterTitle: values?.generalInfo?.title,
    signatureUrl: values?.generalInfo?.signatureUrl,
    signatureName: values?.generalInfo?.signatureName,
    signaturePortfolio: values?.generalInfo?.signaturePortfolio,
    applicantImage:
      'https://onyx-studio-test.s3.us-west-2.amazonaws.com/1732768749662',
    applicantName: 'APPLICANT NAME',
    applicantPostalAddress: 'APPLICANT POSTAL ADDRESS',
    applicantAddress: 'APPLICANT ADDRESS',
    admissionDate: '2020-10-10',
    address: values?.generalInfo?.address,
    postalAddress: values?.generalInfo?.postalAddress,
  };

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex flex-1 flex-col overflow-hidden"
    >
      <div className="flex-1 space-y-6 divide-y divide-gray-200 overflow-y-auto p-6">
        <AdmissionLetterTemplate
          printRef={printRef}
          variables={variables}
          pointers={values?.pointersInfo?.pointers}
        />
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
        <button
          type="submit"
          disabled={submitLoading}
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {submitLoading ? 'Finishing...' : 'Finish'}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;
