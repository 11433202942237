import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { AdmissionLetterConfigForm, Modal } from 'components';

const CREATE_AdmissionLetter = gql`
  mutation CreateAdmissionLetter($input: AdmissionLetterInput!) {
    createAdmissionLetter(input: $input) {
      _id
      code
      createdAt
    }
  }
`;

export default function CreateAdmissionLetterContainer({
  open,
  setOpen,
  refetch,
  admissionletter,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  admissionletter?: string;
}) {
  const [createAdmissionLetter, { loading }] = useMutation(
    CREATE_AdmissionLetter,
  );

  const { params } = useMatch<LocationGenerics>();

  const form = useFormik({
    initialValues: {
      generalInfo: {
        title: '',
        name: '',
        signatureName: '',
        signaturePortfolio: '',
        signatureUrl: '',
        address: '',
        postalAddress: '',
      },
      pointersInfo: {
        pointers: [] as any,
      },
    },
    onSubmit: async ({ ...values }) => {
      await createAdmissionLetter({
        variables: {
          input: {
            ...values?.generalInfo,
            ...values?.pointersInfo,
            admissionCycleId: params.admissionCycle,
          },
        },
      }).then(({ data }) => {
        if (data.createAdmissionLetter._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Admission Letter Created Successfully',
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not create Admission Letter',
            }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => setOpen(false)}
      title="Create Admission Letter"
      description="Provide the details for the admission letter"
      size="5xl"
      loading={loading}
      hideActions={true}
    >
      <AdmissionLetterConfigForm form={form} isCompleted={false} />
    </Modal>
  );
}
