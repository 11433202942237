import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { currentUserVar, setMe } from 'apollo/cache/auth';
import axios from 'axios';
import { TextInput, SelectInput, AvatarUpload, Avatar } from 'components/core';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { classNames } from 'utils';

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: ID!, $input: UserInput!) {
    updateUser(id: $updateUserId, input: $input) {
      _id
    }
  }
`;

const SettingsPersonalContainer: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  const form = useFormik({
    initialValues: {
      name: '',
      profileImageUrl: '',
      email: '',
      phone: '',
      role: '' as any,
    },
    onSubmit: async values => {
      await updateUser({
        variables: {
          updateUserId: currentUser?._id,
          input: {
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data.updateUser._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Profile Edited Successfully',
            }),
          );
          setMe({
            ...currentUser,
            ...values,
          });
        } else {
          toast(
            JSON.stringify({
              type: 'error',
              title: 'Could not update Profile',
            }),
          );
        }
      });
    },
  });

  useEffect(() => {
    form.setValues({
      name: currentUser?.name || '',
      profileImageUrl: currentUser?.profileImageUrl || '',
      email: currentUser?.email || '',
      phone: currentUser?.phone || '',
      role: currentUser?.role || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  console.log({ dsag: currentUser?.school });

  return (
    <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
      <div className="overflow-hidden shadow sm:rounded">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-2 gap-6">
            <div className="">
              <AvatarUpload id="profileImageUrl" label="Photo" {...form} />
            </div>
            <div />
            <div>
              <TextInput
                id="staffId"
                label="Staff ID"
                type="text"
                placeholder="User Code"
                disabled={true}
                values={currentUser}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>

            <div></div>

            <div>
              <TextInput
                id="name"
                label="Name"
                type="text"
                placeholder="e.g. Enoch Nana Nyankah"
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="phone"
                label="Phone Number"
                type="text"
                placeholder="e.g. 0550123292"
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="email"
                label="Email Address"
                type="email"
                placeholder="e.g. nyankahmensah@gmail.com"
                disabled={true}
                values={currentUser}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>
            {/* <div>
              <SelectInput
                id="gender"
                label="Gender"
                options={["MALE", "FEMALE"]}
                placeholder="e.g. Male"
                disabled={true}
                {...form}
              />
            </div> */}

            <div>
              <TextInput
                id="role"
                label="Role"
                type="email"
                placeholder="e.g. DEAN"
                disabled={true}
                values={currentUser}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>

            {currentUser?.school && (
              <div>
                <TextInput
                  id="school.name"
                  label="School"
                  type="text"
                  placeholder="School Name"
                  disabled={true}
                  values={currentUser}
                  handleChange={undefined}
                  handleBlur={undefined}
                />
              </div>
            )}

            {currentUser.department && (
              <div>
                <TextInput
                  id="department.name"
                  label="School"
                  type="text"
                  placeholder="School Name"
                  disabled={true}
                  values={currentUser}
                  handleChange={undefined}
                  handleBlur={undefined}
                />
              </div>
            )}
          </div>
        </div>

        <div className="space-x-3 bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="reset"
            className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SettingsPersonalContainer;
