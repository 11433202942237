import { FC } from 'react';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { OverviewContainer } from './containers';
import { useReactiveVar } from '@apollo/client';
import { currentUserVar } from 'apollo/cache/auth';
import ApplicantsBarChartContainer from 'containers/applicants-bar-chart';
import ApplicantsLevelChartContainer from 'containers/applications-pie-chart';
import ApplicationSchoolChartContainer from 'containers/applications-school-chart';
import ApplicationCampusChartContainer from 'containers/applications-bar-campus';
import InvoiceChartContainer from 'containers/invoices-chart';

const DashboardPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();

  const cycle = searchParams?.cycle;

  return (
    <div className="mx-auto min-h-full flex-1  overflow-y-auto bg-gray-50 p-4 sm:p-6">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
        <div className="col-span-12">
          <OverviewContainer />
        </div>

        <div className="col-span-12 w-full">
          <div className="grid h-[400px] w-full grid-cols-12 gap-4">
            <div className="col-span-8 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              <ApplicantsBarChartContainer
                cycle={cycle}
                dates={{ start: '', end: '' }}
              />
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              <ApplicantsLevelChartContainer />
            </div>
          </div>
        </div>

        <div className="col-span-12 w-full">
          <div className="grid h-[400px] w-full grid-cols-12 gap-4">
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              <ApplicationSchoolChartContainer cycle={cycle} />
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              <ApplicationCampusChartContainer cycle={cycle} />
            </div>
            <div className="col-span-4 flex h-full w-full flex-col rounded bg-white p-5 shadow">
              <InvoiceChartContainer cycleId={cycle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
