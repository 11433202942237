import {
  AcademicCapIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnRightIcon,
  BanknotesIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  ChartBarSquareIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog8ToothIcon,
  DocumentTextIcon,
  LifebuoyIcon,
  MapIcon,
  PlusIcon,
  PresentationChartBarIcon,
  Square3Stack3DIcon,
  TicketIcon,
  UserCircleIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import {
  AdmissionCyclePage,
  AdmissionCyclesPage,
  AdmissionProgrammesPage,
  ApplicationPage,
  ApplicationsPage,
  CampusesPage,
  DashboardPage,
  DepartmentsPage,
  ForgotPasswordPage,
  InvoicesPage,
  MailingPage,
  ProgrammesPage,
  ReportingPage,
  SchoolsPage,
  SettingsPage,
  SigninPage,
  SupportPage,
  TuitionPage,
  UsersPage,
  VendorsPage,
  VoucherClassesPage,
} from 'pages';
import { Outlet, Route } from 'react-location';
import { UserPermission, initiateClearAuth } from 'apollo/cache/auth';

import { AdmissionCycleBreadcrumb } from 'containers';
import AdmissionCycleLayout from 'layouts/admission-cycle';
import AdmissionsPage from 'pages/admissions';
import AnalyticsLayout from 'layouts/analytics-wrapper';
import ApplicationDetailsContainerRaw from 'pages/application/raw';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import CalendarLayout from 'layouts/calendar-wrapper';
import EnrollmentPage from 'pages/enrolled';
import ShortlistedPage from 'pages/shortlisted';
import { AdmissionLettersPage } from 'pages/admission-letters';

export type RouteProps = Omit<Route, 'children'> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: {
    level: 'primary' | 'secondary';
    label: string;
    icon: any;
    onClick?: () => void;
  };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: '/',
    element: <CalendarLayout />,
    sidebar: {
      level: 'primary',
      label: 'Dashboard',
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Dashboard',
      section: 'General',
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <DashboardPage />,
        meta: {
          layout: 'App',
          section: 'General',
        },
      },
    ],
  },
  {
    path: 'admission-cycles',
    element: <Outlet />,
    sidebar: {
      level: 'primary',
      label: 'Admissions',
      icon: Square3Stack3DIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Admission Cycles',
      section: 'Structure',
    },
    children: [
      {
        path: '/',
        element: <AdmissionCyclesPage />,
        meta: {
          layout: 'App',
          section: 'General',
        },
      },
      {
        path: ':admissionCycle',
        element: <AdmissionCycleLayout />,
        meta: {
          layout: 'App',
          breadcrumb: ({ admissionCycle }: { admissionCycle: string }) => {
            return <AdmissionCycleBreadcrumb id={admissionCycle} />;
          },
          section: 'General',
        },
        children: [
          {
            path: '/',
            element: <AdmissionCyclePage />,
            meta: {
              layout: 'App',
              section: 'General',
              name: 'Overview',
              actions: [
                {
                  icon: Cog8ToothIcon,
                  name: 'Configure Cycle',
                  action: 'configure',
                  permissions: [
                    '*:*',
                    'admission-cycles:*',
                    'admission-cycles:configure',
                  ],
                },
              ],
            },
            withPermissions: [
              '*:*',
              'admission-cycles:*',
              'admission-cycles:manage',
            ],
          },
          {
            path: '/classes',
            element: <VoucherClassesPage />,
            withPermissions: [
              '*:*',
              'voucher-classes:*',
              'voucher-classes:manage',
            ],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Classes',
              section: 'General',
              name: 'Classes',
              actions: [
                {
                  icon: PlusIcon,
                  name: 'Add Class',
                  action: 'create',
                  permissions: [
                    '*:*',
                    'voucher-classes:*',
                    'voucher-classes:create',
                  ],
                },
              ],
            },
          },
          {
            path: '/tuition',
            element: <TuitionPage />,
            withPermissions: ['*:*', 'tuition:*', 'tuition:manage'],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Tuition',
              section: 'General',
              name: 'Tuition',
              actions: [
                {
                  icon: PlusIcon,
                  name: 'Add Tuition Fees',
                  action: 'create',
                  permissions: ['*:*', 'tuition:*', 'tuition:create'],
                },
              ],
            },
          },
          {
            path: '/admission-letter',
            element: <AdmissionLettersPage />,
            withPermissions: [
              '*:*',
              'admission-letter:*',
              'admission-letter:manage',
            ],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Admission Letter',
              section: 'General',
              name: 'Admission Letter',
              actions: [
                {
                  icon: PlusIcon,
                  name: 'Add New Admission Letter',
                  action: 'create',
                  permissions: ['*:*', 'admissions:*', 'admissions:manage'],
                },
              ],
            },
          },
          {
            path: '/programmes',
            element: <Outlet />,
            meta: {
              layout: 'App',
              breadcrumb: () => 'Programmes',
              section: 'General',
              name: 'Programmes',
            },
            withPermissions: [
              '*:*',
              'admission-programmes:*',
              'admission-programmes:manage',
            ],
            children: [
              {
                path: '/',
                element: <AdmissionProgrammesPage />,
                meta: {
                  layout: 'App',
                  section: 'General',
                  actions: [
                    {
                      icon: PlusIcon,
                      name: 'Add Programme',
                      action: 'create',
                      permissions: [
                        '*:*',
                        'admission-programmes:*',
                        'admission-programmes:create',
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            path: '/applications',
            element: <Outlet />,
            withPermissions: ['*:*', 'application:*', 'application:manage'],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Applications',
              section: 'General',
              name: 'Applications',
            },
            children: [
              {
                path: '/',
                element: <ApplicationsPage />,
                meta: {
                  layout: 'App',
                  section: 'General',
                  actions: [
                    {
                      icon: ArrowUpTrayIcon,
                      name: 'Upload WAEC Grades',
                      action: 'upload',
                      permissions: [
                        '*:*',
                        'application:*',
                        'application:upload-grades',
                      ],
                      theme: 'green',
                    },
                    {
                      icon: Cog8ToothIcon,
                      name: 'Begin Shortlisting',
                      action: 'create',
                      permissions: [
                        '*:*',
                        'application:*',
                        'application:shortlist',
                      ],
                    },
                  ],
                },
              },
              {
                path: '/:application',
                element: <ApplicationPage />,
                meta: {
                  layout: 'App',
                  breadcrumb: () => 'Application',
                  section: 'General',
                },
              },
            ],
          },
          {
            path: '/shortlisted',
            element: <ShortlistedPage />,
            withPermissions: ['*:*', 'shortlist:*', 'shortlist:manage'],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Shortlisted',
              section: 'General',
              name: 'Shortlisted',
              actions: [
                {
                  icon: Cog8ToothIcon,
                  name: 'Begin Admissions',
                  action: 'create',
                  permissions: ['*:*', 'shortlist:*', 'shortlist:initiate'],
                },
              ],
            },
          },
          {
            path: '/admitted',
            element: <AdmissionsPage />,
            withPermissions: ['*:*', 'admissions:*', 'admissions:manage'],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Admissions',
              section: 'General',
              name: 'Admitted',
              actions: [
                {
                  icon: ArrowUpTrayIcon,
                  name: 'Upload Admission Data',
                  action: 'upload',
                  permissions: [
                    '*:*',
                    'admissions:*',
                    'admissions:upload-admissions',
                  ],
                  theme: 'green',
                },
                {
                  icon: UserCircleIcon,
                  name: 'Export Passports',
                  action: 'investigate',
                  permissions: ['*:*', 'admissions:*', 'admissions:manage'],
                },
                {
                  icon: ArrowUturnRightIcon,
                  name: 'Transfer Admissions',
                  action: 'transfer',
                  permissions: ['*:*', 'admissions:*', 'admissions:transfer'],
                },
              ],
            },
          },
          {
            path: '/accepted',
            element: <EnrollmentPage />,
            withPermissions: ['*:*', 'admissions:*', 'admissions:manage'],
            meta: {
              layout: 'App',
              breadcrumb: () => 'Accepted',
              section: 'General',
              name: 'Accepted',
              actions: [
                // {
                //   icon: UserPlusIcon,
                //   name: "Add Accepted",
                //   action: "assign",
                //   permissions: ["*:*", "enrollment:*", "enrollment:create"],
                // },
              ],
            },
          },
        ],
      },
    ],
    withPermissions: ['*:*', 'admission-cycles:*', 'admission-cycles:manage'],
  },
  {
    path: 'campuses',
    element: <CampusesPage />,
    sidebar: {
      level: 'primary',
      label: 'Campuses',
      icon: DocumentTextIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Campuses',
      section: 'General',
    },
    withPermissions: ['*:*', 'campuses:*', 'campuses:manage'],
  },
  {
    path: 'application-raw/:application',
    element: <ApplicationDetailsContainerRaw />,
    sidebar: {
      level: 'primary',
      label: 'Application Summary',
      icon: AcademicCapIcon,
    },
    meta: {
      layout: 'Print',
      breadcrumb: () => 'Application Summary Raw',
      section: 'Structure',
    },
    withPermissions: ['*:*'],
  },
  {
    path: 'departments',
    element: <DepartmentsPage />,
    sidebar: {
      level: 'primary',
      label: 'Departments',
      icon: AcademicCapIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Department Management',
      section: 'Structure',
    },
    withPermissions: ['*:*', 'departments:*', 'departments:manage'],
  },
  {
    path: 'programmes',
    element: <ProgrammesPage />,
    sidebar: {
      level: 'primary',
      label: 'Programmes',
      icon: Square3Stack3DIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Programmes Management',
      section: 'Structure',
    },
    withPermissions: ['*:*', 'programmes:*', 'programmes:manage'],
  },
  {
    path: 'schools',
    element: <SchoolsPage />,
    sidebar: {
      level: 'primary',
      label: 'Schools',
      icon: MapIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Schools Management',
      section: 'Structure',
    },
    withPermissions: ['*:*', 'schools:*', 'schools:manage'],
  },
  {
    path: 'users',
    element: <UsersPage />,
    sidebar: {
      level: 'primary',
      label: 'Users',
      icon: BriefcaseIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Users',
      section: 'Users & Profiles',
    },
    withPermissions: ['*:*', 'users:*', 'users:manage'],
  },
  {
    path: 'vendors',
    element: <VendorsPage />,
    sidebar: {
      level: 'primary',
      label: 'Vendors',
      icon: BuildingStorefrontIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Vendors',
      section: 'General',
    },
    withPermissions: ['*:*', 'vendors:*', 'vendors:manage'],
  },
  {
    path: 'invoices',
    element: <InvoicesPage />,
    sidebar: {
      level: 'primary',
      label: 'Invoices',
      icon: BanknotesIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Invoices',
      section: 'General',
    },
    withPermissions: ['*:*', 'invoices:*', 'invoices:manage'],
  },
  {
    path: 'analytics',
    element: <AnalyticsLayout />,
    sidebar: {
      level: 'primary',
      label: 'Analytics',
      icon: PresentationChartBarIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Reporting',
      section: 'General',
    },
    children: [
      {
        id: 'all',
        path: '/',
        element: <ReportingPage />,
        meta: {
          layout: 'App',
          section: 'General',
        },
      },
    ],
    withPermissions: ['*:*', 'reporting:*', 'reporting:manage'],
  },
  {
    path: 'mailing',
    element: <MailingPage />,
    sidebar: {
      level: 'secondary',
      label: 'Mailing',
      icon: ChatBubbleBottomCenterTextIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Mailing',
      section: 'Configuration',
    },
    withPermissions: ['*:*', 'mailing:*', 'mailing:manage'],
  },
  {
    path: 'settings',
    element: <SettingsPage />,
    sidebar: {
      level: 'secondary',
      label: 'Settings',
      icon: Cog8ToothIcon,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Settings',
      section: 'Configuration',
    },
  },
  {
    path: 'signout',
    element: <Outlet />,
    sidebar: {
      level: 'secondary',
      label: 'Logout',
      icon: ArrowRightOnRectangleIcon,
      onClick: initiateClearAuth,
    },
    meta: {
      layout: 'App',
      breadcrumb: () => 'Logout',
      section: 'Configuration',
    },
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Forgot Password',
    },
  },
  {
    path: 'signin',
    element: <SigninPage />,
    meta: {
      layout: 'Auth',
      breadcrumb: () => 'Signin',
    },
  },
];

export default routes;
