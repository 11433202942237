import ActionButton, { Action } from 'components/buttons/action-button';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/20/solid';
import { CurrencyDollarIcon, TagIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useMemo } from 'react';
import { Shimmers, TableComponent } from 'components';
import { classNames, useUrlState, withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useMatch, useNavigate, useSearch } from 'react-location';

import { Avatar } from 'components/core';
import CreateVoucherClassContainer from './create';
import { ExportDataContainer } from 'containers';
import { LocationGenerics } from 'router/location';
import UpdateVoucherClassContainer from './update';
import ViewVoucherClassContainer from './view';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import lodash from 'lodash';
import numeral from 'numeral';
import { useToggle } from 'react-use';

const GET_VOUCHER_CLASSES = gql`
  query GetVoucherClasses(
    $filter: VoucherClassFilter
    $populate: [String]
    $pagination: Pagination
    $search: SearchOperator
  ) {
    items: getVoucherClasses(
      filter: $filter
      populate: $populate
      pagination: $pagination
      search: $search
    ) {
      _id
      code
      name
      price
      description
      createdAt
      updatedAt
      cycle {
        _id
        name
      }
      createdBy {
        _id
        name
      }
    }
    itemsCount: getVoucherClassesCount(search: $search, filter: $filter)
  }
`;

const VoucherClassesPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [voucherClassesExpanded, toggleVoucherClassesExpanded] =
    useToggle(false);

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search,
              fields: ['description', 'name'],
            },
          }
        : {}),
      filter: {
        cycle: {
          eq: params.admissionCycle,
        },
      },
      sort: {},
      populate: ['createdBy', 'cycle'],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_VOUCHER_CLASSES,
    {
      variables,
      pollInterval,
      notifyOnNetworkStatusChange: false,
    },
  );

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex h-full flex-1 flex-col overflow-hidden bg-gray-50">
      <div
        className={classNames(
          voucherClassesExpanded ? ' flex-1' : '',
          'group flex flex-shrink-0 flex-col overflow-hidden',
        )}
      >
        {data?.items?.length > 3 && (
          <div
            className={classNames(
              voucherClassesExpanded ? '' : 'pb-0 sm:pb-0',
              'hidden p-4 group-hover:block sm:p-6',
            )}
          >
            <button
              type="button"
              onClick={wrapClick(toggleVoucherClassesExpanded)}
              className={classNames(
                'bg-gray-200 text-gray-800',
                'inline-flex items-center space-x-1 rounded px-3 py-2 text-xs',
              )}
            >
              {voucherClassesExpanded ? (
                <ArrowsPointingInIcon className="h-3 w-3" />
              ) : (
                <ArrowsPointingOutIcon className="h-3 w-3" />
              )}
              <span>View {voucherClassesExpanded ? 'Less' : 'More'}</span>
            </button>
          </div>
        )}
      </div>
      {!voucherClassesExpanded && (
        <div className="flex flex-1 overflow-y-auto">
          <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
            <TableComponent
              title={'Classes'}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={{
                rows: lodash.get(data, 'items', []),
                total: lodash.get(data, 'itemsCount', 0),
                page: searchParams?.page || config.constants.page,
                pageSize: searchParams?.pageSize || config.constants.pageSize,
                totalPages: lodash.ceil(
                  lodash.get(data, 'itemsCount', 0) /
                    (searchParams?.pageSize || config.constants.pageSize),
                ),
              }}
              hasSearch={true}
              renderExport={({ exportOpen, setExportOpen }) => (
                <></>
                // <ExportDataContainer
                //   open={exportOpen}
                //   setOpen={setExportOpen}
                //   entityType="VoucherInstance"
                //   variables={variables}
                // />
              )}
              defaultView="grid"
              renderColumns={() => (
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-right text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Cycle
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                  ></th>
                </tr>
              )}
              renderGridLoader={() => (
                <>
                  <div className="overflow-hidden rounded bg-white shadow">
                    <div className="flex px-4 pb-8 pt-4">
                      <div className="h-12 w-12 rounded bg-gray-400" />
                      <dd className="ml-4 flex flex-1 items-center">
                        <Shimmers.DoubleShimmer />
                      </dd>
                    </div>
                    <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                      <Shimmers.SingleShimmer />
                      <Shimmers.ActionsShimmer actionsCount={4} />
                    </div>
                  </div>
                  <div className="overflow-hidden rounded bg-white shadow">
                    <div className="flex px-4 pb-8 pt-4">
                      <div className="h-12 w-12 rounded bg-gray-400" />
                      <dd className="ml-4 flex flex-1 items-center">
                        <Shimmers.DoubleShimmer />
                      </dd>
                    </div>
                    <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                      <Shimmers.SingleShimmer />
                      <Shimmers.ActionsShimmer actionsCount={4} />
                    </div>
                  </div>
                  <div className="overflow-hidden rounded bg-white shadow">
                    <div className="flex px-4 pb-8 pt-4">
                      <div className="h-12 w-12 rounded bg-gray-400" />
                      <dd className="ml-4 flex flex-1 items-center">
                        <Shimmers.DoubleShimmer />
                      </dd>
                    </div>
                    <div className=" flex justify-between space-x-10 bg-gray-50 px-3 py-1.5 sm:px-4">
                      <Shimmers.SingleShimmer />
                      <Shimmers.ActionsShimmer actionsCount={4} />
                    </div>
                  </div>
                </>
              )}
              renderGridItem={item => (
                <div
                  key={item?._id}
                  className="relative overflow-hidden rounded bg-white shadow-sm ring-1 ring-gray-900/5"
                >
                  <div className="flex flex-col space-y-3 p-3 sm:p-4">
                    <p className="text-md line-clamp-2 font-semibold text-gray-900">
                      {item?.name}
                    </p>
                    <div className="flex items-center space-x-4 text-xs text-gray-600">
                      <div className="line-clamp-3 flex items-center space-x-1">
                        {item?.description}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between bg-gray-50 px-3 py-1.5 sm:px-4">
                    <div className="flex items-center space-x-4 text-xs text-gray-600">
                      <div className="flex items-center space-x-1">
                        <CurrencyDollarIcon className="h-5 w-5" />
                        <span>
                          GHS {numeral(item?.price / 100).format('0,0.00')}
                        </span>
                      </div>
                    </div>

                    <div className="space-x-1">
                      <ActionButton
                        action="view"
                        onClick={dispatchAction(item?._id, 'view')}
                      />
                      {withPermissions([
                        '*:*',
                        'admission-cycles:*',
                        'admission-cycles:update',
                      ])(
                        <ActionButton
                          action="update"
                          onClick={dispatchAction(item?._id, 'update')}
                        />,
                      )}
                    </div>
                  </div>
                </div>
              )}
              renderLoader={() => (
                <tr>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.AvatarShimmer />
                  </td>
                  <td className="border-b border-gray-200 px-6 py-4">
                    <Shimmers.ActionsShimmer actionsCount={1} />
                  </td>
                </tr>
              )}
              renderItem={item => (
                <tr
                  key={item._id}
                  className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                  onClick={wrapClick(dispatchAction(item._id, 'view'))}
                >
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.code || 'N/A'}
                    </div>
                    <div className="font-medium text-gray-500 dark:text-gray-400">
                      {item?.name || 'N/A'}
                    </div>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.itineraryCode || 'N/A'}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.community || 'N/A'}
                    </div>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                    <div className="text-gray-900 dark:text-gray-100">
                      {numeral(item?.meta?.propertiesCount || 0).format('0,0')}{' '}
                      properties
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {numeral(item?.meta?.customersCount || 0).format('0,0')}{' '}
                      customers
                    </div>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                    <div className="text-gray-900 dark:text-gray-100">
                      <span className="mr-2 text-xs font-light">from</span>
                      {item?.meta?.cycleStartDate
                        ? dayjs(item?.meta?.cycleStartDate).format(dateFormat)
                        : 'N/A'}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      <span className="mr-2 text-xs font-light">to</span>
                      {item?.meta?.cycleEndDate
                        ? dayjs(item?.meta?.cycleEndDate).format(dateFormat)
                        : 'N/A'}
                    </div>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (item?.reader?.lastName || '')?.trim(),
                              (item?.reader?.firstName || '')?.trim(),
                            ]
                              .join(' ')
                              .trim() || 'N A'
                          }
                          src={item?.reader?.profileImageUrl || ''}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {[
                            (item?.reader?.lastName || '')?.trim(),
                            (item?.reader?.firstName || '')?.trim(),
                          ]
                            .join(' ')
                            .trim() || 'N A'}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          {item?.reader?.phoneNumber || 'N/A'}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                    <span
                      className={classNames(
                        item?.status === 'Acknowledged'
                          ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                          : '',
                        item?.status === 'Expired'
                          ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                          : '',
                        item?.status === 'Failed'
                          ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                          : '',
                        item?.status === 'Posted'
                          ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                          : '',
                        item?.status === 'Pending'
                          ? `bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                          : '',
                        item?.status === 'Success'
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : '',
                        item?.status === 'Reversed'
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : '',
                        !item?.status
                          ? 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                          : '',
                        'inline-flex items-center  space-x-1 rounded-full px-2 py-1 text-xs',
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        fill="currentColor"
                        className="h-1.5 w-1.5"
                      >
                        <circle
                          fillRule="evenodd"
                          cx="5"
                          cy="5"
                          r="5"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>{item?.status || 'Unknown'}</span>
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                    <div className="space-x-1">
                      <ActionButton
                        action="view"
                        onClick={dispatchAction(item?._id, 'view')}
                      />
                    </div>
                  </td>
                </tr>
              )}
            />
          </div>
        </div>
      )}

      {withPermissions(['*:*', 'voucher-classes:*', 'voucher-classes:create'])(
        <CreateVoucherClassContainer
          open={modal === 'create'}
          setOpen={(val: boolean) => setModal(val ? 'create' : undefined)}
          refetch={refetch}
        />,
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions([
            '*:*',
            'voucher-classes:*',
            'voucher-classes:update',
          ])(
            <UpdateVoucherClassContainer
              open={modal === 'update'}
              setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
              refetch={refetch}
            />,
          )}
          <ViewVoucherClassContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default VoucherClassesPage;
