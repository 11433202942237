import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Avatar, SelectInput, Shimmers, TableComponent } from 'components';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo } from 'react';
import { useMatch, useMatches, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import {
  wrapClick,
  useUrlState,
  classNames,
  useDownloadFile,
  withPermissions,
} from 'utils';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import { useToggle } from 'react-use';
import ViewAdmittedContainer from './view';
import AdmissionProgrammePickerContainer from 'containers/admission-programme-picker';
import { currentUserVar } from 'apollo/cache/auth';
import toast from 'react-hot-toast';
import CreateTuitionContainer from './create';
import UpdateVendorContainer from 'pages/vendors/update';
import UpdateTuitionContainer from './update';
// import StartTuitionContainer from './start-tuition';

const GET_TUITIONS = gql`
  query GetTuitions(
    $filter: TuitionFilter
    $pagination: Pagination
    $search: SearchOperator
    $populate: [String]
  ) {
    items: getTuitions(
      filter: $filter
      pagination: $pagination
      search: $search
      populate: $populate
    ) {
      _id
      code
      lastUpdatedBy {
        _id
        name
      }
      name
      updatedAt
      fees {
        type
      }
    }
    itemsCount: getTuitionsCount(filter: $filter, search: $search)
  }
`;

const TuitionPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, 'expand' | 'goto' | 'clone'>) =>
    () => {
      navigate({
        search: old => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [admissionProgramme, setAdmissionProgramme] =
    useUrlState('admissionProgramme');
  const [school, setSchool] = useUrlState('school');
  const matches = useMatches<LocationGenerics>();

  useEffect(() => {
    setSchool(currentUser?.school?._id);
  }, [currentUser]);

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || '')?.length > 0
        ? {
            search: {
              query: searchParams?.search,
              options: ['CaseInsensitive'],
              fields: [
                'applicationName',
                'applicationEmail',
                'applicationCode',
              ],
            },
          }
        : {}),
      filter: {
        cycle: {
          eq: params.admissionCycle,
        },
      },
      sort: {},
      populate: ['lastUpdatedBy'],
    }),
    [searchParams],
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_TUITIONS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    navigate({
      search: old => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: '' } : {}),
        ...(!old?.sort ? { sort: 'createdAt' } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex h-full flex-1 flex-col overflow-hidden bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="light mx-auto flex min-h-full  min-w-0 flex-1 overflow-hidden overflow-y-auto py-4 sm:py-6">
          <TableComponent
            title={'tuition'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, 'items', []),
              total: lodash.get(data, 'itemsCount', 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, 'itemsCount', 0) /
                  (searchParams?.pageSize || config.constants.pageSize),
              ),
            }}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 space-x-2 sm:ml-16 sm:mt-0 sm:flex"></div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Fee Types
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Last Updated By
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                >
                  Last Updated At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 whitespace-nowrap border-y border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-light uppercase tracking-wider text-gray-900 dark:bg-gray-800 dark:text-gray-100"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="border-b border-gray-200 px-6 py-4">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={item => (
              <tr
                key={item._id}
                className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                onClick={wrapClick(dispatchAction(item._id, 'view'))}
              >
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500 ">
                  <div className="font-medium text-gray-600 dark:text-gray-100">
                    {item?.code || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="font-medium text-gray-600 dark:text-gray-100">
                    {item?.name || 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-500">
                  <div className="font-medium text-gray-600 dark:text-gray-100">
                    {item?.fees.length
                      ? item?.fees?.map((fee: any) => fee?.type).join(', ')
                      : 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
                  <div className="flex flex-row justify-center">
                    {item?.lastUpdatedBy ? (
                      <>
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            src={item?.lastUpdatedBy?.profileImageUrl}
                            alt={item?.lastUpdatedBy?.name || 'N A'}
                          />
                        </div>
                        <div className="ml-4 self-center">
                          <div className="text-gray-600 dark:text-gray-100">
                            {item?.lastUpdatedBy?.name || '-'}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-gray-600 dark:text-gray-100">
                        N/A
                      </div>
                    )}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-center text-sm text-gray-400">
                  <div className="text-gray-400 dark:text-gray-100">
                    {item?.updatedAt
                      ? dayjs(item?.updatedAt).format('MMM DD, YYYY - HH:mm')
                      : 'N/A'}
                  </div>
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-right text-sm text-gray-500">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, 'view')}
                    />
                    <ActionButton
                      action="update"
                      onClick={dispatchAction(item?._id, 'update')}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(['*:*', 'tuition:*', 'tuition:create'])(
        <CreateTuitionContainer
          open={modal === 'create'}
          setOpen={(val: boolean) => setModal(val ? 'create' : undefined)}
          refetch={refetch}
        />,
      )}

      {!!searchParams.id?.length && (
        <>
          <ViewAdmittedContainer
            open={modal === 'view'}
            setOpen={(val: boolean) => setModal(val ? 'view' : undefined)}
          />

          {withPermissions(['*:*', 'tuition:*', 'tuition:update'])(
            <UpdateTuitionContainer
              open={modal === 'update'}
              setOpen={(val: boolean) => setModal(val ? 'update' : undefined)}
              refetch={refetch}
            />,
          )}
        </>
      )}
    </main>
  );
};

export default TuitionPage;
