import { useQuery, gql } from '@apollo/client';

const GET_ADMISSION_CYCLE = gql`
  query GetAdmissionCycleBreadcruumb($id: ID!) {
    admissionCycle: getAdmissionCycle(filter: { _id: { eq: $id } }) {
      _id
      code
      name
    }
  }
`;
const AdmissionCycleBreadcrumb = ({ id }: { id: string }) => {
  const { data } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: false,
  });
  return <>{data?.admissionCycle?.name || 'Admission Cycle'}</>;
};

export default AdmissionCycleBreadcrumb;
