import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, VoucherClassForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_VOUCHER_CLASS = gql`
  query GetVoucherClassLean($filter: VoucherClassFilter!) {
    voucherClass: getVoucherClass(filter: $filter) {
      _id
      code
      name
      level
      description
      price
    }
  }
`;

const UPDATE_VOUCHER_CLASS = gql`
  mutation UpdateVoucherClass($id: ID!, $input: VoucherClassInput!) {
    voucherClass: updateVoucherClass(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateVoucherClassContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { data, loading } = useQuery(GET_VOUCHER_CLASS, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateVoucherClass, { loading: loadingEdit }] =
    useMutation(UPDATE_VOUCHER_CLASS);

  const form = useFormik({
    initialValues: {
      name: '',
      price: 0,
      description: '',
      level: '',
    },
    onSubmit: async values => {
      await updateVoucherClass({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
            price: parseInt(`${values.price * 100}`),
            cycle: params.admissionCycle,
          },
        },
      }).then(({ data }) => {
        if (data?.voucherClass?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Class updated successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not update class' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      name: data?.voucherClass?.name || '',
      price: data?.voucherClass?.price / 100 || 0,
      description: data?.voucherClass?.description || '',
      level: data?.voucherClass?.level || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.voucherClass, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Edit Class Information"
      description="Provide the details to update class"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loadingEdit}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loadingEdit ? 'Editing Class...' : 'Edit Class'}
          </button>
        </>
      )}
    >
      {data?.voucherClass?._id && <VoucherClassForm form={form} />}
    </Modal>
  );
}
