import { FC } from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import { ApplicationDetailsView } from '.';
import Loader from 'components/layouts/loader';
dayjs.extend(localeData);

const GET_APPLICATION = gql`
  query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
    application: getApplication(filter: $filter, populate: $populate) {
      _id
      code
      personalInformation {
        lastName
        middleName
        firstName
        dateOfBirth
        placeOfBirth
        gender
        maritalStatus
        numberOfChildren
        nationality
        isPhysicallyChallenged
        physicalChallenges
        email
        phone
        secondaryPhone
        bioFileUrl
        emergencyContacts {
          name
          relationship
          phone
          email
        }
        profileImageUrl
      }
      programmeInformation {
        preferredCampus {
          _id
          code
          name
          phone
          email
          digitalAddress
          officeAddress
          createdAt
          updatedAt
        }
        preferredOfferingType
        programmeSelections {
          programme {
            _id
            code
            name
            level
            tracks
            requiresInterview
            maximumInterviewScore
            requiredInterviewScore
            interviewScoreWeight
            requiresExamination
            maximumExaminationScore
            requiredExaminationScore
            examinationScoreWeight
            createdAt
            updatedAt
          }
          school {
            _id
            name
          }
        }
      }
      educationalHistoryInformation {
        type
        history {
          institution
          idNumber
          programme
          graduatingMonth
          graduatingYear
          cgpa
          class
          gpaScale
          subjectGrades {
            subject
            grade
          }
          certificateFileUrl
          transcriptFileUrl
          evaluationFileUrl
          essayFileUrl
          cvFileUrl
        }
      }
      employmentHistoryInformation {
        organization
        title
        startDate
        endDate
      }
      extraInformation {
        isExistingStudent
        existingStudentId
        existingStudentProgramme {
          _id
          code
          name
          description
          level
          createdAt
          updatedAt
        }
        sponsorType
        sponsorDetails {
          organization
          name
          title
          email
          phone
        }
      }
      referencesInformation {
        academic {
          organization
          name
          title
          address
          email
          phone
          recommendation {
            generalAssessment
            knownAs
            knownDuration
            knownDurationDetails
            knownDurationTo
            leadership
            maturity
            motivation
            overallRecommendation
            personalStrengths
            selfConfidence
            selfDiscipline
            weakness
            academicMaturity
          }
          approved
        }
        nonAcademic {
          organization
          name
          title
          address
          email
          phone
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`;

interface ShortlistViewProps {
  shortlist: {
    _id: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    applicant: {
      code: string;
      email: string;
      _id: string;
    };
    cycle: {
      _id: string;
      code: string;
      name: string;
    };
    examinationScore: number;
    interviewScore: number;
    programme: {
      _id: string;
      name: string;
      level: string;
      requiresInterview: boolean;
      requiresExamination: boolean;
    };
    lastUpdatedBy: {
      _id: string;
      name: string;
    };
    application: {
      _id: string;
      personalInformation: {
        profileImageUrl: string;
        firstName: string;
        lastName: string;
      };
    };
  };
  application?: any;
  onLoadingChange?: (value: any) => void;
}

const formSteps = [
  {
    name: 'Personal Info',
    description: 'Details',
    accessor: 'personalInformation',
    SummaryComponent: ApplicationDetailsView.Personal,
  },
  {
    name: 'Programme Info',
    description: 'Details',
    accessor: 'programmeInformation',
    SummaryComponent: ApplicationDetailsView.Programme,
  },
  {
    name: 'Education Info',
    description: 'Details',
    accessor: 'educationalHistoryInformation',
    SummaryComponent: ApplicationDetailsView.Education,
  },
  {
    name: 'Employment Info',
    description: 'Details',
    accessor: 'employmentHistoryInformation',
    SummaryComponent: ApplicationDetailsView.Employment,
  },
  {
    name: 'Extra Info',
    description: 'Details',
    accessor: 'extraInformation',
    SummaryComponent: ApplicationDetailsView.Extra,
  },
];

const ShortlistReviewView: FC<ShortlistViewProps> = ({
  shortlist,
  application,
  onLoadingChange,
}) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  const { data, loading } = useQuery(GET_APPLICATION, {
    variables: {
      filter: {
        _id: {
          eq: shortlist?.application?._id,
        },
      },
      populate: [
        'programmeInformation',
        'programmeInformation.preferredCampus',
        'programmeInformation.programmeSelections.programme',
        'programmeInformation.programmeSelections.school',
        'referencesInformation.academic',
        'referencesInformation.nonAcademic',
        'extraInformation.existingStudentProgramme',
      ],
    },
    notifyOnNetworkStatusChange: false,
  });

  return loading ? (
    <Loader />
  ) : (
    <div className="space-y-6 divide-y divide-gray-200">
      {formSteps.map(({ SummaryComponent, ...summary }, idx) => {
        return (
          <div key={idx} className={idx === 0 ? ' py-4' : ' py-4 pt-6'}>
            <>
              <div className="flex cursor-pointer items-center justify-between">
                <div>
                  <h3 className="text-md font-normal leading-6 text-gray-900 dark:text-gray-100">
                    {summary?.name}
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    {summary?.description}
                  </p>
                </div>
                <div />
              </div>

              <div className="mt-6">
                <SummaryComponent
                  data={_.get(data?.application, summary.accessor)}
                  values={data?.application}
                  config={currentConfig}
                />
              </div>
            </>
          </div>
        );
      })}
    </div>
  );
};

export default ShortlistReviewView;
