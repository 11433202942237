import { TextInput } from 'components/core';
import { FormikProps } from 'formik';
import { FC } from 'react';

interface DownloadProfileFormProps {
  form: FormikProps<{
    email: any;
  }>;
}

const DownloadProfileForm: FC<DownloadProfileFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <p className="text-base font-light">
          Please enter an email to send the profile to
        </p>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <TextInput
              id="email"
              label="Email"
              type="email"
              placeholder="Defaults to your current account email"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadProfileForm;
