import { onError } from '@apollo/client/link/error';
import { SentryLink, excludeGraphQLFetch } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from 'config';
import toast from 'react-hot-toast';

Sentry.init({
  environment: config.sentry.env,
  dsn: config.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      traceFetch: false,
    }),
  ],
  beforeBreadcrumb: excludeGraphQLFetch,
  enabled: config.env === 'development',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const sentryMiddleware = new SentryLink({
  uri: config.apollo.uri,
  setTransaction: true,
  setFingerprint: true,
});

const errorMiddleware = onError(errors => {
  if (errors.graphQLErrors)
    errors.graphQLErrors.forEach(({ message, locations, path }) => {
      toast(JSON.stringify({ type: 'error', title: message }));
    });

  if (errors.networkError) {
    console.log(`${errors.networkError.message}`);
    toast(
      JSON.stringify({
        type: 'error',
        title: 'Network Error',
        description: `Please check your internet connection.`,
      }),
    );
  }
  Sentry.captureException(errors);
}).split(() => config.env === 'production', sentryMiddleware);

export default errorMiddleware;
