import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { FC } from 'react';

interface ProgrammeViewProps {
  programme: {
    code: string;
    name: string;
    description: string;
    level: string;
    school: {
      _id: string;
      code: string;
      name: string;
    };
    department: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    createdAt: string;
    updatedAt: string;
  };
}

const ProgrammeView: FC<ProgrammeViewProps> = ({ programme }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Programme Information</span>
        <div className="mt-2 grid grid-cols-2 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              School
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.school?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Department
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.department?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Level
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.level || 'N/A'}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.description || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.createdAt
                ? dayjs(programme?.createdAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.createdBy?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.updatedAt
                ? dayjs(programme?.updatedAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {programme?.lastUpdatedBy?.name || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammeView;
