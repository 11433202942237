import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, CampusForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { CampusSchema } from 'components/forms/campus/schema';

const GET_CAMPUS_LEAN = gql`
  query GetCampusLean($filter: CampusFilter!) {
    campus: getCampus(filter: $filter) {
      _id
      code
      digitalAddress
      email
      name
      phone
      officeAddress
    }
  }
`;

const UPDATE_CAMPUS = gql`
  mutation UpdateCampus($id: ID!, $input: CampusInput!) {
    campus: updateCampus(id: $id, input: $input) {
      _id
    }
  }
`;

export default function UpdateCampusContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CAMPUS_LEAN, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCampus, { loading: updating }] = useMutation(UPDATE_CAMPUS);

  const form = useFormik({
    initialValues: {
      digitalAddress: '',
      email: '',
      name: '',
      officeAddress: '',
      phone: '',
    },
    validationSchema: CampusSchema,
    onSubmit: async values => {
      await updateCampus({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
          },
        },
      }).then(({ data }) => {
        if (data?.campus?._id) {
          toast(
            JSON.stringify({
              type: 'success',
              title: 'Campus updated successfully',
            }),
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: 'error', title: 'Could not update Campus' }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      name: data?.campus?.name || '',
      officeAddress: data?.campus?.officeAddress || '',
      digitalAddress: data?.campus?.digitalAddress || '',
      email: data?.campus?.email || '',
      phone: data?.campus?.phone || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.campus, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Campus Information"
      description="Provide the details to update campus"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? 'Editing Campus...' : 'Edit Campus'}
          </button>
        </>
      )}
    >
      {data?.campus?._id && <CampusForm form={form} />}
    </Modal>
  );
}
