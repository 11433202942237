import { FC } from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { Avatar } from 'components/core';
dayjs.extend(localeData);

interface TuitionViewProps {
  tuition: {
    _id: string;
    code: string;
    description: string;
    fees: {
      fileUrl: string;
      type: string;
    };
    name: string;
    updatedAt: string;
    lastUpdatedBy: {
      _id: string;
      name: string;
    };
    createdBy: {
      _id: string;
      name: string;
    };
    createdAt: string;
  };
}

const TuitionView: FC<TuitionViewProps> = ({ tuition }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);
  // console.log({admitted})

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Tuition Information</span>
        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.code || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.name || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.description || 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {'Files' || 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.createdAt
                ? dayjs(tuition?.createdAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.createdBy?.name ? tuition?.createdBy?.name : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.updatedAt
                ? dayjs(tuition?.updatedAt).format(dateFormat + ' hh:mm A')
                : 'N/A'}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tuition?.lastUpdatedBy?.name
                ? tuition?.lastUpdatedBy?.name
                : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TuitionView;
