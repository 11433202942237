import { useReactiveVar, useLazyQuery, gql } from '@apollo/client';
import { currentUserVar } from 'apollo/cache/auth';
import { RadioInput, SelectInput, TextArea } from 'components/core';
import {
  AdmissionProgrammePicker,
  CampusPicker,
  DepartmentPicker,
  SchoolPicker,
} from 'containers';
import { FormikProps } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import _ from 'lodash';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

interface OfferAdmissionFormProps {
  form: FormikProps<{
    school: any;
    department: any;
    programme: any;
    campus: any;
    level: any;
    offeringType: any;
    startingYear: any;
    offeredProgrammeRemark: any;
  }>;
}

const GET_ADMISSION_PROGRAMME = gql`
  query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!) {
    admissionProgramme: getAdmissionProgramme(filter: $filter) {
      _id
      offerings {
        types
        campus {
          _id
        }
      }
    }
  }
`;

const OfferAdmissionsForm: FC<OfferAdmissionFormProps> = ({ form }) => {
  const { params } = useMatch<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);

  const [getAdmissionProgramme, { data, loading }] = useLazyQuery(
    GET_ADMISSION_PROGRAMME,
  );

  useEffect(() => {
    if (form?.values?.programme && form?.values?.campus) {
      getAdmissionProgramme({
        variables: {
          filter: {
            _id: {
              eq: form?.values?.programme,
            },
            offerings: {
              campus: {
                eq: form?.values?.campus,
              },
            },
          },
        },
      });
    }
  }, [form?.values]);

  const admissionProgrammeCampuses = data?.admissionProgramme?.offerings?.map(
    (offering: any) => offering?.campus?._id,
  );

  const selectedOfferings = useMemo(
    () =>
      data?.admissionProgramme?.offerings?.find(
        (offering: any) => offering?.campus?._id === form.values?.campus,
      ),
    [admissionProgrammeCampuses, form.values?.campus],
  );

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <div className="mt-2 grid grid-cols-1 gap-6">
          <div className="col-span-1">
            <SelectInput
              id="startingYear"
              label="Set Starting Year"
              placeholder="Choose start year"
              options={['1', '2', '3']}
              {...form}
            />
          </div>
          <div className="col-span-1">
            <SchoolPicker
              id="school"
              label="School"
              placeholder="e.g. Accra East School"
              required={true}
              disabled={!!currentUser?.school?._id}
              {...form}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <DepartmentPicker
              id="department"
              label="Department"
              placeholder="e.g. Computer Science"
              required={true}
              {...form}
              disabled={!!currentUser?.department?._id}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <AdmissionProgrammePicker
              id="programme"
              label="Programme"
              placeholder="e.g. Computer Science"
              required={true}
              {...form}
              disabled={!form?.values?.department}
              filter={{
                cycle: { eq: params.admissionCycle as string },
                ...(form?.values?.department && {
                  department: {
                    eq: form?.values?.department,
                  },
                }),
                ...(form?.values?.level && {
                  level: {
                    eq: form?.values?.level,
                  },
                }),
                deleted: { eq: false },
              }}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <CampusPicker
              id="campus"
              label="Campus"
              placeholder="e.g. Kumasi Campus"
              required={true}
              disabled={!Boolean(form.values.programme)}
              filter={{
                _id: {
                  in: admissionProgrammeCampuses?.length
                    ? admissionProgrammeCampuses
                    : [],
                },
              }}
              {...form}
              rawId={true}
            />
          </div>
          <>
            {selectedOfferings?.types.length ? (
              <div className="col-span-1">
                <RadioInput
                  id="offeringType"
                  label="Offering Type"
                  required={true}
                  options={
                    selectedOfferings?.types?.map((type: string) => ({
                      label: _.capitalize(type),
                      value: type,
                    })) || []
                  }
                  {...form}
                />
              </div>
            ) : !loading ? (
              <div className="flex flex-row gap-x-1 rounded-md border bg-gray-100 p-2.5 text-sm text-gray-700">
                <ExclamationCircleIcon className="h-5 w-5 text-gray-500" />
                {form.values?.campus
                  ? `There are no offerings for the selected campus`
                  : `Select a campus to show available offerings`}
              </div>
            ) : (
              <div></div>
            )}
          </>

          <div className="col-span-1">
            <TextArea
              id="offeredProgrammeRemark"
              label="Offered Programme Remark"
              placeholder="eg. You do not qualify for..."
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferAdmissionsForm;
