import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { SchoolView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_SCHOOL = gql`
  query GetSchool($filter: SchoolFilter!, $populate: [String]) {
    school: getSchool(filter: $filter, populate: $populate) {
      _id
      code
      dean {
        _id
        code
        name
        email
        phone
        profileImageUrl
        staffId
      }
      digitalAddress
      email
      name
      phone
      officeAddress
      yearEstablished
      createdBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      lastUpdatedBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deleted
      meta {
        departmentsCount
        programmesCount
      }
    }
  }
`;

export default function ViewSchoolContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SCHOOL, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['createdBy', 'lastUpdatedBy', 'dean'],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: old => ({
        ...old,
        modal: 'update',
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="School Information"
      description="Details of school are shown below"
      renderActions={() => (
        <>
          {withPermissions(['*:*', 'schools:*', 'schools:update'])(
            <button
              type="button"
              className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>,
          )}
        </>
      )}
    >
      <SchoolView school={data?.school} />
    </Modal>
  );
}
