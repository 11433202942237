import { ApolloLink, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { clearAuth, currentTokenVar } from 'apollo/cache/auth';

const authMiddleware = new ApolloLink((operation, forward) => {
  // check if token has been cached
  let authorization = currentTokenVar();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(authorization ? { authorization: authorization } : {}),
    },
  }));

  return forward(operation);
});

const resetAuthMiddleware = onError(({ networkError }) => {
  if (networkError) {
    if (
      networkError.name === 'ServerError' &&
      (networkError as ServerError).statusCode === 401
    ) {
      clearAuth();
    }
  }
});

export default authMiddleware.concat(resetAuthMiddleware);
