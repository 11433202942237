import { useEffect, useRef, useState } from 'react';
import { wrapClick } from 'utils';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import {
  ApplicationView,
  Modal,
  TextInput,
  VoucherClassForm,
} from 'components';
import { useMatch, useMatches, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import ShortlistForm from 'components/forms/shortlist';
import Loader from 'components/layouts/loader';
import {
  AdmissionProgrammePicker,
  CampusPicker,
  SchoolPicker,
} from 'containers';
import { useReactToPrint } from 'react-to-print';

const GET_ADMISSIONS = gql`
  query GetAdmissions($filter: AdmissionFilter, $populate: [String]) {
    admissions: getAdmissions(filter: $filter, populate: $populate) {
      _id
      application {
        gimpaIndexNumber
        personalInformation {
          profileImageUrl
        }
      }
      applicationLevel
      applicationEmail
      applicationName
      applicationCode
      code
    }
  }
`;

const ENROLL_ADMISSION = gql`
  mutation EnrollAdmission($input: EnrollAdmissionInput!) {
    enrollAdmission(input: $input) {
      _id
    }
  }
`;

export default function ExportPassportContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'ADMISSION_PASSPORTS',
    bodyClass: 'w-[1000px]',
  });

  const matches = useMatches<LocationGenerics>();
  const cycle = matches?.[0]?.params?.admissionCycle;

  const [searchIndex, setSearchIndex] = useState('');
  const [school, setSchool] = useState('');
  const [programme, setProgramme] = useState('');
  const [campus, setCampus] = useState('');

  const [getAdmissions, { data, loading }] = useLazyQuery(GET_ADMISSIONS, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only',
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Export Passport"
      size="6xl"
      description="Provide the details the Index number of the applicant you want to accept"
      renderActions={() => (
        <>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => handlePrint())}
          >
            {loading ? 'Print...' : 'Print'}
          </button>
        </>
      )}
    >
      <div className="grid grid-cols-4 gap-x-3">
        <div>
          <SchoolPicker
            id="school"
            label="School"
            placeholder="Filter School"
            values={{ school }}
            setFieldValue={(field: string, value: string) => {
              setSchool(value);
              setProgramme('');
            }}
            rawId={true}
          />
        </div>
        <div>
          <AdmissionProgrammePicker
            id="admissionProgramme"
            label="Programme"
            placeholder="Filter Programme"
            filter={{
              cycle: {
                eq: cycle || '',
              },
              ...(school && { school: { eq: school } }),
            }}
            values={{ admissionProgramme: programme }}
            setFieldValue={(field: string, value: string) => {
              setProgramme(value);
            }}
            rawId={true}
          />
        </div>
        <div>
          <CampusPicker
            id="campus"
            label="Campus"
            placeholder="Filter Campus"
            values={{ campus: campus }}
            setFieldValue={(field: string, value: string) => {
              setCampus(value);
            }}
            rawId={true}
          />
        </div>
        <div className="col-span-1 flex w-full flex-row">
          <button
            type="button"
            disabled={loading}
            className="mt-1 h-10 w-full self-end rounded border  border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:text-sm"
            onClick={wrapClick(() =>
              getAdmissions({
                variables: {
                  filter: {
                    ...(cycle && { cycle: { eq: cycle } }),
                    ...(campus && { campus: { eq: campus } }),
                    ...(programme && { programme: { eq: programme } }),
                    ...(school && { school: { eq: school } }),
                  },
                  populate: ['application'],
                },
              }),
            )}
          >
            {loading ? 'Fetching...' : 'Get Admissions'}
          </button>
        </div>
      </div>

      <div ref={printRef} className="mt-10 grid grid-cols-3 gap-5">
        {data?.admissions?.map((admission: any) => {
          return (
            <div className="flex w-full flex-row gap-x-2 rounded border border-gray-300 px-3 py-4">
              <div className="h-20 w-20">
                <img
                  className="h-full w-full object-contain"
                  src={
                    admission?.application?.personalInformation?.profileImageUrl
                  }
                />
              </div>

              <div className="flex flex-col justify-between">
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    {admission?.applicationCode} (ID:{' '}
                    {admission?.application.gimpaIndexNumber || 'Unavailable'})
                  </p>
                  <p className="line-clamp-1 text-gray-700">
                    {admission?.applicationName}
                  </p>
                </div>

                <a
                  href={
                    admission?.application?.personalInformation?.profileImageUrl
                  }
                  target="_blank"
                  className="text-xs text-primary-500"
                >
                  Download Passport
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
