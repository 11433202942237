import { FC, Fragment, useRef, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames, wrapClick } from 'utils';
import Loader from './loader';

const sizeMap = {
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  '8xl': 'max-w-8xl',
};
interface ModalProps {
  title: string;
  description?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  renderActions?: () => JSX.Element;
  hideActions?: boolean;
  hidePadding?: boolean;
  size?: keyof typeof sizeMap;
  loading?: boolean;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  setOpen,
  children,
  renderActions,
  title,
  description,
  hideActions,
  hidePadding,
  size = '3xl',
  loading,
}) => {
  // const theme = useReactiveVar(currentConfigVar);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('theme', 'fixed inset-0 z-10 overflow-y-auto')}
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                sizeMap[size],
                'inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-gray-900 sm:my-8 sm:w-full sm:align-middle',
              )}
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-800 dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:flex-col">
                <div className="mx-auto flex flex-shrink-0 flex-col items-start rounded border-b border-gray-200 sm:mx-0 sm:px-6 sm:py-4">
                  <Dialog.Title
                    as="h3"
                    className="flex-1 text-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-50 sm:text-left"
                  >
                    {title}
                  </Dialog.Title>
                  {description && (
                    <p className="mt-1 w-full  text-base  text-gray-600 dark:text-gray-300 sm:w-auto sm:text-sm">
                      {description}
                    </p>
                  )}
                </div>
                <div
                  className={classNames(
                    hideActions || hidePadding
                      ? 'overflow-hidden'
                      : 'min-h-[50vh] overflow-y-auto sm:p-6',
                    loading ? 'min-h-[50vh]' : '',
                    'flex max-h-[75vh] w-full flex-1 flex-col',
                  )}
                >
                  {loading ? <Loader /> : children}
                </div>
              </div>
              {!hideActions && !loading && (
                <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 dark:bg-gray-800 sm:flex sm:flex-row-reverse sm:px-6 sm:py-4">
                  <>{renderActions?.()}</>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:dark:bg-gray-700 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={wrapClick(() => setOpen(false))}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
