import * as Yup from 'yup';
import { phoneNumberValidation } from '../admin/schema';

export const CampusSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is a required field'),
  name: Yup.string().required('Name is a required field'),
  phone: phoneNumberValidation(),
  officeAddress: Yup.string(),
  digitalAddress: Yup.string(),
});

export const CampusInitialValues = {
  email: '',
  name: '',
  phone: '',
  officeAddress: '',
  digitalAddress: '',
};
