import { FC, useState } from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { currentPushTokenVar, setAuth } from 'apollo/cache/auth';
import { useNavigate, useSearch } from 'react-location';
import { useLocalStorage } from 'react-use';
import { LocationGenerics } from 'router/location';
import * as Yup from 'yup';
import { TextInput } from 'components/core';
import { useFormik } from 'formik';
import { Link } from 'react-location';

const LOGIN_ADMIN = gql`
  mutation LoginUser($input: LoginInput!) {
    auth: loginUser(input: $input) {
      token
      user {
        _id
        code
        name
        email
        phone
        profileImageUrl
        role
        school {
          _id
          code
          name
        }
        gender
        meta {
          isFirstLogin
          isSuspended
        }
        createdAt
        updatedAt
        department {
          _id
          name
        }
        staffId
      }
    }
  }
`;

const LoginPage: FC = () => {
  const currentPushToken = useReactiveVar(currentPushTokenVar);

  const search = useSearch<LocationGenerics>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_forgotStore, setForgotStore] = useLocalStorage<{
    username?: string;
    tab: string;
    token?: string;
  }>('forgot-store', { username: '', tab: 'send-code', token: '' });
  const navigate = useNavigate();
  const [loginUser, { loading }] = useMutation(LOGIN_ADMIN);
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: async values => {
      await loginUser({
        variables: {
          input: {
            username: values.username.toLowerCase(),
            password: values.password,
            ...(currentPushToken
              ? { notificationToken: currentPushToken }
              : {}),
          },
        },
      }).then(({ data }) => {
        if (data?.auth) {
          // console.log("data.auth?.user?.meta?.isFirstLogin", data.auth?.user?.meta?.isFirstLogin)
          if (data.auth?.user?.meta?.isFirstLogin) {
            setForgotStore({
              tab: 'reset-password',
              token: data.auth.token,
              username: data.auth.user.name,
            });
            return navigate({
              to: '/forgot-password',
            });
          } else {
            setAuth({
              user: data.auth?.user,
              token: data.auth.token,
            });
            return navigate({
              replace: true,
              to: search?.redirect ?? '/',
            });
          }
        }
      });
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="text-center">
        <h2 className="text-xl font-extrabold text-gray-900">Welcome back!</h2>
        <p className="mt-2 text-sm text-gray-600">
          Enter your account details below.
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label="Email"
                type="email"
                placeholder="e.g. user@gimpa.edu.gh"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="e.g.  **************"
                {...form}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="show-password"
                  name="show-password"
                  type="checkbox"
                  onChange={() => setShowPassword((prev: any) => !prev)}
                  className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                />
                <label
                  htmlFor="show-password"
                  className="ml-2 block text-sm text-gray-600"
                >
                  Show Password
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-600"
                >
                  Remember for 30 days
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {loading ? 'Logging in...' : 'Log in'}
              </button>
            </div>

            <div className="flex flex-col space-y-2 text-center text-sm text-gray-600">
              <span>
                Don't have an account?{' '}
                <a
                  href="mailto:engineering@polymorphlabs.io"
                  className="text-primary-600 hover:text-primary-500"
                >
                  Contact Admin
                </a>
              </span>

              <Link
                to="/forgot-password"
                className="text-primary-600 hover:text-primary-500"
              >
                Forgot your password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
