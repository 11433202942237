import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import TuitionView from 'components/cards/tuition-view';

const GET_TUITION = gql`
  query GetTuition($filter: TuitionFilter!) {
    tuition: getTuition(filter: $filter) {
      _id
      code
      description
      fees {
        fileUrl
        type
      }
      name
      updatedAt
      lastUpdatedBy {
        _id
        name
      }
      createdBy {
        _id
        name
      }
      createdAt
    }
  }
`;

export default function ViewTuitionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_TUITION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id,
        },
      },
      populate: ['lastUpdatedBy', 'createdBy'],
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only',
  });

  const updateItem = () => {
    navigate({
      search: old => ({
        ...old,
        modal: 'update',
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="View Tuition Information"
      description="Details of the tuition are displayed here"
    >
      <TuitionView tuition={data?.tuition} />
    </Modal>
  );
}
