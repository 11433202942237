import { DocumentIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { FC, useEffect, useState } from 'react';
import { classNames } from 'utils';
import { EXAMINATION_GRADING, extractFirebaseName } from 'utils/general';

type DocumentType = {
  name: string;
  size?: number;
  file: any;
  isUploaded?: boolean;
  downloadUrl?: string;
};

const DocumentComponent = ({ file }: { file: string }) => {
  const [fileDocument, setFileDocument] = useState<DocumentType>();

  useEffect(() => {
    if (file && file !== '') {
      const name = extractFirebaseName(file) || '';
      setFileDocument({
        name: name,
        downloadUrl: file,
        file: undefined,
        size: undefined,
        isUploaded: true,
      });
    }
  }, [file]);

  return (
    <div className="mt-3 flex h-24 w-full flex-row rounded-md border border-gray-200 px-3 py-4">
      <span className="mr-3 flex-shrink-0 self-start rounded-full bg-blue-100 p-2">
        <DocumentIcon className="h-5 w-5 text-primary-500" />
      </span>

      <div className="flex flex-1 flex-col justify-between">
        <div className="flex w-full flex-row justify-between">
          <span className="m-0 text-sm font-semibold">
            {fileDocument?.name} (
            <a
              className="text-primary-500 underline"
              target="_blank"
              href={fileDocument?.downloadUrl}
            >
              View
            </a>
            )
          </span>
        </div>
        <span className="text-gray-400"></span>

        <div className="relative hidden w-full">
          <div
            style={{ width: '100%' }}
            className="absolute z-10 h-2 flex-shrink-0 rounded-full bg-blue-100"
          ></div>
          <div
            style={{ width: fileDocument?.isUploaded ? '100%' : '0%' }}
            className="bg-primary absolute z-30 h-2 flex-shrink-0 rounded-full duration-300"
          ></div>
        </div>
      </div>
    </div>
  );
};

const EducationInfoSummary: FC<{ data: any }> = ({ data }) => {
  const allSubjects: any[] = [];

  data?.history.forEach((historyItem: any) => {
    historyItem?.subjectGrades?.map((data: any) => {
      if (!allSubjects.includes(data?.subject)) {
        allSubjects.push(data?.subject);
      }
    });
  });

  return (
    <div className="space-y-6 divide-y divide-gray-200 rounded border border-dashed border-gray-300 p-3 dark:border-gray-600">
      <div className="pt-6">
        <span className="text-xs font-light">Education Information</span>
        {data?.type && (
          <div className="mt-2 grid grid-cols-4 gap-6">
            {['WASSCE', 'SSSCE'].includes(data?.type) ? (
              <>
                <div className="col-span-4 grid grid-cols-2 gap-4 ">
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Qualification
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.type || 'N/A'}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Institution
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.length
                        ? [...data?.history]
                            ?.map((data: any) => data?.institution)
                            .join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Index Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.length
                        ? [...data?.history]
                            ?.map((data: any) => data?.idNumber)
                            .join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Graduating Month
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.length
                        ? [...data?.history]
                            ?.map((data: any) =>
                              dayjs(
                                new Date(
                                  `${data?.graduatingYear}-${data?.graduatingMonth}-01`,
                                ),
                              ).format('MMMM, YYYY'),
                            )
                            .join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                </div>
                <div className="col-span-4">
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 rounded border border-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            SUBJECT
                          </th>
                          {data?.history?.map((_: any, idx: any) => (
                            <th
                              key={idx}
                              scope="col"
                              className="w-12 whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900"
                            >
                              Grade (Attempt {idx + 1})
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allSubjects.map((subject, index) => {
                          return (
                            <>
                              <tr
                                className="divide-x divide-gray-200"
                                key={index}
                              >
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                                  {subject || 'Not Set'}
                                </td>
                                {data?.history?.map((historyItem: any) => {
                                  const foundSubjectGrade =
                                    historyItem?.subjectGrades?.find(
                                      (subjectGrade: any) =>
                                        subjectGrade?.subject === subject,
                                    );
                                  return (
                                    <>
                                      <td className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-700">
                                        {
                                          EXAMINATION_GRADING[
                                            (data?.type as keyof typeof EXAMINATION_GRADING) ??
                                              'WASSCE'
                                          ]?.find(
                                            grade =>
                                              grade?.value ===
                                              foundSubjectGrade?.grade,
                                          )?.label
                                        }
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-span-4 mt-2 grid grid-cols-2 gap-6">
                <div className="col-span-2 grid grid-cols-3 gap-4 ">
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Qualification
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.type || 'N/A'}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Institution
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.length
                        ? [...data?.history]
                            ?.map((data: any) => data?.institution)
                            .join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Index Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.length
                        ? [...data?.history]
                            ?.map((data: any) => data?.idNumber)
                            .join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                  {!['WASSCE_NON_GHANAIAN'].includes(data?.type) && (
                    <div className="col-span-1">
                      <span className="block text-sm font-light text-gray-700">
                        Programme
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {data?.history?.[0]?.programme || 'N/A'}
                      </div>
                    </div>
                  )}
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Graduating Month
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {data?.history?.[0]?.graduatingMonth &&
                      data?.history?.[0]?.graduatingYear
                        ? dayjs(
                            new Date(
                              `${data?.history?.[0]?.graduatingYear}-${data?.history?.[0]?.graduatingMonth}-01`,
                            ),
                          ).format('MMMM, YYYY')
                        : 'N/A'}
                    </div>
                  </div>
                  {!['MATURE', 'ONALEVEL', 'WASSCE_NON_GHANAIAN'].includes(
                    data?.type,
                  ) && (
                    <div className="col-span-1">
                      <span className="block text-sm font-light text-gray-700">
                        Class
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {lodash
                          .capitalize(data?.history?.[0]?.class)
                          .replaceAll('_', ' ')}{' '}
                        Class (
                        {`${data?.history?.[0]?.cgpa} / ${data?.history?.[0]?.gpaScale}`}
                        )
                      </div>
                    </div>
                  )}
                </div>
                {data?.history?.[0]?.certificateFileUrl &&
                  data?.education?.history?.[0]?.certificateFileUrl !== '' && (
                    <div className="col-span-1">
                      <span className="text-xs font-light">Certificate</span>
                      <DocumentComponent
                        file={data?.history?.[0]?.certificateFileUrl}
                      />
                    </div>
                  )}
                {data?.history?.[0]?.transcriptFileUrl &&
                  data?.education?.history?.[0]?.transcriptFileUrl !== '' && (
                    <div className="col-span-1">
                      <span className="text-xs font-light">Transcript</span>
                      <DocumentComponent
                        file={data?.history?.[0]?.transcriptFileUrl}
                      />
                    </div>
                  )}
                {data?.history?.[0]?.essayFileUrl &&
                  data?.education?.history?.[0]?.essayFileUrl !== '' && (
                    <div className="col-span-1">
                      <span className="text-xs font-light">Essay</span>
                      <DocumentComponent
                        file={data?.history?.[0]?.essayFileUrl}
                      />
                    </div>
                  )}
                {data?.history?.[0]?.cvFileUrl &&
                  data?.education?.history?.[0]?.cvFileUrl !== '' && (
                    <div className="col-span-1">
                      <span className="text-xs font-light">CV</span>
                      <DocumentComponent file={data?.history?.[0]?.cvFileUrl} />
                    </div>
                  )}
                {data?.history?.[0]?.evaluationFileUrl &&
                  data?.education?.history?.[0]?.evaluationFileUrl !== '' && (
                    <div
                      className={classNames(
                        ['WASSCE_NON_GHANAIAN'].includes(data?.type)
                          ? 'col-span-1'
                          : 'col-span-2',
                      )}
                    >
                      <span className="text-xs font-light">Evaluation</span>
                      <DocumentComponent
                        file={data?.history?.[0]?.evaluationFileUrl}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationInfoSummary;
