import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { currentUserVar } from 'apollo/cache/auth';

type CycleFilter = {
  school: string;
  _id: Partial<{
    notEq: string;
    eq: string;
  }>;
  closingDate: Partial<{
    after: string;
    before: string;
    between: {
      end: string;
      start: string;
    };
    eq: string;
  }>;
};

interface AdmissionCyclePickerContainerProps {
  filter?: Partial<CycleFilter>;
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_ADMISSION_CYCLE = gql`
  query GetAdmissionCycles($filter: AdmissionCycleFilter) {
    cycles: getAdmissionCycles(filter: $filter) {
      _id
      name
      code
    }
  }
`;

const AdmissionCyclePickerContainer: FC<AdmissionCyclePickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      filter: {
        ...(filter ? filter : {}),
      },
    },
    notifyOnNetworkStatusChange: false,
  });
  return (
    <SearchSelectInput
      id={id ?? 'cycle'}
      label={label ?? 'Admission Cycle'}
      placeholder="Select Cycle"
      optionsLoading={loading}
      options={(data?.cycles ?? [])?.map((cycle: any) => ({
        label: {
          title: cycle.name as string,
        },
        value: rawId ? cycle._id : cycle,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default AdmissionCyclePickerContainer;
